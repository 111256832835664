import React from 'react';
// MUI
import { Box, Typography, Card, CardContent, FormControlLabel, Checkbox } from '@mui/material';
// Interfaces
import { CompanyApplication, Company, Contact } from '../../../interfaces/users.interface';
import { WORKFLOW_MAP, WorkflowMap } from '../../../services/workflow';
import { Grantee } from '../../../interfaces/engagements';

const ShieldLabel = ({ selectedReportTypes }: { selectedReportTypes: string[] }) => {
  
  const badgeImages = {
    gold: {
      src: `${process.env.PUBLIC_URL}/assets/TPN_GoldLogoTransparent_1280.png`,
      alt: 'Gold Shield Logo',
      label: 'Assessment Report',
    },
    blue: {
      src: `${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`,
      alt: 'Blue Shield Logo',
      label: 'Questionnaire Report',
    },
  };
  
  return (
        <Box display="flex" alignItems="center" gap={3}>
          {selectedReportTypes.includes('tpn_self_reported') && (
            <Box display="flex" alignItems="center" gap={1}>
              <img
                src={badgeImages.blue.src}
                alt={badgeImages.blue.alt}
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  border: '1px solid #ddd',
                  padding: '2px',
                  backgroundColor: '#fff',
                }}
              />
              <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
                {badgeImages.blue.label}
              </Typography>
            </Box>
          )}
          {selectedReportTypes.includes('tpn_assessed') && (
            <Box display="flex" alignItems="center" gap={1}>
              <img
                src={badgeImages.gold.src}
                alt={badgeImages.gold.alt}
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  padding: '2px',
                  backgroundColor: '#fff',
                }}
              />
              <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
                {badgeImages.gold.label}
              </Typography>
            </Box>
          )}
        </Box>
  );
};

const ReviewAndConfirm = (
  { 
    type,
    company,
    selectedReportTypes,
    siteOrApplication, 
    questionnaireType,
    termsAccepted,
    setTermsOpen,
    isIndefinite,
    startDate,
    endDate,
    selectedEmployees,
  }: {
    selectedReportTypes: string[];
    type: string;
    company: Company | null;
    siteOrApplication: Contact | CompanyApplication | null;
    questionnaireType: string | null;
    employeeList: Grantee[];
    selectedEmployees?: Grantee[];
    termsAccepted: boolean;
    setTermsOpen: (open: boolean) => void;
    isIndefinite: boolean;
    startDate: Date | null;
    endDate: Date | null;
  },
) => {
  return (
        <>
        <Typography variant="h6">
          Review Your Selections
        </Typography>
        <Card
          sx={{
            p: 3,
            background: 'linear-gradient(135deg, #FEFEFE, #fcfcfd)',
            borderRadius: 2,
            boxShadow: 1,
            borderTop: '8px solid #1195CD',
            mb:2,
          }}
        >
        <CardContent>
          {/* Company Section - Label on Top, Logo & Name on Same Row */}
          <Box sx={{ mb:3 }}>
            <Typography variant="subtitle2" sx={{ color: '#666', textTransform: 'uppercase', fontWeight: 300 }}>
              Company
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
              {company?.logo ? (
                <img
                  src={company.logo}
                  alt={company.name}
                  style={{ width: 40, height: 40, borderRadius: '50%' }}
                />
              ) : (
                <Box sx={{ width: 40, height: 40, borderRadius: '50%', backgroundColor: '#ddd' }} />
              )}
              <Typography variant="h6" sx={{ fontWeight: 400, color: '#333' }}>
                {company?.name || ''}
              </Typography>
            </Box>
          </Box>

          {/* Site/Application */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ color: '#666', textTransform: 'uppercase', fontWeight: 300 }}>
              {type === 'site' ? 'Site' : !!type ? 'Application' : ''}
            </Typography>
            { siteOrApplication && (
              <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
              {
              'application' in siteOrApplication
                ? (siteOrApplication).application.name 
                : (siteOrApplication).name}
            </Typography>
            )}
          </Box>

          {/* Questionnaire Version */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ color: '#666', textTransform: 'uppercase', fontWeight: 300 }}>
              Questionnaire Type
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
              {WORKFLOW_MAP[questionnaireType  as  keyof WorkflowMap]?.version || ''}
            </Typography>
          </Box>
          {/* Company Section - Label on Top, Logo & Name on Same Row */}
          <Box sx={{ mb: 3 }}>
            <Typography
                variant="subtitle2"
                sx={{ color: '#666', textTransform: 'uppercase', fontWeight: 300 }}
            >
                Authorized Users
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, flexWrap: 'wrap', mt: 1 }}>
                {selectedEmployees?.length ? (
                  selectedEmployees.map((employee) => (
                    <Box
                    key={employee.email}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      px: 2,
                      py: 0.8,
                      borderRadius: '6px',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f7f9f9',
                      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08)',
                    }}
                    >
                    <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
                        {`${employee.email} (${employee.firstName || 'Unknown'} ${employee.lastName})`}
                    </Typography>
                    </Box>
                  ))
                ) : (
                <Typography></Typography>
                )}
            </Box>
            </Box>
          {/* Shields */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ color: '#666', textTransform: 'uppercase', fontWeight: 300 }}>
              Reports
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 400, color: '#333', pt: .5 }}>
              { siteOrApplication && <ShieldLabel selectedReportTypes={selectedReportTypes} /> }
            </Typography>
          </Box>

          {/* Start and End Date */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ color: '#666', textTransform: 'uppercase', fontWeight: 300 }}>
              Access Duration
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
              { (!startDate && !endDate && !isIndefinite) ? '' :
                isIndefinite
                  ? `${startDate ? new Date(startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Invalid Date'} - Indefinite`
                  : `${startDate ? new Date(startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Invalid Date'} to ${endDate ? new Date(endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Invalid Date'}`
              }
            </Typography>
          </Box>
          {/* Terms & Conditions Checkbox */}
          <Box sx={{ mt: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  disabled
                />
              }
              label={
                <Typography variant="body2">
                 You must read and agree to the Terms and Conditions.{' '}
                  <Typography
                    component="span"
                    color="primary"
                    variant='body2'
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => setTermsOpen(true)}
                  >
                   Click  here
                  </Typography>
                  {' '}to view them.
                </Typography>
              }
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ReviewAndConfirm;