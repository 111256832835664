import React, { useState, useEffect } from 'react';
import { Button, TextField, Box } from '@mui/material';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';

interface CommentBuilderProps {
  canEdit?: boolean;
  commentBuilderDefault?: string;
  showCommentBuilder: boolean;
  handleHideCommentBuilder: () => void;
  handleSaveCommentBuilder: (comment: string) => void;
}

const CommentBuilder: React.FC<CommentBuilderProps> = ({ canEdit, commentBuilderDefault = '', handleHideCommentBuilder, showCommentBuilder, handleSaveCommentBuilder }) => {
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (showCommentBuilder) {
      setComment(commentBuilderDefault || '');
    }
  }, [showCommentBuilder, commentBuilderDefault]);

  const handleClose = () => {
    handleHideCommentBuilder();
    setComment('');
  };

  const handleSave = () => {
    handleSaveCommentBuilder(comment);
    handleHideCommentBuilder();
    setComment('');
  };

  return (
    <StandardDialog
      maxWidth="sm"
      handleClose={handleClose}
      isOpen={showCommentBuilder}
      title="Assessor Findings Comment Builder">
      <TextField
        id="assessor-finding-comment"
        autoFocus={showCommentBuilder}
        multiline
        rows={7}
        label="Assessor Finding Comment"
        fullWidth
        disabled={!canEdit}
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        sx={{ mt: 2 }}
        inputProps={{ readOnly: false, sx: { overflowY: 'scroll' } }}
      />
      <StandardDialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined">
            {canEdit ? 'Cancel' : 'Close'}
          </Button>
          {canEdit && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSave}>
              Continue
            </Button>
          )}
        </Box>
      </StandardDialogActions>
    </StandardDialog>
  );
};

export default CommentBuilder;