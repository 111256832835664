import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
//MUI
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import { Engagement } from '../../interfaces/engagements';

const SummaryBubbles = ({
  engagementsSent,
  engagementsReceived,
  summaryBubbleTabChange,
}: {
  engagementsSent: Engagement[];
  engagementsReceived: Engagement[];
  summaryBubbleTabChange: (tab: number) => void;
}) => {
  const theme = useTheme();

  const countReportsSentByStatus = useMemo(() => {
    const now = new Date();
    
    return engagementsSent.reduce(
      (counts, report) => {
        const startDate = new Date(report.startDate);
        const endDate = new Date(report.endDate);
      
        if (startDate > now && (!endDate || endDate >= now)) counts.upcoming++;
        else if (report.endDate && endDate < now) counts.expired++;
        else counts.active++;
      
        return counts;
      },
      { active: 0, upcoming: 0, expired: 0 },
    );
  }, [engagementsSent]);
    
  const countReportsRecievedByStatus = useMemo(() => {
    const now = new Date();
    
    return engagementsReceived.reduce(
      (counts, report) => {
        const startDate = new Date(report.startDate);
        const endDate = new Date(report.endDate);
      
        if (startDate > now && (!endDate || endDate < now)) counts.upcoming++;
        else if (report.endDate && endDate < now) counts.expired++;
        else counts.active++;
      
        return counts;
      },
      { active: 0, upcoming: 0, expired: 0 },
    );
  }, [engagementsReceived]);

  // Count of reports shared by status
  const activeReportsSharedCount = countReportsSentByStatus.active;
  const upcomingReportsSharedCount = countReportsSentByStatus.upcoming;
  const expiredReportsSharedCount = countReportsSentByStatus.expired;

  // Count of reports shared with me by status
  const activeReportsSharedWithMeCount = countReportsRecievedByStatus.active;
  const upcomingReportsSharedWithMeCount = countReportsRecievedByStatus.upcoming;
  const expiredReportsSharedWithMeCount = countReportsRecievedByStatus.expired;
    
  return (
    <Grid container spacing={3} sx={{ mb: 4 }}>
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ backgroundColor: '#E76F51' }} onClick={() => summaryBubbleTabChange(0)}>
        <CardContent>
          <Typography 
            variant="h5" 
            sx={{ color: theme.palette.secondary.contrastText, fontWeight: 500 }}
          >
            Reports You Shared
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, mt: 3 }}>
            {[
              { count: activeReportsSharedCount, label: 'Active' },
              { count: upcomingReportsSharedCount, label: 'Upcoming' },
              { count: expiredReportsSharedCount, label: 'Expired' },
            ].map((item, index) => (
              <Box 
                key={index} 
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', gap: 1 }}
              >
                <Typography 
                  variant="h5" 
                  sx={{ fontWeight: 'bold', color: theme.palette.secondary.contrastText }}
                >
                  {item.count}
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ backgroundColor: '#5A4FCF' }} onClick={() => summaryBubbleTabChange(1)}>
        <CardContent>
          <Typography 
            variant="h5" 
            sx={{ color: theme.palette.secondary.contrastText, fontWeight: 500 }}
          >
            Reports Shared With You
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, mt: 3 }}>
            {[
              { count: activeReportsSharedWithMeCount, label: 'Active' },
              { count: upcomingReportsSharedWithMeCount, label: 'Upcoming' },
              { count: expiredReportsSharedWithMeCount, label: 'Expired' },
            ].map((item, index) => (
              <Box 
                key={index} 
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', gap: 1 }}
              >
                <Typography 
                  variant="h5" 
                  sx={{ fontWeight: 'bold', color: theme.palette.secondary.contrastText }}
                >
                  {item.count}
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  );
};

export default SummaryBubbles;