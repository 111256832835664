import React, { useState, useRef, useEffect, useMemo } from 'react';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Notification } from '../../interfaces/assessment.interface';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchAssessment, fetchBPVerificationList } from '../../app/store/assessmentSlice';
import { setBestPractice as setReduxBP, setQuestion } from '../../app/store/questionnaireAnswerSlice';
import NotificationPopover from '../BestPractices/NotificationsPopover';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FileDownload } from '@mui/icons-material';
import { Button, Badge } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { buildBestPracticeTitle } from '../BestPractices/BestPractice';
import { ADMIN_REMEDIATION_VALIDATION, NOTIFICATION_TYPES, REMEDIATION_PLAN_OVERDUE, REMEDIATION_VALIDATION_STATUS_UPDATE } from '../../services/surveyHelpers';
import { markAssessmentNotificationAsRead } from '../../services/assessments';
import { ReportVersion } from '../../interfaces/questionnaire.interface';
import { USER_TYPES } from '../../services/user';

type NotificationTypeKey = keyof typeof NOTIFICATION_TYPES;
export default function BestPracticeHeader(props: {
  bestPractice: BestPractice,
}) {
  const { user } = useAppSelector(state => state.user);
  const descRef = useRef();
  const dispatch = useAppDispatch();
  const [bestPractice, setBestPractice] = useState(props.bestPractice);
  const [bestPracticeTitle, setBestPracticeTitle] = useState<string | undefined>(undefined);
  const [descriptionExpandedButton, setDescriptionExpandedButton] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const { assessmentQuestionsStatus, questionStatus, bpStatus } = useAppSelector(state => state.questionnaireAnswer);
  const { assessment } = useAppSelector(state => state.assessments);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const handleNotificationRead = async (id: number) => {
    if (user?.type !== USER_TYPES.VENDOR_ADMIN && notifications.find(notification => notification.id === id)?.type !== NOTIFICATION_TYPES[ADMIN_REMEDIATION_VALIDATION]) return;

    const newNotifications = notifications.map(notification => {
      if (notification.id === id) {
        return { ...notification, read: true };
      }
      return notification;
    });
    setNotifications(newNotifications);
    await markAssessmentNotificationAsRead(id);
    if (assessment?.id) dispatch(fetchAssessment(assessment.id));
  };

  const [anchorElNotifications, setAnchorElNotifications] = useState<null | HTMLElement>(null);

  const handleNotificationClose = () => {
    setAnchorElNotifications(null);
  };

  const handleBPClick = React.useCallback((bp:BestPractice, notificationId: number) => {
    handleNotificationRead(notificationId);

    const hasQuestions = bp.questions.findIndex(q => typeof q === 'number');
    if (hasQuestions !== -1) return;
    dispatch(setReduxBP({ ...bp, domain: typeof bp.domain === 'number' ? bp.domain : bp.domain?.id, topic: typeof bp.topic === 'number' ? bp.topic : bp.topic?.id }));
    dispatch(setQuestion(bp.questions[0]));
    handleNotificationClose();
  }, [dispatch]);

  useEffect(() => {
    const descitem:HTMLElement = descRef.current!;
    descitem.children[0].scrollTop = 0;
  }, [descriptionExpanded]);

  useEffect(() => {
    setBestPractice(props.bestPractice);
    if (!assessmentQuestionsStatus || !assessment?.notifications) return;

    const newNotifications = assessment.notifications.reduce((acc: Notification[], notification) => {
      if (notification.adminOnly && user?.type !== USER_TYPES.TPN_ADMIN) return acc;
      const { id, message, read, type } = notification;
      acc.push({
        id,
        read,
        message,
        assessment: notification.assessment,
        type: NOTIFICATION_TYPES[notification.type as NotificationTypeKey],
        questionTitle: questionStatus[notification.question as number]?.question.title || '',
        shortCode: questionStatus[notification.question as number]?.question.shortCode || '',
        date: type === REMEDIATION_PLAN_OVERDUE
          ? new Date(notification.createdAt)
          : new Date(notification.createdAt),
        bestPractice: bpStatus[(questionStatus[notification.question as number]?.question?.bestPractice as BestPractice)?.id]?.bestPractice,
      });
      return acc;
    }, []);
    setNotifications(newNotifications);
  }, [props.bestPractice, assessment?.notifications, assessmentQuestionsStatus, user, bpStatus, questionStatus]);

  useEffect(() => {
    if (!bestPractice) return;
    setBestPracticeTitle(buildBestPracticeTitle(bestPractice));
    const descitem:HTMLElement = descRef.current!;
    const isTextClamped = (paraItem:Element) => paraItem.scrollHeight > paraItem.clientHeight;

    if (isTextClamped(descitem.children[0])) setDescriptionExpandedButton(true);

  }, [bestPractice]);

  useEffect(() => {
    dispatch(fetchBPVerificationList());
  }, []);

  const styles = () => {
    if (descriptionExpanded) return { maxHeight: '350px', overflow: 'auto' };
    if (!descriptionExpanded)  return { maxHeight: '48px', textOverflow: 'ellipsis', overflow: 'hidden', WebkitLineClamp: '2', lineClamp: '2', WebkitBoxOrient: 'vertical', boxOrient: 'vertical', display: '-webkit-box' };
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const openNotifEl = Boolean(anchorElNotifications);

  const filteredNotifications = useMemo(() => {
    return notifications.filter(notification => {
      // Never show Reviewed status notifications to non TPN Admins or COs
      if (notification.type === NOTIFICATION_TYPES[REMEDIATION_VALIDATION_STATUS_UPDATE] &&
        (notification?.message.includes('reviewed') || notification?.message.includes('pass')) &&
        ![USER_TYPES.TPN_ADMIN, USER_TYPES.CONTENT_OWNER].includes(user?.type || '')) {
        return false;
      }
      return true;
    });
  }, [notifications]);

  const isQuestionnaireV51 = typeof bestPractice.questionnaire !== 'number' && bestPractice.questionnaire.title.includes(ReportVersion.v5_1);

  return (
    <>
      <Paper sx={{ p:2, position: 'relative' }}>
        {bestPractice.description?.length > 0 && <>
          <Box sx={{ position: 'absolute', right: 10, top: 15, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <Badge
              badgeContent={filteredNotifications.filter(({ read }) => !read).length}
              color="error"
              invisible={filteredNotifications.filter(({ read }) => !read).length === 0}
            >
              <Tooltip title="Remediation Dashboard" arrow placement="top">
                <Button
                  variant="outlined"
                  onClick={handleNotificationClick}
                  startIcon={
                    <DashboardIcon sx={{ }} />
                  }
                >
                  Remediation Dashboard
                </Button>
              </Tooltip>
            </Badge>
            <Tooltip title="Download Guideline" arrow placement='top'>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'download-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                color="primary"
              >
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="download-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <a href={typeof bestPractice.questionnaire !== 'number' ? bestPractice.questionnaire.bestPracticeGuidanceLink : '#'} download><MenuItem>
              <FileDownload /> { `Download Best Practices & ${isQuestionnaireV51 ? 'Implementation Guidance' : 'Additional Recommendations'}` }
            </MenuItem></a>
          </Menu>
        </>}
        <Typography variant="h4">{typeof bestPractice.questionnaire !== 'number' && bestPractice.questionnaire.title}</Typography>
        <Divider sx={{ margin: '1em 0' }} />
        <Typography variant="h5" fontWeight={400}>{bestPracticeTitle}</Typography>
        <Box ref={descRef}>
          <Typography sx={{ ...styles(), whiteSpace: 'pre-wrap', transition: 'max-height .25s ease' }}>
            {bestPractice.description}
          </Typography>
          { descriptionExpandedButton &&
            <Divider onClick={() => setDescriptionExpanded(!descriptionExpanded)} sx={{ mt: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '.75em', cursor: 'pointer' }}>
              { descriptionExpanded ? 
                <><ExpandLessIcon /><Box>Show Less</Box></>
                :
                <><ExpandMoreIcon /><Box>Show More</Box></>
              }
              </Box>
            </Divider>
          }
        </Box>
      </Paper>
      <NotificationPopover
          anchorEl={anchorElNotifications}
          open={openNotifEl}
          onClose={handleNotificationClose}
          notifications={notifications}
          handleBPClick={handleBPClick}
        />
    </>
  );

}