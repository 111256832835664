import React from 'react';
import { Box, Tooltip } from '@mui/material';

const ShieldLabel = ({ showOne, showTwo }: { showOne: boolean, showTwo: boolean }) => {
  const badgeImages = {
    gold: {
      src: `${process.env.PUBLIC_URL}/assets/TPN_GoldLogoTransparent_1280.png`,
      alt: 'Gold Shield Logo',
      label: 'Assessment Report',
    },
    blue: {
      src: `${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`,
      alt: 'Blue Shield Logo',
      label: 'Questionnaire Report',
    },
  };

  return (
    <Box display="flex" alignItems="center" sx={{ gap: 1 }} justifyContent="space-between">
      {/* Blue Badge or Placeholder */}
      {showOne ? (
        <Tooltip title={badgeImages.blue.label} arrow placement="top">
          <img
            src={badgeImages.blue.src}
            alt={badgeImages.blue.alt}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              border: '1px solid #ddd',
              padding: '4px',
              backgroundColor: '#fff',
            }}
          />
        </Tooltip>
      ) : (
        <Box width="40px" />
      )}

      {/* Gold Badge or Placeholder */}
      {showTwo ? (
        <Tooltip title={badgeImages.gold.label} arrow placement="top">
          <img
            src={badgeImages.gold.src}
            alt={badgeImages.gold.alt}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              border: '1px solid #ddd',
              padding: '4px',
              backgroundColor: '#fff',
            }}
          />
        </Tooltip>
      ) : (
        <Box width="40px" />
      )}
    </Box>
  );
};

export default ShieldLabel;
