import axios from 'axios';

import getAuthSession from './auth';
import { CompanyLegalContact } from '../interfaces/users.interface';
import { FieldValues } from 'react-hook-form';

export default async function createCompanyLegalContact(contact: FieldValues) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/company-legal-contacts/`,
    contact,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyLegalContact;
}

export async function updateCompanyLegalContact(companyLegalContact: FieldValues) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/company-legal-contacts/${companyLegalContact.id}/`,
    companyLegalContact,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyLegalContact;
}
