import React, { useEffect, useState } from 'react';

import { CompanyProfile } from '../Vendors/Profile';
import { getCompany } from '../../services/company';
import { Company } from '../../interfaces/users.interface';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { setCompanyFromResponse } from '../../app/store/questionnaireAnswerSlice';
import { useAppDispatch } from '../../app/hooks';
import { USER_TYPES } from '../../services/user';

export default function CompanyDetail() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const companyId:string = params.companyId!;
  const { user } = useAppSelector(state => state.user);
  const [company, setCompany] = useState<Company | null>(null);

  useEffect(() => {
    if (!user || !companyId) return;
    if (user.type === USER_TYPES.ASSESSOR && (user.companies[0].id as number) !== parseInt(companyId)) return;
    if (user.type === USER_TYPES.VENDOR_ADMIN && (user.companies[0].id as number) !== parseInt(companyId)) return;
    getCompany(companyId).then((companyResult: Company) => {
      setCompany(companyResult);
      dispatch(setCompanyFromResponse(companyResult));
    });
  }, [companyId, user]);

  return (
    <>
      {company && <CompanyProfile company={company} />}
    </>
  );
}