import React, { useState, useEffect } from 'react';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
} from '@mui/material';
import { StartQuestionnaireModalData } from '../../services/surveyHelpers';

const StartNewQuestionnaireModal = ({
  handleClose,
  isOpen,
  startQuestionnaireModalData,
}: {
  handleClose: (createWf: boolean) => void;
  isOpen: boolean;
  startQuestionnaireModalData: StartQuestionnaireModalData;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleContinue = () => {
    if (isChecked) {
      setLoading(true);
      handleClose(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsChecked(false);
      setLoading(false);
    }
  }, [isOpen]);

  return (
    <StandardDialog
      title="Attention Required: Read Before Proceeding"
      isOpen={isOpen}
      handleClose={() => handleClose(false)}
      maxWidth="sm"
    >
      <Typography variant="body1" gutterBottom>{startQuestionnaireModalData.data}</Typography>
      {startQuestionnaireModalData.canStart && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label={<Typography variant="body1">I have read and understood the message.</Typography>}
          sx={{ mt: 2 }}
        />
      )}

      <StandardDialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button onClick={() => handleClose(false)} variant="outlined">
            Cancel
          </Button>
          {startQuestionnaireModalData.canStart && (
            <Button
              onClick={handleContinue}
              color="primary"
              variant="contained"
              disabled={!isChecked || loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Agree and Continue'
              )}
            </Button>
          )}
        </Box>
      </StandardDialogActions>
    </StandardDialog>
  );
};

export default StartNewQuestionnaireModal;
