// Background for each partner resource card
export const gradientColors = [
  { start: '#2C3E50', end: '#4CA1AF' },  // Dark Blue to Teal
  { start: '#34495E', end: '#2C3E50' },  // Slate Gray to Charcoal Blue
  { start: '#1F1C2C', end: '#928DAB' },  // Dark Violet to Soft Gray
  { start: '#2E4053', end: '#4B79A1' },  // Dark Slate to Steel Blue
  { start: '#0F2027', end: '#203A43' },  // Dark Teal to Deep Ocean Blue
  { start: '#3A6073', end: '#16222A' },  // Slate Blue to Dark Teal
];

export const EmojiFlagMap = {
  'English': '🇺🇸',
  'Brazilian Portuguese': '🇧🇷',
  'Castilian Spanish': '🇪🇸',
  'French': '🇫🇷',
  'German': '🇩🇪',
  'Hindi': '🇮🇳',
  'Italian': '🇮🇹',
  'Japanese': '🇯🇵',
  'Korean': '🇰🇷',
  'Latin American Spanish': '🇲🇽',
  'Chinese': '🇨🇳',
  'Russian': '🇷🇺',
  'Arabic': '🇸🇦',
  'Dutch': '🇳🇱',
};

export const ReactWorldFlagsMap = {
  'English': { code: 'US', nativeName: 'English' },
  'Brazilian Portuguese': { code: 'BR', nativeName: 'Português (Brasil)' },
  'Castilian Spanish': { code: 'ES', nativeName: 'Español (Castellano)' },
  'French': { code: 'FR', nativeName: 'Français' },
  'German': { code: 'DE', nativeName: 'Deutsch' },
  'Hindi': { code: 'IN', nativeName: 'हिन्दी (Hindi)' },
  'Italian': { code: 'IT', nativeName: 'Italiano' },
  'Japanese': { code: 'JP', nativeName: '日本語 (Nihongo)' },
  'Korean': { code: 'KR', nativeName: '한국어 (Hanguk-eo)' },
  'Latin American Spanish': { code: 'MX', nativeName: 'Español (América Latina)' },
  'Chinese': { code: 'CN', nativeName: '中文 (Zhōngwén)' },
  'Russian': { code: 'RU', nativeName: 'Русский (Russkiy)' },
  'Arabic': { code: 'SA', nativeName: 'العربية (Al-‘Arabiyya)' },
  'Dutch': { code: 'NL', nativeName: 'Nederlands' },
};

export type EmojiFlagMapType = keyof typeof EmojiFlagMap;