import React from 'react';
// MUI
import { Box, Typography, Button } from '@mui/material';
// Modals
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';

const TermsAndConditions = ({
  termsOpen,
  setTermsOpen,
  setTermsAccepted,
}: {
  termsOpen: boolean,
  setTermsOpen: (open: boolean) => void,
  setTermsAccepted: (accepted: boolean) => void
}) => {
  return (
    <StandardDialog
      title="PLEASE READ BEFORE PROCEEDING"
      subtitle="Review the details before granting access to the selected users."
      isOpen={termsOpen}
      handleClose={() => setTermsOpen(false)}
      maxWidth="md"
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="body2" sx={{ color: '#444', fontWeight: 400, lineHeight: 1.4 }}>
          By clicking <strong>“Agree & Continue”</strong>, you are giving the selected users the right to view the chosen materials. 
          This access will continue until it is revoked, either:
        </Typography>
        <Box
          component="ul"
          sx={{
            overflowY: 'auto',
            p: 2,
            pl: 3,
            color: '#444',
            fontWeight: 400,
            lineHeight: 1.2,
            '& li': {
              mb: 1.2,
              listStyleType: 'disc',
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: 1.4,
            },
          }}
        >
          <li>
            <Typography variant="body2" component="span">
              Manually, by clicking the <strong>“End Sharing”</strong> option in the Shared Reports tab.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" component="span">
              Automatically, by expiration of the chosen access period.
            </Typography>
          </li>
        </Box>
        <Typography variant="body2" sx={{ color: '#444', fontWeight: 400, lineHeight: 1.4 }}>
          If this is not your intention, please close this window by clicking the <strong>“x”</strong> in its top right corner.
        </Typography>
      </Box>
      <StandardDialogActions>
        <Box sx={{ justifyContent: 'space-between', p: 2 }}>
          <Button
            onClick={() => {
              setTermsAccepted(true);
              setTermsOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Agree & Continue
          </Button>
        </Box>
      </StandardDialogActions>
    </StandardDialog>
  );
};

export default TermsAndConditions;
