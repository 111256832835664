import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
//Modal
import { StandardDialogActions } from '../Modals/StandardDialog';
import StandardDialog from '../Modals/StandardDialog';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SearchIcon from '@mui/icons-material/Search';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// Services
import { postDownloadPartnerResource } from '../../services/partnerResources';
// Helpers
import { EmojiFlagMapType, ReactWorldFlagsMap } from '../../services/partnerResourcesHelpers';
// Material-UI
import { Box, Button, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';

import PartnerResource from './PartnerResource';
import MPABestPractices from './Library/MPABestPractices';
import StandardLibrary from './Library/StandardLibrary';

import KeyTermsAndDefinitions from './Library/KeyTermsAndDefinitions';
import { PartnerResource as PartnerResourceInterface, PartnerResourceMetadata, ResourceImageType } from '../../interfaces/partnerResources.interface';
import { getHelpURL } from '../../services/user';
import { useAppSelector } from '../../app/hooks';

export default function PartnerResourcesModal({
  partnerResources,
  showResourceModal,
  fetchResources,
  handleCloseResourceModal,
}: {
  partnerResources: PartnerResourceInterface[],
  showResourceModal: boolean,
  handleCloseResourceModal: () => void,
  fetchResources: () => void,
}) {
  const [expandedSection, setExpandedSection] = useState<string | null>('MPA CONTENT SECURITY BEST PRACTICES');
  const [partnerResource, setPartnerResource] = useState<any | null>(null);
  const { resourceSection, documentId } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAppSelector(state => state.user);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Must go back to the main resource page if a search query is entered
    if (partnerResource !== null) setPartnerResource(null);
    setSearchQuery(event.target.value.toLowerCase());
  };
  const resourceSections = useMemo(() => partnerResources.reduce((acc, resource) => {
    if (!acc[resource.category.title]) acc[resource.category.title] = [];
    acc[resource.category.title.toLocaleUpperCase()].push(resource);
    return acc;
  }, {
    'POLICIES': [],
  } as { [key: string]: PartnerResourceInterface[] }), [partnerResources]);

  // Filter sections based on the search query
  const filteredSections =
    expandedSection && resourceSections[expandedSection]
      ? resourceSections[expandedSection].filter((item) => {
        const lowerSearchQuery = searchQuery.toLowerCase();

        if (expandedSection === 'MPA CONTENT SECURITY BEST PRACTICES') {
          const nativeName = ReactWorldFlagsMap[item.title as EmojiFlagMapType]?.nativeName?.toLowerCase() || '';
          return nativeName.includes(lowerSearchQuery);
        }
        return item.title.toLowerCase().includes(lowerSearchQuery) || item.subDescription.toLowerCase().includes(lowerSearchQuery);
      })
      : [];

  const handleSectionClick = (section: string) => {
    setExpandedSection(section);
    navigate(`/partner-resources/${section}`);
  };

  const handleCloseEduCenter = () => {
    handleCloseResourceModal();
  };

  const handleUpdateResource = useCallback((resource: PartnerResourceInterface) => {
    setPartnerResource(resource);
  }, []);

  const handleBackClick = useCallback(() => {
    setPartnerResource(null);
    navigate(`/partner-resources/${expandedSection}`);
  }, [documentId, expandedSection]);

  const uniqueDownloadCount = useCallback((metadata: PartnerResourceMetadata[]) => {
    const uniqueUsers = new Set();
    return metadata.reduce((acc, meta) => {
      if (uniqueUsers.has(meta.downloadedByUser)) return acc;
      uniqueUsers.add(meta.downloadedByUser);
      acc++;
      return acc;
    }, 0);
  }, [user]);

  useEffect(() =>  {
    fetchResources();
  }, [documentId, partnerResource]);

  useEffect(() => {
    if (showResourceModal) {
      setExpandedSection('MPA CONTENT SECURITY BEST PRACTICES');
      setPartnerResource(null);
      setSearchQuery('');
    } else {
      navigate('/partner-resources');
    }
    return () => {
      setSearchQuery('');
    };
  }, [showResourceModal]);

  useEffect(() => {
    if (documentId && expandedSection && resourceSections[expandedSection]) {
      // If current url is /partner-resources exit early
      if (window.location.pathname === '/partner-resources') return;
      const selectedDoc = resourceSections[expandedSection].find((doc) => doc.id === parseInt(documentId));
      if (selectedDoc) setPartnerResource(selectedDoc);
    }

    if (!documentId && partnerResource) setPartnerResource(null);
  }, [documentId, expandedSection, showResourceModal]);

  useEffect(() => {
    if (resourceSection && window.location.pathname !== '/partner-resources') {
      setExpandedSection(resourceSection);
    }
  }, [resourceSection]);

  const handleDownloadPartnerResource = useCallback(async (resource: PartnerResourceInterface) => {
    if (!resource.id || !user) return;

    try {
      await postDownloadPartnerResource({
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        downloadedByUser: user.id,
        resource: resource.id as number,
      });

      const resourceImage = resource.images.filter((image) => image.type === ResourceImageType.DOCUMENT)[0].image;

      // Extract the file name from the URL
      const fileName = resourceImage ? resourceImage?.split('/')?.pop()?.split('?')[0] : 'download.pdf';


      // Ensure the file is downloadable and correct
      const link = document.createElement('a');
      link.href = resource.images.filter((image) => image.type === ResourceImageType.DOCUMENT)[0].image;
      link.setAttribute('download', fileName || 'download.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }, [partnerResource, user, handleUpdateResource]);

  return (
        <StandardDialog
          maxWidth="xl"
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocalLibraryIcon fontSize="small" />
              Partner Resource Center
            </Box>
          }
          isOpen={showResourceModal}
          handleClose={handleCloseEduCenter}>
        <Grid container sx={{ minHeight: '470px' }}>
            <Grid item xs={2} sx={{ borderRight: '1px solid #f1f4f6', padding: 2 }}>
                <Typography variant="h6">Library</Typography>
                <List component="nav">
                  <ListItem
                      button
                      onClick={() => handleSectionClick('MPA CONTENT SECURITY BEST PRACTICES')}
                      sx={{
                        borderRadius: '4px',
                        backgroundColor: expandedSection === 'MPA CONTENT SECURITY BEST PRACTICES' ? '#ECEFF1' : 'inherit',
                        '&:hover': {
                          backgroundColor: '#f1f4f6',
                        },
                      }}
                  >
                      <ListItemText primary="MPA Content Security Best Practices" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleSectionClick('POLICIES')}
                        sx={{
                          borderRadius: '4px',
                          backgroundColor: expandedSection === 'POLICIES' ? '#ECEFF1' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#f1f4f6',
                          },
                        }}
                    >
                      <ListItemText primary="Policies" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleSectionClick('KEY TERMS & DEFINITIONS')}
                        sx={{
                          borderRadius: '4px',
                          backgroundColor: expandedSection === 'KEY TERMS & DEFINITIONS' ? '#ECEFF1' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#f1f4f6',
                          },
                        }}
                    >
                        <ListItemText primary="Key Terms & Definitions" />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={10} sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search by title or description..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          sx: { height: '45px' },
                        }}
                        sx={{
                          width: '50%',
                          minWidth: '300px',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#e8e8e8',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                            },
                          },
                          borderRadius: '4px',
                        }}
                    />
                    <a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer">
                        <Button
                            variant="text"
                            startIcon={<ReviewsIcon />}
                            sx={{ ml: 2, px: '20px' }}
                            onClick={()=> { return false; }}
                        >
                            Feedback
                        </Button>
                    </a>
                </Box>
                { (!documentId && !partnerResource) && (

                  <Box>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 500, px: 1 }}>
                      {expandedSection?.split(' ').slice(0, 7).map((word, index) =>
                        expandedSection === 'MPA CONTENT SECURITY BEST PRACTICES'
                          ? index === 0
                            ? word.toUpperCase()
                            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                      ).join(' ')}
                    </Typography>
                  
                    <Grid container spacing={3}>
                      {expandedSection === 'MPA CONTENT SECURITY BEST PRACTICES' ? (
                        <MPABestPractices
                          uniqueDownloadCount={uniqueDownloadCount}
                          filteredSections={filteredSections}
                          handleDownloadPartnerResource={handleDownloadPartnerResource}
                        />
                      ) : expandedSection === 'KEY TERMS & DEFINITIONS' ? (
                        <KeyTermsAndDefinitions
                          filteredSections={filteredSections}
                          handleDownloadPartnerResource={handleDownloadPartnerResource}
                        />
                      ) : (
                        <StandardLibrary
                          expandedSection={expandedSection}
                          filteredSections={filteredSections}
                          uniqueDownloadCount={uniqueDownloadCount}
                        />
                      )}
                    </Grid>
                  </Box>
                )}
                { partnerResource && (
                  <PartnerResource uniqueDownloadCount={uniqueDownloadCount} handleUpdateResource={handleUpdateResource} partnerResource={partnerResource} handleBackClick={handleBackClick} />
                )}
            </Grid>
        </Grid>
        <StandardDialogActions>
            <Button variant="outlined" onClick={handleCloseResourceModal}>Close</Button>
        </StandardDialogActions>
    </StandardDialog>
  );
}