import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { WillNotRemediateReasons } from '../../../interfaces/assessment.interface';

type WillNotRemediateProps = {
  disabled: boolean;
  value: string;
  onChange: (value: WillNotRemediateReasons) => void;
};

const WillNotRemediateReasonSelect: React.FC<WillNotRemediateProps> = ({ disabled, value, onChange }) => {
  return (
    <Box sx={{ mt: 3, mb:1 }}>
      <FormControl>
        <InputLabel id="willNotRemediatePlanDetails">Reason for Not Remediating</InputLabel>
        <Select
          sx={{ width: '350px' }}
          labelId="willNotRemediatePlanDetails"
          id="willNotRemediatePlanDetails"
          label="Reason for Not Remediating"
          disabled={disabled}
          value={value}
          onChange={(event) => onChange(event.target.value as WillNotRemediateReasons)}
        >
          <MenuItem value="" disabled>
            Select an option
          </MenuItem>
          <MenuItem value="compensating_control">Compensating Control</MenuItem>
          <MenuItem value="other">Other (Please Specify)</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default WillNotRemediateReasonSelect;
