import axios from 'axios';
import getAuthSession from './auth';
import { Engagement } from '../interfaces/engagements';
interface EngagementAccessHistory {
  engagement: number;
  reportViewed: string;
  accessedBy: number;
}

export const createEngagementAccessHistory = async (data: EngagementAccessHistory) => {
  try {
    const authSession = await getAuthSession();

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API}/engagement-access-history/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authSession.getIdToken().getJwtToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error creating engagement access history:', error);
    throw error;
  }
};

export const getEngagementUserByEmail = async ({
  granteeCompanyId,
  email,
}: {
  granteeCompanyId: number;
  email: string;
}) => {
  const authSession = await getAuthSession();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/engagement-company-user/${granteeCompanyId}/`,
      {
        params: { email },
        headers: {
          Authorization: `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching engagement user by email:', error);
    throw error;
  }
};

export const getEngagements = async (type: 'sent' | 'received', userId?: number) => {
  try {
    const authSession = await getAuthSession();
    const idToken = authSession.getIdToken().getJwtToken();

    const url = new URL(`${process.env.REACT_APP_BASE_API}/engagements`);

    if (userId && type === 'received') {
      url.searchParams.append('grantees', userId.toString());
    }

    const response = await axios.get(url.toString(), {
      headers: {
        'Authorization': `Bearer ${idToken}`,
      },
    });

    return response.data.results;
  } catch (error) {
    console.error('Error fetching engagements:', error);
    return [];
  }
};

export const createEngagement = async ({
  sharedReportTypes,
  isActive,
  startDate,
  endDate,
  workflow,
  grantorCompany,
  createdBy,
  grantees,
}:{
  sharedReportTypes: string[];
  isActive: boolean;
  startDate: Date;
  endDate: Date | null;
  workflow: number;
  grantorCompany: number;
  createdBy: number;
  grantees: number[];
}) => {
  try {
    const authSession = await getAuthSession();
    const idToken = authSession.getIdToken().getJwtToken();

    const engagementData = {
      sharedReportTypes,
      isActive,
      startDate,
      endDate,
      workflow,
      grantorCompany,
      createdBy,
      grantees,
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/engagements/`, engagementData, {
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error creating engagement:', error);
    return null;
  }
};

export const updateEngagement = async (request: Partial<Engagement>) => {
  const authSession = await getAuthSession();
  const { id, ...req } = request;

  try {
    const url = `${process.env.REACT_APP_BASE_API}/engagements/${id}/`;
    const response = await axios.patch(
      url,
      req,
      {
        headers: {
          Authorization: `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return response.data as Engagement;
  } catch (error) {
    console.error('Error updating engagement:', error);
    throw error;
  }
};

export const getEngagementReportCompany = async (companyId: string) => {
  const authSession = await getAuthSession();

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/engagement-company-detail/${companyId}/`,
      {
        headers: {
          Authorization: `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching engagement report company:', error);
    throw error;
  }
};
