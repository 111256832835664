import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useEffect, useState, useMemo } from 'react';

import User, { Company, CompanySubmissionData, Contact } from '../../../interfaces/users.interface';
import { updateCompany } from '../../../services/company';
import { AccordionCollapse, AccordionCollapseSummary } from '../../Layout/Collapsible';
import StandardDialog from '../../Modals/StandardDialog';
import { DataCell, DataRow } from '../../Tables/DataList';
import { UserDataRow } from '../../Users/Users';
import canAdminCompany from '../../../services/adminUtils';
import { useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import NewUserForm from '../../Users/NewUserForm';
import { USER_TYPES } from '../../../services/user';
import { COMPANY_TYPES } from '../../../services/vendor';
// import { Typography } from '@mui/material';
// import { getHelpURL } from '../../../services/user';

export default function CompanyUsersAccordion(props: { company: Company, companyCallback: any }) {
  const {
    user: adminUser,
  } = useAppSelector(state => state.user);
  const { profileSection } = useParams();
  const { company } = props;
  const { employees: propUsers } = company;
  const [users, setUsers] = useState<Array<User>>(propUsers);
  const [createUserOpen, setCreateUserOpen] = useState(false);
  // const [isLimitUser, setIsLimitUser] = useState(false);
  const handleCreateUserClose = () => setCreateUserOpen(false);
  const handleUserDeleteCallback = (user: User) => setUsers(users.filter((filteredUser: User) => filteredUser.id != user.id));

  const handleCreateUserOpen = () => {
    // if (company?.type === USER_TYPES.CONTENT_OWNER && company?.contentOwnerLevel === 1 && company.employees.length >= 2) {
    //   setIsLimitUser(true);
    //   return;
    // }

    setCreateUserOpen(true);
  };

  useEffect(() => {
    setUsers(propUsers);
  }, [props.company]);

  const handleAddUser = (responseCompany: Company) => {
    props.companyCallback(responseCompany);
    handleCreateUserClose();
  };

  const handleEditUser = (responseUser: User) => {
    const employees: User[] = company.employees.map((employee: User) => {
      if (responseUser.id == employee.id) return responseUser;
      return employee;
    }).filter((item): item is User => !!item);
    const updatedCompany: Company = {
      ...company,
      employees: employees,
    };
    props.companyCallback(updatedCompany);
  };

  const addUserSuccessCallback = (user: User) => {
    const companyData: CompanySubmissionData = {
      ...company,
      primaryContactUser: company.primaryContactUser?.id,
      employees: [...users.map((employee: User) => employee.id), user.id],
      pendingEmployees: company.pendingEmployees.map((employee) => employee.id),
      locations: company.locations.map((location: Contact) => location.id),
      services: company.services.map((service: any) => service.id),
      admins: company.admins.map(item => item.id),
      subsidiaries: company.subsidiaries.map(item => item.id),
    };
    // If the user adding the new user is a TPN Admin, then make this user a company admin.
    if (adminUser?.type == USER_TYPES.TPN_ADMIN) {
      companyData.admins = companyData.admins ? [...companyData?.admins?.map((adminId: number) => adminId), user.id] : [user.id];
      if (!company.primaryContactUser?.id) companyData.primaryContactUser = user.id;
    }
    if (company.id) updateCompany(companyData).then((responseCompany: Company) => handleAddUser(responseCompany));
  };

  const getUserTypeFromCompany = () => {
    if (company.type == COMPANY_TYPES.ASSESSOR) return USER_TYPES.ASSESSOR;
    if (company.type == COMPANY_TYPES.CONTENT_OWNER) return USER_TYPES.CONTENT_OWNER;
    if (company.type == COMPANY_TYPES.VENDOR) return USER_TYPES.VENDOR_ADMIN;
    return USER_TYPES.TPN_ADMIN;
  };

  const userCanAdminCompany: boolean = canAdminCompany(adminUser, company);
  const isTpnAdmin = useMemo(() => adminUser?.type === USER_TYPES.TPN_ADMIN, [adminUser]);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'users');
  }, [profileSection]);

  return (
    <AccordionCollapse expanded={expanded} onChange={() => { setExpanded(!expanded); }}>
      <AccordionCollapseSummary
        name="users-contacts-header"
        title='Users'
        count={users.length}
        addItemTest={userCanAdminCompany}
        addItem={() => handleCreateUserOpen()}
        addItemLabel='User'
      >
      </AccordionCollapseSummary>
      <AccordionDetails>
        <StandardDialog title="Invite User" handleClose={handleCreateUserClose} isOpen={createUserOpen}>
          <NewUserForm successCallback={addUserSuccessCallback} userType={getUserTypeFromCompany()} modalClose={() => handleCreateUserClose()} />
        </StandardDialog>
        {/* <StandardDialog 
          title='Gold Plan Only'
          handleClose={() => setIsLimitUser(false)} 
          isOpen={isLimitUser}
        >
          <Typography>You must upgrade to a Gold member plan to export Assessment Reports. <a target="_blank" href={getHelpURL(adminUser?.type)} rel="noreferrer">Request Help Here.</a></Typography>
        </StandardDialog> */}
        <DataRow onClick={() => {}} header>
          <DataCell xs={3}>Email</DataCell>
          <DataCell xs={2}>First</DataCell>
          <DataCell xs={2}>Last</DataCell>
          <DataCell xs={2}>Last Login</DataCell>
          <DataCell xs={1}>Admin</DataCell>
          <DataCell xs={1}>Consultant</DataCell>
          <DataCell xs={1}>&nbsp;</DataCell>
        </DataRow>
        {users && users.map((user: User, index: number) => <UserDataRow key={user.id} company={company} user={user} index={index} handleUserDelete={handleUserDeleteCallback} editUsersCallback={handleEditUser} includeAdminActionContent={true} isTpnAdmin={isTpnAdmin} userCanAdminCompany={userCanAdminCompany}/>)}
      </AccordionDetails>
    </AccordionCollapse>
  );
}