import React, { useEffect, useState, useMemo } from 'react';
import { Typography, ListItem, ListItemIcon, ListItemText, Box, CircularProgress } from '@mui/material';
import { Survey } from '../../../interfaces/survey.interface';
import { fetchSurvey } from '../../../services/surveys';
import { buildFullQuestionnaireObject } from '../../../services/questionnaires';
import { fetchQuestionDependantObjects, initialBuildOutQuestionnaire } from '../../../services/buildQuestionnaire';
import { Questionnaire } from '../../../interfaces/questionnaire.interface';
import { Question } from '../../../interfaces/question.interface';
import { getEngagementReportCompany } from '../../../services/engagements';
import { Badge, Company, CompanyApplication, Contact } from '../../../interfaces/users.interface';
import { QuestionAnswer } from '../../../interfaces/questionAnswer.interface';
import moment from 'moment';
import { appTheme } from '../../Themes/light';
import { BestPractice } from '../../../interfaces/bestPractice.interface';
import { Answer } from '../../../interfaces/answer.interface';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useAppSelector } from '../../../app/hooks';
import { Topic } from '../../../interfaces/topic.interface';
import { WORKFLOW_MAP, fetchWorkflows } from '../../../services/workflow';
import { Workflow } from '../../../interfaces/workflow.interface';
import FileViewer from '../../FileViewer/FileViewer';
import { createEngagementAccessHistory } from '../../../services/engagements';

export default function BlueReport(props: {   engagementId: number | null, closeMenuItem: () => void, label: string, workflow: Workflow | null, surveyId?: number, siteAppName?: string | null }) {
  if (!props.workflow) return null;

  const { workflow, surveyId, closeMenuItem, engagementId } = props;
  const { user } = useAppSelector(state => state.user);
  const [reportLoading, setReportLoading] = useState(false);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);
  const [baseline, setBaseline] = useState<Survey | undefined>(undefined);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | undefined>(undefined);
  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
  const [domainRollUps, setDomainRollUp] = useState<any>(undefined);
  const [bpDisplay, setBPDisplay] = useState<any>(undefined);
  const [reportReady, setReportReady] = useState(false);
  const [site, setSite] = useState<Contact | undefined>(undefined);
  const [application, setApplication] = useState<CompanyApplication | undefined>(undefined);
  const [employeeCount, setEmployeeCount] = useState<Answer | undefined>(undefined);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [showFileViewer, setShowFileViewer] = useState(false);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const badge = (workflow?.badges as Badge[])?.find(b => b.title === 'tpn_self_reported');

  const isWorkflow51 = useMemo(() => WORKFLOW_MAP[workflow.workflowType]?.version === 'v5.1', [workflow]);

  const buildReport = async () => {
    if (!survey || !survey.questionnaire) {
      setReportLoading(false);
      return;
    }

    setReportLoading(true);
    const surQuestionnaire = survey.questionnaire as Questionnaire;

    getEngagementReportCompany(typeof survey.company === 'number' ? survey.company.toString() : survey.company.id!.toString()).then(comp => setCompany(comp));
    const questionnaireObjects = await buildFullQuestionnaireObject(surQuestionnaire.id);
    const builtQuestionnaire = initialBuildOutQuestionnaire({ ...questionnaireObjects, questionnaire: surQuestionnaire });
    setQuestionnaire(builtQuestionnaire.questionnaire);

    const fetchQuestions = await fetchQuestionDependantObjects(false, builtQuestionnaire.allQuestions);
    setQuestions(fetchQuestions.questions);
  };

  const loopQuestionnaire = () => {
    if (!survey || !company || !questionnaire || !questions || questions.length === 0) {
      setReportLoading(false);
      return;
    }

    const questionAnswers = survey.questionAnswers;

    if (!questionAnswers || questionAnswers.length === 0) {
      setReportLoading(false);
      return;
    }

    const rollups: any[] = [];
    const bps: any[] = [];

    questionnaire.domains.forEach(domain => {
      const domainRollUp: any = { name: domain.title, bpCounts: { fi: 0, pi: 0, ni: 0, na: 0 }, igCounts: { fi: 0, pi: 0, ni: 0, na: 0 } };
      domain.topics.forEach(topic => {
        topic.bestPractices.forEach(bp => {
          const curBP = { bp, domain, topic, questions: ([] as any[]) };
          (bp.questions as unknown as number[]).forEach(questionId => {
            const question = questions.find(q => q.id === questionId);
            const answer = (survey.questionAnswers as QuestionAnswer[]).find(qa => qa.question === questionId);
            const qAnswer = question?.answers.find(ans => ans.id === answer?.answer?.[0]);
            let revealQuestion = !question?.inclusions.length || question.inclusions.some(({ answer: inclusionAnswer, question: inclusionQuestion }) => {
              const inclusionAnswerId = (inclusionAnswer as Answer).id;
              const inclusionQuestionId = (inclusionQuestion as Question).id;

              const surveyQuestionAnswer = (survey.questionAnswers as QuestionAnswer[]).find(qa => qa.question === inclusionQuestionId);
              const baselineQuestionAnswer = (baseline?.questionAnswers as QuestionAnswer[]).find(qa => qa.question === inclusionQuestionId);
            
              return (
                baselineQuestionAnswer?.answer?.includes(inclusionAnswerId) ||
                surveyQuestionAnswer?.answer?.includes(inclusionAnswerId) ||
                surveyQuestionAnswer?.compensatingControlAnswers?.includes(inclusionAnswerId)
              );
            });            

            if (!question || !revealQuestion) return;

            curBP.questions.push({ question, answer, qAnswer });
          });
          if (curBP.questions.length > 0) bps.push(curBP);
        });
      });
      rollups.push(domainRollUp);
    });
    setDomainRollUp(rollups);
    setBPDisplay(bps);
    setReportLoading(false);
    setReportReady(true);
  };

  const setSiteOrApplication = () => {
    if (!company || !survey) return;

    const csite = survey.site ? company.locations.find(location => location.id === survey.site) : undefined;
    const capplication = survey.application ? company.companyApplications?.find(companyApplication => companyApplication.application.id === survey.application) : undefined;

    setSite(csite);
    setApplication(capplication);
  };

  useEffect(() => {
    if (!survey) return;
    buildReport();
  }, [survey]);

  useEffect(() => {
    if (!questions || !company || !questionnaire) return;
    loopQuestionnaire();
    setSiteOrApplication();
  }, [questions, company, questionnaire]);

  const fetchBaselineQuestions = async () => {
    if ((!site && !application) || !company || !baseline) return;

    const getBaselineQuestions = await fetchQuestionDependantObjects(true, (baseline.questionAnswers as QuestionAnswer[]).map(qa => qa.question));
    const baselineQuestions = getBaselineQuestions.questions;
    if (!baselineQuestions.length || !baseline.questionAnswers?.length) return;

    const employeeCountQuestion = baselineQuestions.find(question => question.title.toLowerCase().indexOf('number') !== -1 && question.title.toLowerCase().indexOf('employees') !== -1);
    if (employeeCountQuestion) {
      const questionAnswer = (baseline.questionAnswers as QuestionAnswer[]).find(qa => (qa.question as number) === employeeCountQuestion.id);
      if (questionAnswer && questionAnswer.answer?.length) setEmployeeCount(employeeCountQuestion.answers.find(answer => answer.id === questionAnswer.answer![0]));
    }
    if (bpDisplay) {
      setReportLoading(false);
      setReportReady(true);
    }
  };

  useEffect(() => {
    if ((!site && !application) || !company || !baseline) return;
    fetchBaselineQuestions();
  }, [baseline, site, application, company]);

  const requestBuildReport = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (reportLoading) return;
    if (!surveyId) {
      setReportLoading(false);
      return;
    }

    if (engagementId && user) {
      createEngagementAccessHistory({ engagement: engagementId, reportViewed: 'tpn_self_reported', accessedBy: user?.id! });
    }

    setReportLoading(true);
    fetchSurvey(surveyId).then(sur => setSurvey(sur));
    fetchWorkflows({ 'bp_survey': surveyId }).then(wf => {
      if (wf.length > 0) {
        fetchSurvey(wf[0].baseline!).then(bas => setBaseline(bas));
      }
    });
  };

  const outputOverviewBox = () => {
    if (!survey || !company) return;
    const output = {
      margin: [0, 0, 0, 50],
      table: {
        headerRows: 0,
        widths: ['*'],
        body: [
          [{
            border: [true, false, true, true],
            stack: [
              { text: [{ text: 'Survey Scope:', bold: true }, ` ${survey?.site ? 'Site' : application?.sites.length ? 'Site' : 'On-Prem'}`] },
              { text: [{ text: 'Survey Type:', bold: true }, ` ${survey?.site ? 'On Site' : 'Remote'}`] },
            ],
          }],
        ],
      },
    };

    if (site) output.table.body[0][0].stack.push({ text: [{ text: 'Related Facility:', bold: true }, ` ${site.name}`] });
    if ((application && application.sites?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Related Facilities:', bold: true }, ` ${application.sites.map(csite => csite.name).join(', ')}`] });
    if ((site && site.services?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Services:', bold: true }, ` ${site.services.map(service => service.name).join(', ')}`] });
    if ((application && application.services?.length)) output.table.body[0][0].stack.push({ text: [{ text: 'Services:', bold: true }, ` ${application.services.map(service => service.name).join(', ')}`] });
    if (employeeCount?.text) output.table.body[0][0].stack.push({ text: [{ text: 'Number of Employees:', bold: true }, ` ${employeeCount.text}`] });
    const ownedApps = company?.companyApplications
      ?.filter(
        (ca) =>
          ca.application.company &&
          (typeof ca.application.company === 'number' && ca.application.company === company.id) ||
          (typeof ca.application.company !== 'number' && ca.application.company?.id === company.id),
      );
    if (site && ownedApps?.length) output.table.body[0][0].stack.push({ text: [{ text: 'Owned Applications:', bold: true }, ` ${ownedApps.map(app => app.application.name).join(', ')}`] });
    if (application) output.table.body[0][0].stack.push({ text: [{ text: 'Owned Application:', bold: true }, application.application.name ] });
    const thirdPartyApps = company?.companyApplications
      ?.filter(
        (ca) =>
          !ca.application.company ||
          (typeof ca.application.company === 'number' && ca.application.company !== company.id) ||
          (typeof ca.application.company !== 'number' && ca.application.company.id !== company.id),
      );
    if (site && thirdPartyApps?.length) output.table.body[0][0].stack.push({ text: [{ text: '3rd Party Licensed Applications:', bold: true }, ` ${thirdPartyApps.map(app => app.application.name).join(', ')}`] });

    return output;
  };

  const outputBPItems = () => {
    const bpItems:any = bpDisplay.map((bpitem: any, index: number) => {
      const item:any = [
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: `${bpitem.domain.code}: ${bpitem.domain.title} - ${bpitem.topic.title}`,
              } ],
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: `${bpitem.domain.topics.findIndex((top: Topic) => bpitem.topic.id === top.id) + 1}.${bpitem.topic.bestPractices.findIndex((bp: BestPractice) => bpitem.bp.id === bp.id)} ${bpitem.bp.title}`,
              } ],
            ],
          },
        },
      ];
      bpitem.questions.map((qContain: any) => {
        const notImplementedAnswer = qContain.question.answers.find((answer: Answer) => answer.text === 'Not Implemented');
        const notApplicableAnswer = qContain.question.answers.find((answer: Answer) => answer.text === 'Not Applicable');
        const fullyImplementedAnswers = qContain.question.answers.filter((answer: Answer) => !['Not Applicable', 'Not Implemented'].includes(answer.text || ''));

        const isFullyImplemented = qContain.answer?.answer.length === fullyImplementedAnswers.length;
        const answerIsNotImplemented = qContain.answer?.answer.includes(notImplementedAnswer?.id);
        const hasNoAnswer = !qContain.answer?.answer?.length
          && !qContain.answer?.compensatingControlAnswers?.length
          && !qContain.answer?.nonApplicableAnswers?.length
          &&  !qContain.answer?.compensatingControlAnswers?.length;
        const questionArray:any = [
          {
            text: (qContain.question.isBestPractice
              ? 'Best Practice'
              : isWorkflow51
                ? 'Implementation Guidance'
                : 'Additional Recommendations'
            ),
            fontSize: 16,
            margin: [0, 0, 0, 20],
            alignment: 'center',
          },
          { text: qContain.question.title, bold: true, margin: [0, 0, 0, 10] },
        ];
        if (qContain && qContain.answer && qContain.answer.text) { questionArray.push(qContain.answer.text); } else {
          const answers = qContain.question.answers.map((answer: Answer) => {
            const answered = qContain.answer && qContain.answer.answer.findIndex((ans: number) => ans === answer.id) > -1;
            return {
              columns: [
                { width: 'auto', text: answered ? '√' : 'x' },
                { width: 'auto', text: answer.text },
              ],
              bold: answered,
              columnGap: 5,
            };
          });
          questionArray.push(...answers);
        }
        questionArray.push(...[
          qContain.answer?.comment && { text: `Service Provider Comment: ${qContain.answer?.comment}` },
          {
            table: {
              widths: '*',
              body: [
                [
                  {
                    border: [false, false, false, false],
                    fillColor: isFullyImplemented ? appTheme.palette.success.main : answerIsNotImplemented ? appTheme.palette.error.main : qContain?.answer?.answer.includes(notApplicableAnswer?.id) ? '#ccc' : appTheme.palette.nonCompliant.main,
                    color: isFullyImplemented || answerIsNotImplemented || hasNoAnswer ? 'white' : 'black',
                    text: isFullyImplemented ? 'Fully Implemented'
                      : answerIsNotImplemented ? 'Not Implemented'
                        : qContain.answer?.answer.includes(notApplicableAnswer?.id) ? 'Not Applicable'
                          : (hasNoAnswer || qContain?.question?.type === 'single_select') ? '' : 'Partially Implemented',
                  },
                ],
              ],
            },
            margin: [0, 10, 0, 5],
          },
        ]);
        item.push({
          table: {
            headerRows: 0,
            widths: ['*'],
            body: [
              [{
                stack: questionArray,
              }],
            ],
          },
          margin: [0, 20, 0, 0],
        });
      });
      if (index + 1 !== bpDisplay.length) return { stack: item, pageBreak: 'after' };
      return { stack: item };
    });

    return bpItems;
  };
  
  const generatePDF = async () => {
    if (!(reportReady && bpDisplay && domainRollUps && company && questionnaire && user)) return;

    const curDate = moment();

    pdfMake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
      MaterialSymbolsOutlined: {
        normal: 'https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2',
      },
    };

    const docDefinition: any = {
      content: [
        {
          stack: [
            { image: 'TPN', fit: [400, 400], margin: [0, 130, 0, 20] },
            { text: 'BLUE SELF-REPORTED QUESTIONNAIRE', fontSize: 25 },
            ...(site ?
              [
                {
                  stack: [
                    company.name,
                    site.name,
                    site.address,
                    site.address2,
                    site.address3,
                    `${site.city}, ${site.state} ${site.zipcode}`,
                    site.country,
                  ], fontSize: 16, margin: [0, 30, 0, 20],
                },
                { text: 'Primary Contact Information', fontSize: 18 },
                `${site.primaryContact?.firstName} ${site.primaryContact?.lastName}`,
                site.primaryContact?.email,
                site.primaryContact?.contact?.phoneNumber,
              ]
              :
              [
                {
                  stack: [
                    company.name,
                    company.contact?.address,
                    company.contact?.address2,
                    company.contact?.address3,
                    `${company.contact?.city}, ${company.contact?.state} ${company.contact?.zipcode}`,
                    company.contact?.country,
                  ], fontSize: 16, margin: [0, 30, 0, 20],
                },
                { text: 'Primary Contact Information', fontSize: 18 },
                `${company.primaryContactUser?.firstName} ${company.primaryContactUser?.lastName}`,
                company.primaryContactUser?.email,
                company.primaryContactUser?.contact?.phoneNumber,
              ]
            ),
            {
              stack: [
                `Report Generated: ${moment().format('MMM DD, YYYY')}`,
                `Questionnaire Completed: ${moment(badge?.createdAt).format('MMM DD, YYYY')}`,
                `Expiration Date: ${moment(badge?.expirationDate).format('MMM DD, YYYY')}`,
              ], fontSize: 12, margin: [0, 40, 0, 0],
            },
            { text: questionnaire.title, margin: [0, 50, 0, 0] },
          ], alignment: 'center', pageBreak: 'after',
        },
        {
          style: 'headerBG',
          table: {
            widths: '*',
            body: [
              [{
                border: [false, false, false, false],
                fillColor: appTheme.palette.primary.main,
                text: 'Overview',
              }],
            ],
          },
        },
        outputOverviewBox(),
        { stack: [], pageBreak: 'after' },
        {
          stack: outputBPItems(),
        },
      ],
      images: {
        TPN: `${window.location.origin}/assets/TPN_BlueLogoTransparent_1280wide.png`,
      },
      styles: {
        headerBG: {
          fontSize: 16,
          color: 'white',
        },
      },
      defaultStyle: {
        font: 'Roboto',
      },
      info: {
        title: `TPN-Best_Practice_Questionnaire-${encodeURIComponent(company.name.replace(/\s+/g, '-'))}-${curDate.format('YYYY-MM-DD')}`,
        author: 'Trusted Partner Network',
      },
      watermark: { text: `TPN Best Practice Questionnaire - ${user.email} - ${curDate.format('MMMM DD YYYY')}`, color: 'blue', opacity: 0.2, bold: true, italics: false },
      footer: function (currentPage: number, pageCount: number) { return { text: currentPage.toString() + ' of ' + pageCount, alignment: 'center', margin: [0, 10, 0, 0 ] }; },
      header: { text: `TPN Best Practice Questionnaire: ${company.name} ${curDate.format('MMMM DD YYYY')}`, alignment: 'center', margin: [0, 5, 0, 5] },
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getBlob((blob) => {
      const localPdfUrl = URL.createObjectURL(blob);
      setPdfUrl(localPdfUrl);
      setShowFileViewer(true);
    });
  };

  useEffect(() => {
    if (!reportReady) return;

    setShowFileViewer(true);
    generatePDF();
  }, [reportReady]);

  return (
    <Box>
      {(!surveyId) && <Typography>Could not find questionnaire</Typography>}
  
      {surveyId && (
        <ListItem 
          button 
          onClick={(e) => { requestBuildReport(e); }}
          sx={{ 
            px: 0, 
            '&:hover': { backgroundColor: 'transparent !important' }, 
            '&.Mui-focusVisible': { backgroundColor: 'transparent !important' },
            '&:active': { backgroundColor: 'transparent !important' },
            '&.MuiListItem-root': {
              '&:hover, &:focus, &:active': {
                backgroundColor: 'transparent !important',
              },
            },
            transition: 'none',
            '& .MuiTouchRipple-root': { display: 'none' }, 
          }}
        >
          <ListItemIcon>
          {!reportLoading ? (
            <img
              src={`${process.env.PUBLIC_URL}/assets/TPN_BlueLogoTransparent_1280.png`}
              alt="Blue Badge"
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                border: '1px solid #ddd',
                backgroundColor: '#fff',
              }}
              />
          ) : <CircularProgress size={24} />}
          </ListItemIcon>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body1">Questionnaire Report</Typography>
              </Box>
            }
          />
        </ListItem>
      )}
  
      {pdfUrl && showFileViewer && (
        <FileViewer
          preventDownload={true}
          previewFile={pdfUrl}
          closeFileViewer={() => {
            setShowFileViewer(false);
            URL.revokeObjectURL(pdfUrl);
            setPdfUrl(null);
            closeMenuItem();
          }}
          showFileViewer={showFileViewer}
        />
      )}
    </Box>
  );
}