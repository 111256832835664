// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewSurveyButton from './ViewSurveyButton';
import moment from 'moment';
// MUI
import { Box, Button, Stack, Typography } from '@mui/material';
import { Add, Undo } from '@mui/icons-material';
//redux
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchSurvey as fetchSurveyPreload, resetSurvey, updateSurvey } from '../../app/store/surveySlice';
import { updateWorkflow } from '../../app/store/workflowSlice';
import {
  fetchAssessment as fetchAssessmentPreload,
  resetAssessment,
  updateAssessment,
  updateAssessmentArrayAssessmentSurvey,
} from '../../app/store/assessmentSlice';
// interface
import User  from '../../interfaces/users.interface';
import { Badge, Company, CompanyApplication, Contact, Version } from '../../interfaces/users.interface';
import { Survey } from '../../interfaces/survey.interface';
import { Workflow } from '../../interfaces/workflow.interface';
import { Assessment, AssessmentSurvey } from '../../interfaces/assessment.interface';
// service
import { deleteBadge } from '../../services/badge';
import { fetchSurvey } from '../../services/surveys';
import { updateContactShort } from '../../services/contact';
import { updateCompanyBoolean } from '../../services/company';
import { WORKFLOW_STATUSES } from '../../services/workflow';
import {
  assessmentActionLabel,
  SURVEY_ACTIONS_LABELS,
  surveyActionLabel,
  surveyDisplayLogic,
  surveyStatusColor,
  testSubmitAssessmentButton,
} from '../../services/surveyHelpers';
import {
  fetchAssessment,
  fetchAssessmentSurvey,
  updateAssessmentSurvey,
  updateAssessmentSurveySimple,
} from '../../services/assessments';
// components
import AssessmentForm from '../Assessments/NewAssessmentModal';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import { updateVersionBadge } from '../../services/applications';
import { updateCompanyApplicationSimple } from '../../services/companyApplication';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import WorkflowDownloadsMenuButton from './WorkflowDownloadsMenuButton';
import { USER_TYPES } from '../../services/user';

type WorkflowButtonsProps = {
  company: Company;
  workflow?: Workflow;
  survey?: Survey;
  assessmentSurvey?: AssessmentSurvey;
  site?: Contact;
  companyApplication?: CompanyApplication;
  version?: Version;
  setAssessmentNotifications?: React.Dispatch<React.SetStateAction<any[]>>;
};

const WorkflowButtons = (props: WorkflowButtonsProps) => {
  // props
  const { company, workflow, survey, assessmentSurvey, site, companyApplication, version, setAssessmentNotifications } = props;
  // redux
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { assessment } = useAppSelector(state => state.assessments);
  const { survey: cSurvey } = useAppSelector((state) => state.surveys);
  // state
  const [cAssessment, setCAssessment] = useState<Assessment>();
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [beginAssessmentOpen, setBeginAssessmentOpen] = useState(false);
  const [fromStatus, setFromStatus] = useState('');
  const [toStatus, setToStatus] = useState('');
  const [isRevertConfirmOpen, setIsRevertConfirmOpen] = useState(false);
  const [visitAssessment, setVisitAssessment] = useState<{ assessment: number; survey: number } | undefined>(undefined);
  // short/simple functions
  const handleAssessmentOpen = useCallback(() => setAssessmentModalOpen(true), []);
  const handleAssessmentClose = useCallback(() => setAssessmentModalOpen(false), []);
  const handleBeginAssessmentClose = useCallback(() => setBeginAssessmentOpen(false), []);
  const handleRevertConfirmClose = useCallback(() => setIsRevertConfirmOpen(false), []);

  const handleClearPreLoad = useCallback(() => {
    dispatch(resetAssessment());
    dispatch(resetSurvey());
  }, [dispatch]);

  const handleBeginAssessmentOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setFromStatus('');
    setToStatus('');
    handleClearPreLoad();
    setBeginAssessmentOpen(true);
  }, []);

  const handleRevertConfirmOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>, from: string, to: string) => {
    e.stopPropagation();
    setFromStatus(from);
    setToStatus(to);
    setIsRevertConfirmOpen(true);
  }, []);

  const linkHandler = useCallback(
    (id: number, e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      handleClearPreLoad();
      navigate(`/questionnaire/${id}/`);
    },
    [navigate],
  );

  // Preload survey and assessment for Questionnaire page
  const handlePreLoad = useCallback(() => {
    if (workflow?.bpSurvey && workflow.bpSurvey !== cSurvey?.id) {
      dispatch(fetchSurveyPreload(workflow.bpSurvey));
    }
    if (workflow?.assessment && workflow.assessment !== assessment?.id) {
      dispatch(fetchAssessmentPreload(workflow.assessment));
    }
  }, [workflow, dispatch, cSurvey, assessment]);

  const handleVisitAssessment = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      if (!workflow?.assessmentSurvey || !workflow?.bpSurvey) return;
      setTimeout(() => {
        setVisitAssessment({
          assessment: workflow?.assessment as number,
          survey: workflow.bpSurvey as number,
        });
      }, 500);
    },
    [workflow],
  );

  const updateAssessmentSurveyStatus = useCallback(
    async (status: string) => {
      if (!workflow?.assessmentSurvey || !workflow?.bpSurvey) return;
      const assSur = assessmentSurvey || (await fetchAssessmentSurvey(workflow?.assessmentSurvey));
      const assessmentDate =
        !assSur.assessmentDate && status === 'Assessing'
          ? moment().utc().format('YYYY-MM-DDTHH:mm:ssZZ')
          : assSur.assessmentDate;
      const newAssSur = await updateAssessmentSurvey({
        ...assSur,
        status,
        assessmentDate,
      });
      dispatch(updateAssessmentArrayAssessmentSurvey(newAssSur));
      if (workflow) await dispatch(updateWorkflow({ id: workflow.id, status }));
      if (newAssSur.status === 'Assessing') handleVisitAssessment();
      handleBeginAssessmentClose();
    },
    [dispatch, workflow, assessmentSurvey, handleVisitAssessment],
  );

  const handleRevert = async () => {
    const curStatus = workflow!.status.toLowerCase();

    if (workflow?.assessmentSurvey) {
      let newStatus = '';
      let assSurUpdateRequest = {} as Partial<AssessmentSurvey>;
      let workflowUpdateRequest = {} as Partial<Workflow>;
      const curAssSurvey = assessmentSurvey || await fetchAssessmentSurvey(workflow.assessmentSurvey);

      if (['assessing', 'submitted'].includes(curStatus)) {
        newStatus = 'Pre-Assessment';
        assSurUpdateRequest = { assessmentDate: null };

        if (curStatus === 'submitted') {
          assSurUpdateRequest = { ...assSurUpdateRequest, submittedDate: null };
        }
      } else if (['remediation', 'complete'].includes(curStatus)) {
        newStatus = 'Assessing';
        assSurUpdateRequest = { reportIssueDate: null, expirationDate: null };

        updateCompanyBoolean({ id: company.id, displayShieldType: 'tpn_self_reported', goldShieldDate: null });
        const siteId = site?.id || (curAssSurvey.survey as Survey).site;
        if (siteId) {
          updateContactShort({ id: siteId, badge: 'tpn_self_reported', goldShieldDate: null });
        } else {
          // for application and version
          const curApp = companyApplication || company.companyApplications?.find(ca => ca.application.id === (curAssSurvey.survey as Survey).application);
          const curVersion = version || (curApp?.application.versions as Version[]).find(v => v.value === (curAssSurvey.survey as Survey).version);

          if (curApp && curVersion) {
            updateVersionBadge(curVersion.id!, (workflow.badges as Badge[])?.find(b => b.title === 'tpn_self_reported')?.id!);
            updateCompanyApplicationSimple(curApp.id!, { badge: 'tpn_self_reported', goldShieldDate: null });
          }
        }

        Promise.all((workflow.badges as Badge[])?.filter(badge => badge.title === 'tpn_assessed').map(badge => deleteBadge(badge.id!)) as Promise<boolean>[]);
        const newBadges = (workflow.badges as Badge[])?.filter(badge => badge.title !== 'tpn_assessed').map(badge => badge.id) as number[];
        workflowUpdateRequest = { reportIssueDate: null, badges: newBadges };
      }

      if (['submitted', 'remediation', 'complete'].includes(curStatus)) {
        dispatch(updateAssessment({ ...cAssessment, status: newStatus }));
      }

      const newAssSur = await updateAssessmentSurveySimple(curAssSurvey.id!, { ...assSurUpdateRequest, status: newStatus });
      dispatch(updateAssessmentArrayAssessmentSurvey(newAssSur));
      dispatch(updateWorkflow({ ...workflowUpdateRequest, id: workflow.id, status: newStatus, adminOverride: true }));
    } else if (workflow?.bpSurvey) {
      updateCompanyBoolean({ id: company.id, displayShieldType: '', blueShieldDate: null });
      const curSurvey = survey || await fetchSurvey(workflow.bpSurvey);

      // for site
      const curSite = site || company.locations.find(location => location.id === curSurvey.site);
      if (curSite) {
        updateContactShort({ id: curSite.id, blueShieldDate: null, badge: null });
      } else {
        // for application and version
        const curApp = companyApplication || company.companyApplications?.find(capplication => capplication.application.id === curSurvey.application);
        const curVersion = version || (curApp?.application.versions as Version[]).find(v => v.value === curSurvey.version);

        if (curApp && curVersion) {
          updateVersionBadge(curVersion.id!, null);
          updateCompanyApplicationSimple(curApp.id!, { badge: null, blueShieldDate: null });
        }
      }

      dispatch(updateSurvey({ ...curSurvey, status: 'incomplete' }));
      Promise.all(workflow.badges?.map(badge => deleteBadge((badge as Badge).id!)) as Promise<boolean>[]);
      dispatch(updateWorkflow({ id: workflow.id, status: 'incomplete', badges: [], adminOverride: true }));
    }

    handleRevertConfirmClose();
  };

  const renderRevertButton = useMemo(() => {
    let revertStatus = '';
    if (workflow?.assessmentSurvey) {
      const status = workflow?.status.toLowerCase();
      if (['assessing', 'submitted'].includes(status)) {
        revertStatus = WORKFLOW_STATUSES.PRE_ASSESSMENT;
      } else if (['remediation', 'complete'].includes(status)) {
        revertStatus = WORKFLOW_STATUSES.ASSESSING;
      }
    } else if (workflow?.bpSurvey && workflow?.status === WORKFLOW_STATUSES.SUBMITTED_FOR_ASSESSMENT) {
      revertStatus = 'Incomplete';
    }

    if (!revertStatus) return null;

    return <Button variant='outlined' color='error' sx={{ mt: 1, width: '100%' }} onClick={e => handleRevertConfirmOpen(e, workflow?.status!, revertStatus)}>Revert to {revertStatus}</Button>;
  }, [workflow]);

  // navigating to assessment link
  useEffect(() => {
    if (!visitAssessment) return;
    navigate(`/assessment/${visitAssessment.assessment}/questionnaire/${visitAssessment.survey}/`);

    return () => {
      setVisitAssessment(undefined);
    };
  }, [visitAssessment, navigate]);

  const surveyStatusDisplay = React.useMemo(() => surveyDisplayLogic({
    survey,
    companyCerts: company.companyCertifications,
    workflow,
  }), [survey, company, workflow]);

  const surveyType = React.useMemo(() => workflow?.bpSurvey
    ? 'TPN Best Practices Questionnaire'
    : workflow?.site
      ? 'Site Scoping Baseline'
      : 'Application Scoping Baseline', [workflow]);

  const surveyActLabel = React.useMemo(() => {
    const label = surveyActionLabel(
      surveyType,
      typeof surveyStatusDisplay === 'string' ? surveyStatusDisplay : '',
      Boolean(workflow?.baseline && !workflow?.bpSurvey),
    );
    const isAdmin = user?.type === USER_TYPES.VENDOR_ADMIN || user?.type === USER_TYPES.TPN_ADMIN;
    return label === SURVEY_ACTIONS_LABELS.scheduleAssessment && !isAdmin ? SURVEY_ACTIONS_LABELS.viewQuestionnaire : label;
  }, [workflow, surveyStatusDisplay]);

  // fetching assessment data for revert and AssessmentNotificationsAlertIcon
  useEffect(() => {
    if (workflow?.assessment && workflow?.assessmentSurvey) {
      fetchAssessment(workflow.assessment).then(workflowAssessment => {
        setCAssessment(workflowAssessment);
        if (setAssessmentNotifications) setAssessmentNotifications(workflowAssessment.notifications);
      });
    }
  }, [workflow?.status, workflow?.assessment, workflow?.assessmentSurvey]);

  const assessmentActionObj = useMemo(() => assessmentActionLabel(workflow?.status, user), [workflow?.status, user]);
  const { label: actionLabel, icon: actionIcon } = assessmentActionObj;

  const shouldShowViewSurveyButton = (wf: Workflow, usr: User, label: string) => {
    if (!wf) return false;

    const isContentOwnerOrAdmin = usr?.type === USER_TYPES.TPN_ADMIN || usr?.type === USER_TYPES.CONTENT_OWNER;
    const isSurveyPendingOrIncomplete = surveyStatusDisplay === 'Pending' && wf.bpSurvey && wf.status === 'incomplete';
    const isSurveyActionLabelMatch = surveyActLabel === SURVEY_ACTIONS_LABELS.continueQuestionnaire ||
      label === 'View and Comment' ||
      label === 'Update Assessment';
    const isNonAdminAndScheduleAssessment = usr?.type !== USER_TYPES.TPN_ADMIN &&
      (typeof surveyStatusDisplay === 'string' && surveyActLabel === SURVEY_ACTIONS_LABELS.scheduleAssessment);
    const isCompleteWithoutAssessmentSurvey = wf.status.toLowerCase() === 'complete' && !wf.assessmentSurvey;

    return isContentOwnerOrAdmin || isSurveyPendingOrIncomplete || isSurveyActionLabelMatch || isNonAdminAndScheduleAssessment || isCompleteWithoutAssessmentSurvey;
  };

  const showReassignButton: boolean = useMemo(() => {
    return workflow?.status === WORKFLOW_STATUSES.REJECTED &&
    company.admins?.some(admin => admin.id === user?.id) &&
    user?.type === USER_TYPES.VENDOR_ADMIN &&
    !!cAssessment;
  }, [workflow, company, user, cAssessment]);

  return (
    <>
      <Box sx={{ position: 'relative', maxWidth: '100%', width: '205px',  minWidth: '170px' }}>
        {!workflow && user?.type === USER_TYPES.VENDOR_ADMIN && (
          <Button variant='outlined' startIcon={<Add />}>
            New Questionnaire
          </Button>
        )}
        {(!workflow || !workflow?.status) && user?.type !== USER_TYPES.VENDOR_ADMIN && (
          <Button variant='outlined'>Click here for details</Button>
        )}
        {workflow && (
          <>
            {user?.type === USER_TYPES.CONTENT_OWNER &&
              !workflow?.assessmentSurvey &&
              workflow?.bpSurvey &&
              workflow?.status !== WORKFLOW_STATUSES.SUBMITTED_FOR_ASSESSMENT &&
              workflow?.baseline && (
                <ViewSurveyButton workflow={workflow} limit='Baseline' actionLabel={actionLabel} actionIcon={actionIcon}/>
            )}
            {(user?.type !== USER_TYPES.CONTENT_OWNER ||
              workflow?.assessmentSurvey ||
              (workflow.status === WORKFLOW_STATUSES.SUBMITTED_FOR_ASSESSMENT || workflow.status === WORKFLOW_STATUSES.REJECTED)) && (
              <>
                {workflow?.assessmentSurvey && actionLabel !== 'Update Assessment' && actionLabel !== 'View and Comment' ? (
                  <Stack spacing={1} alignItems='center'>
                    {user?.type !== USER_TYPES.CONTENT_OWNER &&
                      (user?.type !== USER_TYPES.ASSESSOR ||
                        ['Complete', 'Completed', 'Approved', 'Remediation'].indexOf(workflow?.status) === -1) && (
                          <ViewSurveyButton handlePreLoad={handlePreLoad} handleVisitAssessment={handleVisitAssessment} workflow={workflow} actionIcon={actionIcon} actionLabel={testSubmitAssessmentButton(assessmentSurvey, user?.type)
                            ? SURVEY_ACTIONS_LABELS.reviewAndSubmit
                            : assessmentActionLabel(workflow?.status, user).label}/>
                    )}
                    {user?.type === USER_TYPES.ASSESSOR && workflow?.status?.toLowerCase() === 'pre-assessment' && (
                      <Button
                        sx={{ maxWidth: '100%', width: '205px', minWidth: '170px' }}
                        size='small'
                        variant='outlined'
                        onClick={handleBeginAssessmentOpen}
                      >
                        Begin Assessment
                      </Button>
                    )}
                    {workflow?.status &&
                      ['Complete', 'Completed', 'Remediation'].indexOf(workflow?.status) === -1 &&
                      user?.type === USER_TYPES.CONTENT_OWNER && (
                        <ViewSurveyButton workflow={workflow} actionIcon={<DynamicFormIcon fontSize="small"/>} actionLabel={SURVEY_ACTIONS_LABELS.viewQuestionnaire}/>
                    )}
                    {workflow?.status &&
                      ['Complete', 'Completed', 'Remediation'].indexOf(workflow?.status) > -1 &&
                      user?.type === USER_TYPES.CONTENT_OWNER && (
                        <ViewSurveyButton workflow={workflow} handlePreLoad={handlePreLoad} handleVisitAssessment={handleVisitAssessment} actionIcon={<DynamicFormIcon fontSize="small"/>} actionLabel={SURVEY_ACTIONS_LABELS.viewQuestionnaire}/>
                    )}
                    { showReassignButton && (
                      <>
                        <Button
                          sx={{ maxWidth: '100%', width: '205px', minWidth: '170px' }}
                          size='small'
                          color={surveyStatusColor(survey)}
                          variant='outlined'
                          onClick={(e) => {
                            e.stopPropagation();
                            setAssessmentModalOpen(true);
                          }}
                        >REASSIGN</Button>
                      </>
                    )}
                  </Stack>
                ) : (
                  <>
                    { user && shouldShowViewSurveyButton(workflow, user, actionLabel) && (
                      <Box sx={{ mb: 1 }}>
                      {/* Primary Workflow access button */}
                      <ViewSurveyButton
                        limit={
                        ((user?.type === USER_TYPES.VENDOR_ADMIN || user?.type === USER_TYPES.ASSESSOR) && workflow.status === WORKFLOW_STATUSES.SUBMITTED) ||
                        (surveyStatusDisplay === SURVEY_ACTIONS_LABELS.pending && user?.type === USER_TYPES.TPN_ADMIN) || (actionLabel === 'Update Assessment' && workflow.status !== WORKFLOW_STATUSES.PRE_ASSESSMENT) ? 'Baseline' : undefined}
                        workflow={workflow}
                        actionIcon={<DynamicFormIcon
                        fontSize="small"/>}
                        handleVisitAssessment={user?.type === USER_TYPES.VENDOR_ADMIN && actionLabel === 'Update Assessment' && workflow.status === WORKFLOW_STATUSES.PRE_ASSESSMENT ? handleVisitAssessment : undefined}
                        actionLabel={SURVEY_ACTIONS_LABELS.viewQuestionnaire}
                        />
                      </Box>
                    )}
                    {(user?.type === USER_TYPES.VENDOR_ADMIN || (user?.type === USER_TYPES.ASSESSOR && workflow.status === WORKFLOW_STATUSES.SUBMITTED )) && (
                      <>
                        <Button
                          sx={{ maxWidth: '100%', width: '205px', minWidth: '170px' }}
                          size='small'
                          color={surveyStatusColor(survey)}
                          variant='outlined'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClearPreLoad();
                            if (
                              typeof surveyStatusDisplay === 'string' &&
                              surveyActLabel === SURVEY_ACTIONS_LABELS.scheduleAssessment
                            ) {
                              handleAssessmentOpen();
                            } else {
                              if (actionLabel === SURVEY_ACTIONS_LABELS.updateAssessment || actionLabel === 'View and Comment') {
                                handleVisitAssessment();
                              } else {
                                linkHandler((workflow?.bpSurvey || workflow?.baseline) as number);
                              }
                            }
                          }}
                        >
                        {actionLabel ===  SURVEY_ACTIONS_LABELS.updateAssessment || actionLabel === 'View and Comment' ? actionLabel : surveyActLabel}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Box sx={{ mt: 1 }}>
              <WorkflowDownloadsMenuButton workflow={workflow} company={company} site={site} />
            </Box>
            {/* FOR revert button */}
            {user?.type === USER_TYPES.TPN_ADMIN && workflow && renderRevertButton}
          </>
        )}
      </Box>
      <AssessmentForm modalOpen={assessmentModalOpen} company={company} closeCallback={handleAssessmentClose} assessment={cAssessment} />
      <StandardDialog title={'Begin Assessment'} handleClose={handleBeginAssessmentClose} isOpen={beginAssessmentOpen}>
        <Typography>Are you sure you want to begin this assessment?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={handleBeginAssessmentClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => updateAssessmentSurveyStatus('Assessing')}
            disabled={!user || user.type !== USER_TYPES.ASSESSOR}
          >
            Begin
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog title={`Revert ${site ? 'Site' : 'Application'} ${site?.name || (`${companyApplication?.application?.name}  ${version?.value}`)}?`} handleClose={handleRevertConfirmClose} isOpen={isRevertConfirmOpen}>
        <Typography>Are you sure you want to revert from {fromStatus} to {toStatus}?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={handleRevertConfirmClose}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            startIcon={<Undo />}
            onClick={handleRevert}
          >
            Revert
          </Button>
        </StandardDialogActions>
      </StandardDialog>
    </>
  );
};

export default WorkflowButtons;
