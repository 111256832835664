import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PartnerResources from './features/PartnerResources/PartnerResources';
import Dashboard from './features/Dashboard/Dashboard';
import Questionnaires from './features/Questionnaires/Questionnaires';
import Questionnaire from './features/Questionnaires/Questionnaire';
import Domain from './features/Domains/Domain';
import Topic from './features/Topics/Topic';
import BestPractice from './features/BestPractices/BestPractice';
import QuestionnaireManagement from './features/Questionnaires/QuestionnaireManagement';
import Certifications from './features/Topics/Certifications';
import Assessments from './features/Assessments/Assessments';
import Reporting from './features/Reporting/Reporting';
import CssBaseline from '@mui/material/CssBaseline';
import LoginPage from './features/Forms/LoginPage';
import ProtectedRoute from './features/Navigation/ProtectedRoute';
import OnboardForm from './features/Vendors/Onboarding/OnboardingForm';
import PageNotFound from './features/Helpers/PageNotFound';
import Profile from './features/Vendors/Profile';
import OnBoarding from './features/Vendors/Onboarding/OnBoarding';
import CompanyOnBoardingForm from './features/Vendors/Onboarding/CompanyOnBoardingForm';
import OnBoardingQuestionnaire from './features/Vendors/Onboarding/Questionnaire';
import PaymentWorkFlow from './features/Vendors/Onboarding/PaymentWorkFlow';
import AdminTabs from './features/Admin/AdminTabs';
import Directory from './features/Directory/Directory';
import CompanyDirectory from './features/Directory/CompanyDirectory';
import CompanyDetail from './features/Directory/CompanyDetail';
import UserProfile from './features/Users/UserProfile';
import Survey from './features/Surveys/Survey';
import Assessment from './features/Assessments/Assessment';
import WaitingForApproval from './features/Users/WaitingForApproval';
import ApplicationDirectory from './features/Applications/Applications';
import { useAppSelector } from './app/hooks';
import SharedReports from './features/SharedReports/SharedReports';
import { USER_TYPES } from './services/user';

function App() {
  const { user } = useAppSelector(state => state.user);
  return (
    <div>
      <CssBaseline />
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="sign-up" element={ <OnboardForm />} />
        <Route path="waiting-for-approval" element={<WaitingForApproval />} />
        <Route path="company-onboarding" element={<OnBoarding />} >
          <Route path="" element={<CompanyOnBoardingForm /> } />
          <Route path="questionnaire" element={<OnBoardingQuestionnaire />} />
          <Route path="payments" element={<PaymentWorkFlow />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          {(user?.type === USER_TYPES.TPN_ADMIN || user?.type === USER_TYPES.CONTENT_OWNER) && <Route path="dashboard" element={<Dashboard />} />}
          <Route path="partner-resources" element={<PartnerResources />} />
          <Route path="partner-resources/:resourceSection" element={<PartnerResources />} />
          <Route path="partner-resources/:resourceSection/:documentId" element={<PartnerResources />} />
          <Route path="shared-reports" element={<SharedReports />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/:profileSection" element={<Profile />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="registry" element={<Directory/>} >
            <Route path="" element={<CompanyDirectory />} />
            {(user?.type === USER_TYPES.TPN_ADMIN || user?.type === USER_TYPES.CONTENT_OWNER) && <Route path=":companyId" element={<CompanyDetail />} />}
          </Route>
          {user?.type === USER_TYPES.TPN_ADMIN && <Route path="admin" element={<AdminTabs />} />}
          <Route path="applications" element={<ApplicationDirectory />} />
          <Route path="questionnaire/:surveyId" element={<Survey />} />
          <Route path="questionnaire/:surveyId/?question=:questionId" element={<Survey />} />
          {user?.type === USER_TYPES.TPN_ADMIN && <Route path="questionnaires" element={<Questionnaires />} >
            <Route path="" element={<QuestionnaireManagement />} />
            <Route path="certifications" element={<Certifications />} />
            <Route path=":questionnaireSlug" element={<Questionnaire />} />
            <Route path=":questionnaireSlug/:domainSlug" element={<Domain />} />
            <Route path=":questionnaireSlug/:domainSlug/:topicSlug" element={<Topic />} />
            <Route path=":questionnaireSlug/:domainSlug/:topicSlug/:bestPracticeSlug" element={<BestPractice />} />
          </Route>}
          <Route path="assessments" element={<Assessments />} />
          <Route path="assessment/:assessmentId/questionnaire/:surveyId" element={<Assessment />} />
          <Route path="assessment/:assessmentId/questionnaire/:surveyId/?question=:questionId" element={<Assessment />} />
          {user?.type === USER_TYPES.TPN_ADMIN && <Route path="reporting" element={<Reporting />} />}
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
