import React from 'react';
import { Box, Typography, TextField, Checkbox, FormControlLabel, Tooltip, IconButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import moment, { Moment } from 'moment';

const StartEndDate = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isIndefinite,
  handleIndefiniteChange,
}: {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  isIndefinite: boolean;
  handleIndefiniteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {

  const handleStartDateChange = (newValue: Moment | null) => {
    if (newValue) {
      const adjustedStart = newValue.toDate();
      adjustedStart.setHours(0, 0, 0, 0);
      setStartDate(adjustedStart);

      if (endDate && endDate < adjustedStart) {
        const adjustedEnd = new Date(adjustedStart);
        adjustedEnd.setHours(23, 59, 59, 999);
        setEndDate(adjustedEnd);
      }
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = (newValue: Moment | null) => {
    if (newValue) {
      const adjustedEnd = newValue.toDate();
      adjustedEnd.setHours(23, 59, 59, 999);
      setEndDate(adjustedEnd);
    } else {
      setEndDate(null);
    }
  };

  return ( 
    <>
      <Typography variant="h6">Select Start and End Date for Sharing This Report</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
          {/* Start Date Picker */}
          <DesktopDatePicker
            label="Start Date"
            inputFormat="MM/DD/yyyy"
            value={startDate ? moment(startDate) : null}
            onChange={handleStartDateChange}
            minDate={moment().startOf('day')}
            renderInput={(params) => <TextField {...params} />}
          />
     
          {/* End Date Picker */}
          <DesktopDatePicker
            label="End Date"
            inputFormat="MM/DD/yyyy"
            value={endDate ? moment(endDate) : null}
            onChange={handleEndDateChange}
            minDate={startDate ? moment(startDate) : undefined}
            disabled={isIndefinite}
            renderInput={(params) => <TextField {...params} />}
          />
     
          {/* Indefinite Checkbox */}
          <FormControlLabel
            control={
              <Checkbox checked={isIndefinite} onChange={handleIndefiniteChange} />
            }
            label={
              <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                Indefinite (No End Date)
                <Tooltip title="You can return later to update or revoke this setting." arrow placement='top'>
                  <IconButton size="small" sx={{ padding: 0 }}>
                    <HelpOutlineIcon fontSize="small" sx={{ color: '#555' }} />
                  </IconButton>
                </Tooltip>
              </span>
            }
            sx={{ ml: '5px', alignSelf: 'flex-end' }}
          />
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default StartEndDate;
