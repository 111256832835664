// react and external-libs
import React, { useMemo } from 'react';
// MUI
import { Box, Collapse } from '@mui/material';
// interface
import { Application, Company, CompanyApplication, Version } from '../../../../../../interfaces/users.interface';
// components
import VersionDataRow from './VersionDataRow';
import { DataCell, DataRow } from '../../../../../Tables/DataList';

type VersionsAccordionProps = {
  isExpanded: boolean;
  company: Company;
  companyApp: CompanyApplication;
  application: Application;
  versions: Version[];
  onVersionRowClick: (companyApp: CompanyApplication, app: Application, version: Version) => void;
};

const VersionsAccordion = (props: VersionsAccordionProps) => {
  const { isExpanded, company, companyApp, application, versions, onVersionRowClick } = props;
  const cellStyle = useMemo(
    () => ({ background: 'none !important', color: '#575757 !important', fontWeight: 600 }),
    [],
  );

  return (
    <Collapse in={isExpanded}>
      <Box sx={{ border: '1px solid #E0E2E9' }}>
        <DataRow header sx={{ bgcolor: '#E7EAF3', marginLeft: 0, width: '100%' }}>
          <DataCell xs={1} textAlign='center' sx={cellStyle}>
            Shield
          </DataCell>
          <DataCell xs={3} textAlign='center' sx={cellStyle}>
            Version
          </DataCell>
          <DataCell xs={3} textAlign='center' sx={cellStyle}>
            Configuration Guidelines
          </DataCell>
          <DataCell xs={2} textAlign='center' sx={cellStyle}>
            Status
          </DataCell>
          <DataCell xs={3} sx={cellStyle}>
            Actions
          </DataCell>
        </DataRow>
        {versions.map((ver, ind) => (
          <VersionDataRow
            key={ver.id! + ind}
            company={company}
            companyApp={companyApp}
            version={ver}
            application={application}
            onClick={onVersionRowClick}
          />
        ))}
      </Box>
    </Collapse>
  );
};

export default VersionsAccordion;
