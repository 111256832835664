import axios from 'axios';

import getAuthSession from './auth';
import { Company, CompanySubmissionData, DocusignWaiver } from '../interfaces/users.interface';
import { CompaniesMeta, ServiceProviderBadgeMeta } from '../interfaces/metaData.interface';
import { CompanyResponse } from '../interfaces/company.interface';

export default async function createCompany(company: CompanySubmissionData) {
  company.logo = undefined;
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/companies/`,
    { ...company },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Company;
}

export async function updateCompany(company: CompanySubmissionData) {
  company.logo = undefined;
  delete company.isPaid;
  delete company.waiver;
  delete company.isPublished;
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/companies/${company.id}/`,
    { ...company },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Company;
}

export async function updateCompanyBoolean(company: any) {
  company.logo = undefined;
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/companies/${company.id}/`,
    { ...company },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Company;
}

export async function updateCompanyAsPaid(company: any) {
  company.logo = undefined;
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/companies/mark-company-as-paid/${company.id}/`,
    { ...company },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Company;
}

export async function updateCompanyLogo(companyID: number, logo: string | undefined) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/companies/${companyID}/`,
    { logo },
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data as Company;
}

export async function updateDocusignWaiver(waiver: DocusignWaiver) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/docusign-waivers/${waiver.id}/`,
    { ...waiver },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as DocusignWaiver;
}

export async function sendDocusignWaiver(company: Company, invoiceId?: number) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/docusign/waiver/`,
    { company_id: company.id, ...(invoiceId ? { invoice_id: invoiceId } : {}) },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function resendDocusignWaiver(company: Company) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/docusign/waiver/resend/`,
    { company_id: company.id },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function createCompanyWaiver(companyId: number) {
  const authSession = await getAuthSession();
  await axios.post(
    `${process.env.REACT_APP_BASE_API}/docusign/waiver/`,
    { companyId: companyId },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
}

export async function getCompany(companyId: string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/companies/${companyId}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Company;
}

export async function addCompanyEmployee(companyId: number, newEmployeeId: number) {
  const authSession = await getAuthSession();
  const company = await getCompany(companyId.toString());
  const employeeIds = company.employees.map(employee => employee.id);
  const updatedEmployees = [newEmployeeId, ...employeeIds];

  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/companies/${company.id}/`,
    { employees: updatedEmployees },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Company;
}

export async function getCompanies(searchParam?: string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/companies/list/${searchParam ? searchParam : ''}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Company[];
}

export async function getPaginatedCompanies(searchParam?: string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/companies/list/${searchParam ? searchParam : ''}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompanyResponse;
}

export async function resetSubsidiaryCompany(companyId: number) {
  const authSession = await getAuthSession();
  await axios.post(
    `${process.env.REACT_APP_BASE_API}/companies/reset-subsidiary/${companyId}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
}

export async function getCompaniesMetadata() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/companies/meta-data/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as CompaniesMeta;
}

export async function getCompaniesBadgeMetadata() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/companies/badges/meta-data/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as ServiceProviderBadgeMeta;
}
