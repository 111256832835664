import React, { ChangeEvent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Autocomplete, Card, CircularProgress, IconButton, InputAdornment, Stack, Switch, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

import { Application, ApplicationMeta, Company, CompanyApplication, CompanyApplicationSubmitData } from '../../interfaces/users.interface';
import { getApplications, updateApplication } from '../../services/applications';
import { useAppSelector } from '../../app/hooks';
import { getCompanies, getCompany, updateCompanyBoolean } from '../../services/company';
import { throttle } from 'lodash';
import axios, { AxiosResponse } from 'axios';
import getAuthSession from '../../services/auth';
import moment from 'moment';
import createCompanyApplication, { deleteCompanyApplication } from '../../services/companyApplication';
import { USER_TYPES } from '../../services/user';

let companies:Company[] = [];

interface ApplicationRow {
  id: number | undefined;
  name: string | undefined;
  description?: string;
  isPublic: boolean;
  adminApproved: boolean;
  company: Company | number | undefined;
  companyMeta: number[] | ApplicationMeta[] | undefined;
  application: Application;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{
        fileName: `tpn_applications_${new Date().toLocaleDateString()}`,
      }}/>
    </GridToolbarContainer>
  );
}

function createRowSwitch(appRow: ApplicationRow, field: string) {
  const { user } = useAppSelector(state => state.user);
  const userIsAdmin: boolean = user?.type ? user.type == USER_TYPES.TPN_ADMIN : false;
  const applicationId = `application-${appRow.id}`;
  const handleChange = (event: any) => {
    const updatedApplication  = {
      id: appRow.application.id,
      [event.target.name]: event.target.checked,
    };
    updateApplication(updatedApplication);
  };
  const fieldName: string = field as string;
  const fieldValue = appRow.application[fieldName as keyof typeof appRow.application];
  return (
    <Switch
      id={applicationId}
      disabled={!userIsAdmin}
      defaultChecked={typeof fieldValue == 'boolean' ? fieldValue : false}
      onChange={(event) => handleChange(event)}
      name={field}
    />
  );
}

function editCompanyRow(appRow: ApplicationRow) {
  const [value, setValue] = useState<string | number | Company | undefined>(typeof appRow.company === 'number' || typeof appRow.company === 'string' ? appRow.company : appRow.company?.name);
  const [view, setView] = useState<'static' | 'edit'>('static');
  const [itemList, setItemList] = useState<Company[]>([]);

  const saveApp = (company?: Company) => {
    const updatedApplication  = {
      id: appRow.application.id,
      company: company?.id,
    };
    updateApplication(updatedApplication).then(() => {
      setValue(company ? company.name : '-');
      setView('static');
    });

    if (!company) return;
    getCompany(company.id!.toString()).then(comp => {
      if (!comp || comp.companyApplications?.find(ca => ca.application.id === appRow.id)) return;
  
      const companyAppData: CompanyApplicationSubmitData = {
        company: comp.id!,
        application: appRow.id as number,
        sites: [],
        services: [],
      };
      createCompanyApplication(companyAppData).then((companyAppResponse: CompanyApplication) => {
        const companyApps = comp.companyApplications ? comp.companyApplications : [];
        updateCompanyBoolean({ id: comp.id, companyApplications: [...companyApps, companyAppResponse].map(ca => ca.id) });
      });
    });
  };

  const deleteApp = async () => {
    await updateApplication({ id: appRow.application.id, company: undefined });
    setValue('-');
    setView('static');
  
    const company = appRow.application.company as Company;
    const comp = await getCompany(company.id!.toString());
    const cApplication = comp.companyApplications?.find(ca => ca.application.id === appRow.application.id);
    if (!cApplication) return;
    await deleteCompanyApplication(cApplication);
  };

  const fetchItems = async (searchText: string) => {
    if (!searchText) return;
    const authSession = await getAuthSession();
    try {
      axios.get(
        `${process.env.REACT_APP_BASE_API}/companies/list/?search=${searchText}`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      ).then((response: AxiosResponse) => {
        setItemList(response.data.results);
      });
    } catch (error: any) {
      console.error(error.response.data);
    }
  };

  const handleCompanyChange = (event: any, item: Company | null) => {
    if (!item) return;
    saveApp(item);
  };

  const throttledFetchItems = throttle(fetchItems, 2500, { leading: true, trailing: true });

  const handleInputChange = async (event: ChangeEvent<{ value: unknown }>) => {
    await throttledFetchItems(event.target.value as string);
  };

  useEffect(() => {
    if (!companies.length || typeof value !== 'number') return;
    const selectedCompany = companies.find(co => co.id === value);
    if (selectedCompany) setValue(selectedCompany.name);
  }, [companies, value]);

  return (<>
    {view === 'static' && value}
    {view === 'edit' && 
      <Autocomplete
        id='company'
        fullWidth
        options={itemList}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, cvalue) => option.id == cvalue.id}
        onChange={handleCompanyChange}
        defaultValue={typeof appRow.company === 'string' || typeof appRow.company === 'number' ? undefined : appRow.company}
        onKeyDown={(e) => e.stopPropagation()}
        renderInput={(params) =>
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
            placeholder='Search Companies'
            InputProps={{
              ...params.InputProps,
              endAdornment: (<>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>),
            }}
          />
        }
      />
    }
    <Stack direction='row' gap={1} ml={1} sx={{ opacity: '.5' }}>
      {view === 'static' && <IconButton size='small' name='Edit Application' onClick={() => setView('edit')}><EditIcon /></IconButton>}
      {view === 'static' && value !== '-' && <IconButton size='small' name='Delete Application' onClick={() => deleteApp()}><DeleteIcon /></IconButton>}
      {view === 'edit' && <IconButton size='small' name='Cancel Edit' onClick={() => setView('static')}><CancelIcon /></IconButton>}
    </Stack>
  </>);
}

function displayMeta(appRow: ApplicationRow) {
  return (
    <>{!!appRow.companyMeta?.length ? (appRow.companyMeta as ApplicationMeta[])[0].title : '-'}</>
  );
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'createdAt', headerName: 'Created', width: 150 },
  { field: 'name', headerName: 'Name', width: 350 },
  { field: 'description', headerName: 'Description', width: 500 },
  {
    field: 'adminApproved',
    headerName: 'Admin Approved',
    width: 130,
    renderCell: params => createRowSwitch(params.row, 'adminApproved'),
  },
  {
    field: 'isPublic',
    headerName: 'Public',
    width: 130,
    renderCell: params => createRowSwitch(params.row, 'isPublic'),
  },
  { field: 'company', headerName: 'Company', width: 350, valueFormatter: ({ value }) => {
    const id = !value || typeof value === 'number' ? value : value.id;
    const comp: Company | undefined = companies && id && companies.find(co => co.id === id);
    return comp ? comp.name : '-';
  }, renderCell: params => editCompanyRow(params.row) },
  { field: 'companyMeta', headerName: 'Company Meta', width: 350, valueFormatter: ({ value }) => {
    try {
      return !!value?.length ? (value as ApplicationMeta[])[0].title : '-';
    } catch {
      return '-';
    }
  }, renderCell: params => displayMeta(params.row) },
];

function createApplicationRow(application: Application) {
  return {
    id: application.id,
    createdAt: moment(application.createdAt!).format('MM/DD/YYYY'),
    name: application.name,
    description: application.description,
    isPublic: application.isPublic,
    adminApproved: application.adminApproved ? application.adminApproved : false,
    company: application.company,
    companyMeta: application.meta,
    application: application,
  };
}

export default function CertificationAdminPanel() {

  const [rows, setRows] = useState<Array<ApplicationRow>>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getApplications().then((applications: Application[]) => {
      setRows(applications.sort((a, b) => a.name.localeCompare(b.name)).map((app: Application) => createApplicationRow(app)));
      setIsLoading(false);
    });
    getCompanies('?limit=9999').then(comp => companies = comp);
  }, []);
  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
      />
    </Card>
  );
}
