// react and external-libs
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
// MUI
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, Search, SwapHoriz } from '@mui/icons-material';
// redux
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { fetchDocument } from '../../../../../app/store/serviceCategories';
// interface
import { File } from '../../../../../interfaces/questionAnswer.interface';
import { Assessment } from '../../../../../interfaces/assessment.interface';
import { HardeningDocument } from '../../../../../interfaces/hardeningDocuments';
import {
  Application,
  ApplicationMeta,
  ApplicationReponse,
  ApplicationType,
  Company,
  CompanyApplication,
  Version,
} from '../../../../../interfaces/users.interface';
// service
import { selectBadgeFromArray } from '../../../../../services/badge';
import { getApplicationsFull, getBadgesFromVersions, getVersion } from '../../../../../services/applications';
import { deleteCompanyApplication, updateCompanyApplicationSimple } from '../../../../../services/companyApplication';
// components
import GetBadge from '../../../GetBadge';
import ChipsWithOverflow from '../../../../Layout/ChipsWithOverflow';
import { DataCell, DataRow, DataRowActions } from '../../../../Tables/DataList';
import SelectSitesAndServices from '../../ApplicationModals/SelectSitesAndServices';
import StandardDialog, { StandardDialogActions } from '../../../../Modals/StandardDialog';
import { USER_TYPES } from '../../../../../services/user';

type LicensedApplicationDataRowProps = {
  company: Company;
  companyApplication: CompanyApplication;
  assessment?: Assessment;
  userCanAdminCompany: boolean;
  companySuccessCallback: any;
};

const LicensedApplicationDataRow = (props: LicensedApplicationDataRowProps) => {
  const { company, companyApplication, userCanAdminCompany, companySuccessCallback } = props;
  // redux
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { documents } = useAppSelector((state) => state.serviceCategories);
  // states
  const [search, setSearch] = useState('');
  const [applications, setApplications] = useState<ApplicationReponse | undefined>(undefined);
  const [cApplication, setcApplication] = useState(companyApplication);
  const [version, setVersion] = useState<Version | undefined>(undefined);
  const [shouldOpenDoc, setShouldOpenDoc] = useState<number | undefined>(undefined);
  const [editApplicationOpen, setEditApplicationOpen] = useState(false);
  const [deleteApplicationOpen, setDeleteApplicationOpen] = useState(false);
  const [replaceApplication, setReplaceApplication] = useState<undefined | Application>(undefined);
  const [replaceApplicationOpen, setReplaceApplicationOpen] = useState(false);
  // local variables
  const offset = 0;
  const limit = 25;
  const siteNames = useMemo(() => cApplication?.sites?.map((site) => site.name), [cApplication?.sites]);
  const serviceNames = useMemo(() => cApplication?.services?.map((service) => service.name), [cApplication?.services]);
  const applicationTypes = useMemo(
    () => (cApplication?.application?.applicationTypes as ApplicationType[])?.map((app) => app?.type),
    [cApplication?.application?.applicationTypes],
  );
  // short/simple functions
  const handleEditApplicationOpen = useCallback(() => setEditApplicationOpen(true), []);
  const handleEditApplicationClose = useCallback(() => setEditApplicationOpen(false), []);
  const handleDeleteApplicationOpen = useCallback(() => setDeleteApplicationOpen(true), []);
  const handleDeleteApplicationClose = useCallback(() => setDeleteApplicationOpen(false), []);
  const handleReplaceApplicationOpen = useCallback(() => setReplaceApplicationOpen(true), []);

  const handleReplaceApplicationClose = useCallback(() => {
    setReplaceApplicationOpen(false);
    setReplaceApplication(undefined);
    setSearch('');
  }, []);

  const handleApplicationDeleteSubmit = useCallback(async () => {
    await deleteCompanyApplication(cApplication);
    const updatedCompany: Company = {
      ...company,
      companyApplications: company.companyApplications?.filter(
        (companyApp: CompanyApplication) => companyApp.id != cApplication.id,
      ),
    };
    companySuccessCallback(updatedCompany);
    handleDeleteApplicationClose();
  }, [cApplication, company.companyApplications]);

  const handleApplicationReplaceSubmit = useCallback(async () => {
    if (!replaceApplication) return;

    const newCA = await updateCompanyApplicationSimple(cApplication.id!, {
      application: replaceApplication.id,
    });
    if (!newCA) return;

    let companyApps = company.companyApplications ? company.companyApplications : [];
    const caIndex = companyApplication ? companyApps.findIndex((ca) => ca.id === companyApplication.id) : -1;

    if (caIndex > -1) {
      companyApps[caIndex] = newCA;
    } else {
      companyApps = [...companyApps, newCA];
    }

    const updatedCompany = {
      ...company,
      companyApplications: companyApps,
    };
    if (!updatedCompany) return;

    handleReplaceApplicationClose();
    companySuccessCallback(updatedCompany);
  }, [replaceApplication, cApplication.id, company, companyApplication]);

  const updateCompanyApp = useCallback(
    async (avid: number) => {
      const newCA = await updateCompanyApplicationSimple(cApplication.id!, {
        versions: [avid],
      });
      if (!newCA) return;

      let companyApps = company.companyApplications ? company.companyApplications : [];
      const caIndex = companyApplication ? companyApps.findIndex((ca) => ca.id === companyApplication.id) : -1;

      if (caIndex > -1) {
        companyApps[caIndex] = newCA;
      } else {
        companyApps = [...companyApps, newCA];
      }

      const updatedCompany = {
        ...company,
        companyApplications: companyApps,
      };
      if (!updatedCompany) return;

      companySuccessCallback(updatedCompany);
    },
    [cApplication.id, company, companyApplication],
  );

  const fetchApplications = useCallback(
    async (searchString: string) => {
      setApplications(undefined);
      const appList = await getApplicationsFull({
        search: searchString,
        limit,
        offset,
        is_public: true,
        admin_approved: true,
      });
      setApplications(
        user?.type === USER_TYPES.TPN_ADMIN
          ? appList
          : {
            ...appList,
            results: appList.results.filter(
              (app) =>
                !app.company || !(app.company as Company).isHidden,
            ),
          },
      );
    },
    [user?.type],
  );

  const openFile = useCallback((doc: File) => {
    const link = document.createElement('a');
    link.setAttribute('href', doc.document);
    link.setAttribute('target', '_blank');
    link.click();
  }, []);

  const openDoc = useCallback(
    (docid: number) => {
      if (!documents.find((doc) => doc.id === docid)) {
        dispatch(fetchDocument(docid));
        setShouldOpenDoc(docid);
        return;
      }

      const doc = documents.find((d) => d.id === docid);
      if (!doc) return;

      openFile(doc);
    },
    [documents],
  );

  const getDocs = useMemo(
    () => (cv: Version) => {
      if (!cApplication.application.hardeningDocuments?.length) return [];
      const curDocs = (cApplication.application.hardeningDocuments as HardeningDocument[]).filter(
        (hd) => hd.version === cv.value,
      );
      if (!curDocs) return '';
      return curDocs.map((cd) => <Chip key={cd.id} label={cd.type} onClick={() => openDoc(cd.document)} />);
    },
    [cApplication.application.hardeningDocuments],
  );

  const debounceSetSearch = useRef(
    debounce((event: React.ChangeEvent<{ value: string }>) => {
      setSearch(event.target.value);
    }, 1000),
  ).current;

  // search applications
  useEffect(() => {
    fetchApplications(search);
  }, [search]);

  // fetch and set version
  useEffect(() => {
    setcApplication(companyApplication);
    if (!companyApplication.versions?.length) return setVersion(undefined);
    getVersion(companyApplication.versions[0]).then((av) => setVersion(av));
  }, [companyApplication]);

  // open document file
  useEffect(() => {
    if (!shouldOpenDoc || !documents.find((doc) => doc.id === shouldOpenDoc)) return;
    const doc = documents.find((d) => d.id === shouldOpenDoc);
    setShouldOpenDoc(undefined);
    if (!doc) return;
    openFile(doc);
  }, [documents]);

  return (
    <>
      <DataRow>
        <DataCell xs={1} textAlign='center'>
          {version && (cApplication.application.company as Company)?.isPublished && (
            <GetBadge shield={selectBadgeFromArray(getBadgesFromVersions(cApplication.application?.versions as Version[]))} />
          )}
        </DataCell>
        <DataCell xs={2}>
          <Typography sx={{ fontSize: '1.2em' }}>
            {(cApplication.application.company as Company)?.name}
            {!cApplication.application.company &&
              !!cApplication.application.meta?.length &&
              (cApplication.application.meta as ApplicationMeta[])[0].title}{' '}
            {cApplication.application.name}
          </Typography>
        </DataCell>
        <DataCell xs={1}>
          {version?.value ? (
            <Tooltip title={version.value}>
              <Typography sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {version.value}
              </Typography>
            </Tooltip>
          ) : (
            <>
              {cApplication.application.versions && cApplication.application.versions?.length ? (
                <Select>
                  {(cApplication.application.versions as Version[])?.map((av) => (
                    <MenuItem key={av.id} onClick={() => updateCompanyApp(av.id!)}>
                      {av.value}
                    </MenuItem>
                  ))}
                </Select>
              ) : null}
            </>
          )}
        </DataCell>
        <DataCell xs={1.5} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '0.25rem', padding: '0.25rem' }}>
          {ChipsWithOverflow(siteNames, 1, 'site')}
        </DataCell>
        <DataCell xs={2} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '0.25rem', padding: '0.25rem' }}>
          {ChipsWithOverflow(serviceNames, 1, 'service')}
        </DataCell>
        <DataCell xs={1.5}>{version && getDocs(version)}</DataCell>
        <DataCell xs={2} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '0.25rem', padding: '0.25rem' }}>
          {ChipsWithOverflow(applicationTypes, 1, 'appType')}
        </DataCell>
        <DataCell xs={1}>
          {userCanAdminCompany && (
            <DataRowActions>
              {user?.type === USER_TYPES.TPN_ADMIN && (
                <IconButton
                  size='small'
                  name='Replace Application'
                  onClick={handleReplaceApplicationOpen}
                  disabled={!userCanAdminCompany}
                >
                  <SwapHoriz />
                </IconButton>
              )}
              <IconButton
                size='small'
                name='Edit Application'
                onClick={handleEditApplicationOpen}
                disabled={!userCanAdminCompany}
              >
                <Edit />
              </IconButton>
              <IconButton
                size='small'
                name='Delete Application'
                onClick={handleDeleteApplicationOpen}
                disabled={!userCanAdminCompany}
              >
                <Delete />
              </IconButton>
            </DataRowActions>
          )}
        </DataCell>
      </DataRow>
      <StandardDialog
        isOpen={deleteApplicationOpen}
        title={`Delete ${cApplication.application.name} ?`}
        handleClose={handleDeleteApplicationClose}
      >
        <Typography>Are you sure you want to delete {cApplication.application.name}?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={handleDeleteApplicationClose}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='error'
            startIcon={<Delete />}
            onClick={handleApplicationDeleteSubmit}
            disabled={!userCanAdminCompany}
          >
            Delete
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog
        isOpen={replaceApplicationOpen}
        title={`Replace ${cApplication.application.name} ?`}
        handleClose={handleReplaceApplicationClose}
      >
        <Autocomplete
          fullWidth
          id='newApplication'
          options={applications?.results || []}
          getOptionLabel={(option) => {
            return `${option.company ? (option.company as Company).name : ''}${
              !option.company && !!option.meta?.length ? (option.meta as ApplicationMeta[])[0].title : ''
            } ${option.name}`;
          }}
          isOptionEqualToValue={(option, value) => option.id == value.id}
          onChange={(_: any, item: any | null) => setReplaceApplication(item)}
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant='outlined'
              margin='normal'
              placeholder='Search Applications'
              onChange={debounceSetSearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {replaceApplication && (
          <Typography>
            Are you sure you want to replace {cApplication.application.name} with {replaceApplication.name}?
          </Typography>
        )}
        <StandardDialogActions>
          <Button variant='outlined' onClick={handleReplaceApplicationClose}>
            Cancel
          </Button>
          <Button
            variant='outlined'
            startIcon={<SwapHoriz />}
            onClick={handleApplicationReplaceSubmit}
            disabled={!userCanAdminCompany || !replaceApplication}
          >
            Replace
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <SelectSitesAndServices
        isOpen={editApplicationOpen}
        company={company}
        companyApplication={cApplication}
        callBack={companySuccessCallback}
        handleClose={handleEditApplicationClose}
      />
    </>
  );
};

export default LicensedApplicationDataRow;
