import React from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

interface RoundIconButtonProps {
  onClick: () => void;
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}
const RoundIconButton: React.FC<RoundIconButtonProps> = ({ onClick, color = 'default', size = 'small', disabled = false }) => {
  return (
      <IconButton
        style={{ borderRadius: '50%', width: '32px', height: '32px', fontSize: '16px', opacity: disabled ? 0 : 1 }}
        onClick={onClick}
        color={color}
        size={size}
        disabled={disabled}
      >
        <ClearIcon fontSize="inherit" />
      </IconButton>
  );
};

export default RoundIconButton;