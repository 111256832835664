import React, { useState, useEffect, useCallback, useMemo } from 'react';
// MUI
import {
  Box,
  Button,
  Stepper,
  Step,
  StepButton,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// Services
import { getCompany } from '../../../services/company';
import { fetchWorkflows } from '../../../services/workflow';
// Interfaces
import { Badge, Company, CompanyApplication, Contact } from '../../../interfaces/users.interface';
import { Workflow } from '../../../interfaces/workflow.interface';
import { Engagement, Grantee } from '../../../interfaces/engagements';
// Hooks
import { useAppSelector } from '../../../app/hooks';
// Components
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
// Steps
import ShareWith from '../Steps/ShareWith';
import StartEndDate from '../Steps/StartEndDate';
import SiteAppReportType from '../Steps/SiteAppReportType';
import ReviewAndConfirm from '../Steps/ReviewAndConfirm';
import TermsAndConditions from './TermsAndConditionsModal';

const steps = ['Select Authorized Users', 'Select Site or Application', 'Select Access Duration', 'Review & Confirm'];

const ShareReportModal = ({
  isOpen,
  handleClose,
  handleShareReport,
  inheritedReport,
  authorizedUsers,
}: {
  isOpen: boolean;
  authorizedUsers?: Grantee[];
  inheritedReport?: Engagement | null;
  handleClose: () => void;
  handleShareReport: ({
    sharedReportTypes,
    selectedReportTypes,
    startDate,
    endDate,
    workflows,
    company,
    questionnaireType,
    siteOrApplication,
    employeeList,
  } :{
    sharedReportTypes: string[];
    selectedReportTypes: string[];
    startDate: Date;
    endDate: Date | null;
    workflows: Workflow[];
    company: Company;
    questionnaireType: string;
    siteOrApplication: Contact | CompanyApplication;
    employeeList: Grantee[]
  }) => Promise<boolean>;
}) => {
  const { user } = useAppSelector((state) => state.user);
  const [activeStep, setActiveStep] = useState(0);
  const [company, setCompany] = useState<Company | null>(null);
  const [companyOptions, setCompanyOptions] = useState<Company[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Grantee[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isIndefinite, setIsIndefinite] = useState<boolean>(false);
  const [selectedReportTypes, setSelectedReportTypes] = useState<string[]>([]);

  const [type, setType] = useState<string>('');
  const [siteOrApplication, setSiteOrApplication] = useState<Contact | CompanyApplication | null>(null);
  const [siteList, setSiteList] = useState<Contact[]>([]);
  const [applicationList, setApplicationList] = useState<CompanyApplication[]>([]);
  const [questionnaireType, setQuestionnaireType] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const [employeeList, setEmployeeList] = useState<Grantee[]>([]);
  const [questionnaireTypes, setQuestionnaireTypes] = useState<string[]>([]); 
  const [workflows, setWorkflows] = useState<Workflow[]>([]);

  const allRequiredFieldsSet = useMemo(() => {
    if (!startDate) return false;

    const isValidDate = (date: unknown): date is Date => date instanceof Date && !isNaN(date.getTime());

    const isStartDateValid = isValidDate(startDate);
    const isEndDateValid = isIndefinite || (isValidDate(endDate) && endDate > startDate);

    return (
      company &&
      selectedEmployees.length > 0 &&
      type &&
      siteOrApplication &&
      questionnaireType &&
      selectedReportTypes.length > 0 &&
      isStartDateValid &&
      isEndDateValid
    );
  }, [company, selectedEmployees, type, siteOrApplication, questionnaireType, selectedReportTypes, startDate, endDate, isIndefinite]);

  const availableBadges = useMemo(() => {
    if (!workflows || !questionnaireType) return;
    const selectedWf = workflows.find((workflow) => workflow.workflowType === questionnaireType);
    return selectedWf?.badges as Badge[] || [];
  }, [workflows, questionnaireType]);

  const handleIndefiniteChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsIndefinite(event.target.checked);
    if (event.target.checked) {
      setEndDate(null);
    }
  }, []);

  const handeSetSiteOrApplication = useCallback( async (siteOrApp: Contact | CompanyApplication | null) => {
    setSiteOrApplication(siteOrApp);
    if (!siteOrApp || !user) return;
    const siteAppWorkflows = await fetchWorkflows(
      'application' in siteOrApp ? {
        application: siteOrApp.application.id,
        service_provider: user.companies[0]?.id,
      } : {
        site: siteOrApp.id,
        service_provider: user.companies[0]?.id,
      }, 
      true,
    );

    setWorkflows(siteAppWorkflows);
    setQuestionnaireTypes(siteAppWorkflows.map((workflow) => workflow.workflowType));
  }, [user]);

  // Handle step click
  const handleStepClick = useCallback((step: number) => {
    setActiveStep(step);
  }, []);

  const resetState = useCallback(() => {
    setActiveStep(0);
    setCompany(null);
    setCompanyOptions([]);
    setSelectedEmployees([]);
    setStartDate(null);
    setEndDate(null);
    setIsIndefinite(false);
    setType('');
    setSiteOrApplication(null);
    setQuestionnaireType('');
    setTermsAccepted(false);
    setTermsOpen(false);
    setEmployeeList([]);
    setQuestionnaireTypes([]);
    setWorkflows([]);
  }, []);

  const handleSave = useCallback(async () => {
    if (activeStep === 3) {
      try {
        const engagementCreated = await handleShareReport({
          sharedReportTypes: [questionnaireType],
          startDate: startDate as Date,
          endDate,
          workflows,
          company: company as Company,
          questionnaireType: questionnaireType,
          siteOrApplication: siteOrApplication as Contact | CompanyApplication,
          selectedReportTypes,
          employeeList: selectedEmployees,
        });

        if (!engagementCreated) return;
        handleClose();
        resetState();
      } catch (error) {
        console.error('Error sharing report:', error);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, company, selectedEmployees, startDate, endDate, type, siteOrApplication, questionnaireType, handleShareReport, handleClose, workflows, selectedReportTypes, employeeList]);

  // Fetch user's primary company data
  useEffect(() => {
    if (!user?.companies) return;
    const fetchCompany = async () => {
      if (!user?.companies[0]) return;
      const companyFetched = await getCompany((user.companies[0]?.id || '').toString());
      if (companyFetched) {
        setSiteList(companyFetched.locations);
        setApplicationList(companyFetched.companyApplications || []);
      }
    };
    fetchCompany();
  }, [user?.companies]);

  useEffect(() => {
    if (isOpen) return;
    resetState();
  }, [isOpen]);

  useEffect(() => {
    if (inheritedReport) {
      const customWorkflow = inheritedReport.workflow as {
        siteName?: string;
        applicationName?: string;
        workflowType: string;
      };
      const siteOrAppType = !!customWorkflow.siteName ? 'site' : 'application';
      setType(siteOrAppType);
      const localSiteOrApplication = siteOrAppType === 'site'
        ? (siteList as Contact[]).find((site) => site.name === customWorkflow.siteName) || null
        : applicationList.find((app) => app.application.name === customWorkflow.applicationName) || null;
  
      setSiteOrApplication(localSiteOrApplication);
      handeSetSiteOrApplication(localSiteOrApplication);
      setQuestionnaireType(customWorkflow.workflowType);
      setSelectedReportTypes([...inheritedReport.sharedReportTypes]);
    }
  }, [inheritedReport, siteList, applicationList, company]);

  return (
    <>
      <StandardDialog
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DescriptionOutlinedIcon  fontSize="medium" />
            { inheritedReport ? 'Copy & Re-Share Report' : 'Share New Report' }
        </Box>
      }
        isOpen={isOpen}
        maxWidth="lg"
        handleClose={handleClose}
      >
        <Stepper nonLinear activeStep={activeStep} sx={{ my: 3 }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={() => handleStepClick(index)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 2 }}>
          {/* Step 1: Select Company */}
          {activeStep === 0 && (
            <ShareWith
              userCompany={user?.companies[0]}
              inheritedUsers={authorizedUsers}
              inheritedReport={inheritedReport}
              selectedEmployees={selectedEmployees}
              setSelectedEmployees={setSelectedEmployees}
              companyOptions={companyOptions}
              setCompanyOptions={setCompanyOptions}
              setEmployeeList={setEmployeeList}
              company={company}
              setCompany={setCompany}
            />
          )}
          {/* Step 2: Select Site or Application */}
          {activeStep === 1 && (
            <SiteAppReportType
              inheritedReport={inheritedReport}
              type={type}
              setType={setType}
              siteOrApplication={siteOrApplication}
              setSiteOrApplication={handeSetSiteOrApplication}
              setSelectedReportTypes={setSelectedReportTypes}
              selectedReportTypes={selectedReportTypes}
              questionnaireType={questionnaireType}
              questionnaireTypes={questionnaireTypes}
              availableBadges={availableBadges}
              setQuestionnaireType={setQuestionnaireType}
              siteList={siteList}
              applicationList={applicationList}
            />
          )}
          {/* Step 3: Select Start & End Date */}
          {activeStep === 2 && (
            <StartEndDate
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isIndefinite={isIndefinite}
              handleIndefiniteChange={handleIndefiniteChange}
            />
          )}
          {/* Step 4: Review & Confirm */}
          {activeStep === 3 && (
            <ReviewAndConfirm
              type={type}
              selectedEmployees={selectedEmployees}
              siteOrApplication={siteOrApplication}
              questionnaireType={questionnaireType}
              employeeList={employeeList}
              company={company}
              termsAccepted={termsAccepted}
              setTermsOpen={setTermsOpen}
              isIndefinite={isIndefinite}
              startDate={startDate}
              endDate={endDate}
              selectedReportTypes={selectedReportTypes}
            />
          )}
        </Box>
        <StandardDialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', my:1 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                onClick={() => setActiveStep(activeStep - 1)}
                variant="outlined"
                disabled={activeStep === 0}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
              <Button
                onClick={() => setActiveStep(activeStep + 1)}
                variant="outlined"
                disabled={activeStep === steps.length - 1}
                endIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
            </Box>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={ !termsAccepted || !allRequiredFieldsSet || activeStep !== steps.length - 1 }
            >
              Confirm & Share
            </Button>
          </Box>
        </StandardDialogActions>
      </StandardDialog>
      {/* Terms and Conditions Dialog */}
      <TermsAndConditions
        termsOpen={termsOpen}
        setTermsOpen={setTermsOpen}
        setTermsAccepted={setTermsAccepted}
      />
    </>
  );
};

export default ShareReportModal;
