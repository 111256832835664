import React from 'react';
import { Button, Box, Typography, Grid, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Login from './Login';
import LoginContainer from '../Layout/LoginContainer';

import { HelpOutline } from '@mui/icons-material';
import { getHelpURL } from '../../services/user';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Copyright from '../Layout/Copyright';
import { logoutUser } from '../../app/store/userSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function OnboardForm() {
  const { user } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  const handleLogout = () => {
    dispatch(logoutUser());
    signOut();
    navigate('/', { replace: true });
  };

  return (
    <LoginContainer>
      <Login initialState='signIn' />
      <Divider />
      <Box sx={{ textAlign: 'center' }}>
        <Grid container sx={{ width: '100%', mt: 2, mb: 2 }} columnSpacing={2}>
          <Grid item xs={6}><Typography>Are you a new Service Provider?<br /><Link to="/sign-up"><Button>Sign Up Now</Button></Link></Typography></Grid>
          <Grid item xs={6}><Typography>If you are a new Assessor<br /><a href="mailto:support@ttpn.org" target="_blank" rel="noopener noreferrer"><Button>Email support@ttpn.org</Button></a></Typography></Grid>
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ display: 'flex', gap: '1em', justifyContent: 'center' }}>
          {!user && <Typography>Having trouble? Email <a href="mailto:support@ttpn.org" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>support@ttpn.org</a> for assistance.</Typography>}
          {user && <a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer"><Button variant='contained' onClick={()=> { return false; }}><HelpOutline sx={{ mr: 1 }} />Need Support?</Button></a> }
          {user && <Button onClick={() => handleLogout()}>Sign Out</Button>}
        </Box>
      </Box>
      <Copyright sx={{ mt: 6 }} />
    </LoginContainer>
  );
}
