export const calculateDaysAgo = (notificationDate: Date): number => {
  const today = new Date();
  
  // Clear the time to compare only dates
  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const notificationStart = new Date(notificationDate.getFullYear(), notificationDate.getMonth(), notificationDate.getDate());
  
  // Calc the difference
  const diffInMs = todayStart.getTime() - notificationStart.getTime();
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
};