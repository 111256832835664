import React, { useMemo } from 'react';

import { Typography, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Badge, Company, CompanyApplication, Contact } from '../../../interfaces/users.interface';
import { WORKFLOW_MAP } from '../../../services/workflow';
import { Engagement } from '../../../interfaces/engagements';

const LabelWithImage = ({ type }: { type:  'Blue' | 'Gold' }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <img 
            src={`${process.env.PUBLIC_URL}/assets/TPN_${type}LogoTransparent_1280.png`}
            alt="TPN Logo" 
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%', 
              padding: '2px',
              backgroundColor: '#fff',
            }}
        />
        <span>{type === 'Gold' ? 'Assessment' : 'Questionnaire'} Report</span>
    </div>
);

const SiteAppReportType = ({
  type,
  setType,
  siteOrApplication,
  setSiteOrApplication,
  questionnaireType,
  questionnaireTypes,
  setQuestionnaireType,
  selectedReportTypes,
  setSelectedReportTypes,
  availableBadges,
  siteList,
  applicationList,
}: {
  type: string;
  inheritedReport?: Engagement | null;
  setType: (type: string) => void;
  siteOrApplication: Contact | CompanyApplication | null;
  setSiteOrApplication: (siteOrApp: Contact | CompanyApplication | null) => Promise<void>;
  questionnaireType: string;
  questionnaireTypes: string[];
  availableBadges?: Badge[];
  selectedReportTypes: string[];
  setSelectedReportTypes: (reportTypes: string[]) => void;
  setQuestionnaireType: (questionnaireType: string) => void;
  siteList: Contact[];
  applicationList: CompanyApplication[];
}) => {
  const showBlueBadge = useMemo(() => availableBadges?.some((badge) => badge.title === 'tpn_self_reported'), [availableBadges]);
  const showGoldBadge = useMemo(() => availableBadges?.some((badge) => badge.title === 'tpn_assessed'), [availableBadges]);
  return (
        <>
        <Typography variant="h6">Which Site/Application and Report Are You Sharing?</Typography>
        <FormControl fullWidth>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            label="Type"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              setSiteOrApplication(null);
              setQuestionnaireType('');
              setSelectedReportTypes([]);
            }}
          >
            <MenuItem value="site">Site</MenuItem>
            <MenuItem value="application">Application</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="site-application-label">Select {type === 'site' ? 'Site' : 'Application'}</InputLabel>
          <Select
            label={`Select ${type === 'site' ? 'Site' : 'Application'}`}
            labelId="site-application-label"
            value={siteOrApplication ? siteOrApplication.id : ''}
            onChange={(e) => {
              const selectedItem =
                type === 'site'
                  ? siteList.find((site) => site.id === e.target.value)
                  : applicationList.find((app) => app.id === e.target.value);
              
              setSiteOrApplication(selectedItem || null);
              setQuestionnaireType('');
            }}
            disabled={!type}
          >
            {(type === 'site' ? siteList : applicationList).map((item) => (
              <MenuItem key={item.id} value={item.id}>{type === 'site' ? (item as Company).name : (((item as CompanyApplication).application).name)}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Questionnaire Type Selection */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="questionnaire-type-label">Questionnaire Version</InputLabel>
          <Select
            labelId="questionnaire-type-label"
            label="Questionnaire Version"
            value={questionnaireType}
            onChange={(e) => setQuestionnaireType(e.target.value)}
          >
            {questionnaireTypes.map((qType) => (
              <MenuItem key={qType} value={qType}>{WORKFLOW_MAP[qType as keyof typeof WORKFLOW_MAP].label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Shield Type Selection */}
        <FormGroup sx={{ pl: 2, display: 'flex', flexDirection: 'row' }}>
        { showBlueBadge && (
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedReportTypes.includes('tpn_self_reported')}
                onChange={(e) => {
                  const newReportTypes = e.target.checked
                    ? [...selectedReportTypes, 'tpn_self_reported']
                    : selectedReportTypes.filter((b) => b !== 'tpn_self_reported');
                  setSelectedReportTypes(newReportTypes);
                }}
              />
            }
            label={<LabelWithImage type='Blue' />}
          />
        )}
        { showGoldBadge && (
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedReportTypes.includes('tpn_assessed')}
                onChange={(e) => {
                  const newReportTypes = e.target.checked
                    ? [...selectedReportTypes, 'tpn_assessed']
                    : selectedReportTypes.filter((b) => b !== 'tpn_assessed');
                  setSelectedReportTypes(newReportTypes);
                }}
              />
            }
            label={<LabelWithImage type='Gold' />}
          />
        )}
        { !showBlueBadge && !showGoldBadge && questionnaireType && (
          <Typography variant="body2" sx={{ color: 'gray' }}>No reports available for the selected {type} and Questionnaire version</Typography>
        )}
        </FormGroup>
      </>
  );
};

export default SiteAppReportType;