import React, { useState, useMemo } from 'react';
// MUI
import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
// Components
import StandardDialog from '../../Modals/StandardDialog';
// Icons
import DownloadIcon from '@mui/icons-material/Download';
// Interfaces
import { PartnerResource } from '../../../interfaces/partnerResources.interface';

const KeyTermsAndDefinitions = ({
  handleDownloadPartnerResource,
  filteredSections,
}: {
  filteredSections: PartnerResource[];
  handleDownloadPartnerResource: (section: PartnerResource) => void;
}) => {
  const [selectedTerm, setSelectedTerm] = useState<PartnerResource | null>(null);
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const [filterLetter, setFilterLetter] = useState<string | null>(null);

  // Filtered list based on selected letter
  const filteredList = useMemo(() => filterLetter
    ? filteredSections.filter(item => item.title.startsWith(filterLetter))
    : filteredSections, [filterLetter, filteredSections]);

  return (
        <Box sx={{ padding: 2, pl: 3, pr: 0 }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, px: 1, pr: 0 }}>
          <Typography variant="body2" sx={{ color: '#666', fontStyle: 'italic' }}>
            Click on a term to view its definition.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            sx={{ textTransform: 'none', whiteSpace: 'nowrap', position: 'absolute', right: 60, top: 180 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDownloadPartnerResource(filteredSections[0]);
            }}
          >
            Download
          </Button>
        </Box>          
        {/* A-Z Filter */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, ml: 1 }}>
          <List sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
            <ListItem
              button
              onClick={() => setFilterLetter(null)}
              selected={filterLetter === null}
              sx={{
                width: 'auto',
                padding: '4px 8px',
                minWidth: 36,
                justifyContent: 'center',
              }}
            >
              <ListItemText
                primary="All"
                sx={{ textAlign: 'center', fontSize: '18px', color: filterLetter === null ? '#1976d2' : '#666' }}
              />
            </ListItem>
            {alphabet.map((letter) => (
              <ListItem
                key={letter}
                button
                onClick={() => setFilterLetter(letter)}
                selected={filterLetter === letter}
                sx={{
                  width: 'auto',
                  padding: '4px 8px',
                  minWidth: 36,
                  justifyContent: 'center',
                  '&:hover': { backgroundColor: '#f5f5f5' },
                }}
              >
                <ListItemText
                  primary={letter}
                  sx={{
                    textAlign: 'center',
                    fontSize: '18px',
                    color: filterLetter === letter ? '#1976d2' : '#666',
                    fontWeight: filterLetter === letter ? 'bold' : 'normal',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* List of Terms */}
        <List>
          {filteredList.length > 0 ? (
            filteredList.map((item, index) => (
              <ListItem key={index} button onClick={() => setSelectedTerm(item)}>
                <ListItemText
                  primary={item.title}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 600,
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" sx={{ color: '#666', textAlign: 'center', mt: 2 }}>
            No terms found for &quot;{filterLetter}&quot;
            </Typography>
          )}
        </List>

        <StandardDialog
          maxWidth="sm"
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {selectedTerm?.title}
            </Box>
          }
          isOpen={Boolean(selectedTerm)}
          handleClose={() => setSelectedTerm(null)}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            {/* Term Definition */}
            <Typography
              id="term-definition-description"
              sx={{
                mt: 1,
                color: '#333',
                lineHeight: 1.6,
                letterSpacing: '0.2px',
              }}
            >
              {selectedTerm?.description}
            </Typography>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => setSelectedTerm(null)} variant="outlined" color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </StandardDialog>
      </Box>
  );
};

export default KeyTermsAndDefinitions;