import axios from 'axios';

import getAuthSession from './auth';
import User, { BaseUser, Company, InviteUser } from '../interfaces/users.interface';
import { UsersResponse } from '../app/store/usersSlice';
import { updateCompanyBoolean } from './company';
import { sendAdmitEmployeeNotice, sendRejectEmployeeNotice } from './notifications';

export const USER_TYPES = {
  TPN_ADMIN: 'tpn_admin',
  VENDOR_ADMIN: 'vendor_admin',
  CONTENT_OWNER: 'content_owner',
  ASSESSOR: 'assessor',
};

export async function getUsersForDataGrid() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/users-data-grid/?limit=99999`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as User[];
}

export default async function createUser(user: BaseUser | InviteUser) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/auth/manage-users/`,
    { ...user },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as User;
}

export async function updateUser(user: BaseUser) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/users/${user.id}/`,
    { ...user },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as User;
}

export async function getUser(userId: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/users/${userId}/`,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as User;
}

export async function getUserFromAuthSession() {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/auth/cognito-token/`,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );
  return response.data as User;
}

export async function resetUser(user: User) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/auth/cognito/reset-user/${user.id}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function resendUserInvite(user: User) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/auth/cognito/resend-invite/${user.id}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function deleteUser(user: User) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/auth/manage-users/${user.id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export const getUsersByType = async (userType: string) => {
  const authSession = await getAuthSession();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/users/?type=${userType}&limit=99999`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    );
    return (await response.data) as UsersResponse;
  } catch (error: any) {
    return error;
  }
};

export const setPendingUser = async (user: number, company: number) => {
  const authSession = await getAuthSession();

  return Promise.all([
    axios.patch(
      `${process.env.REACT_APP_BASE_API}/users/${user}/`,
      { id: user, pendingCompanies: [company] },
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
    axios.post(
      `${process.env.REACT_APP_BASE_API}/companies/${company}/pending-employees/${user}/`,
      {},
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  ]).then(res => {
    return res;
  });
};

export const handlePendingUser = async (user: number, company: Company, action: 'accept' | 'reject') => {
  const employees = company.employees.map(employee => employee.id);
  if (action === 'accept') employees.push(user);

  const newCompany = await updateCompanyBoolean({ id: company.id, pendingEmployees: company.pendingEmployees.filter(pending => pending.id !== user).map(pending => pending.id), employees });

  if (!company?.id) return newCompany;

  if (action === 'accept') {
    sendAdmitEmployeeNotice(company.id, user).then(() => {});
  } else {
    sendRejectEmployeeNotice(company.id, user).then(() => {});
  }

  return newCompany;
};

export const getHelpURL = (userType?:string) => {
  let baseURL = 'https://tpnplus.atlassian.net/servicedesk/customer/portal/3';
  if (userType === USER_TYPES.ASSESSOR) return baseURL + '/group/11';
  if (userType === USER_TYPES.VENDOR_ADMIN) return baseURL + '/group/9';
  if (userType === USER_TYPES.CONTENT_OWNER) return baseURL + '/group/10';
  return baseURL;
};