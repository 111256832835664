import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Moment from 'moment/moment';

import { BillingFrequency, billingFrequencyOptions } from '../../interfaces/billing.interface';
import { RowActionsMenuProps } from '../../interfaces/datagrid.interface';
import getAllBillingRecords, { patchBillingRecord } from '../../services/billingRecords';
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Invoice,
  BillingRecord,
  BillingRecordPatchObj,
  InvoicePatchPostObj,
} from '../../interfaces/billing.interface';
import InvoiceForm from '../Billing/InvoiceForm';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import Button from '@mui/material/Button';
import { FieldValues, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { patchInvoice } from '../../services/invoices';
import { MoreVert } from '@mui/icons-material';
import { COMPANY_TYPES } from '../../services/vendor';

enum Status {
  active = 'Active',
  inactive = 'Inactive',
  hold = 'Hold',
}

enum BillingStatus {
  null = '',
  void = 'Void',
  already_invoiced = 'Already Invoiced',
  paid = 'Paid',  // Legacy value
  ready_for_invoice = 'Ready for Invoice',
  needs_validation = 'Needs Validation',
}

enum BillingRecordStatus {
  active = 'Active',
  inactive = 'Inactive',
  hold = 'Hold',
}

enum CompanyType {
  assessor = 'ASSESSOR',
  content_owner = 'CONT OWNER',
  vendor = 'SRV PROVIDER',
}

const BillingFrequencyCaptured: { [key in BillingFrequency]: string } = {
  [BillingFrequency.Quarterly]: 'Quarterly',
  [BillingFrequency.Annually]: 'Annually',
};

function getEnumValue(ENUM: any, value: string) {
  const enumValue = ENUM[value as keyof typeof ENUM];
  if (!enumValue) return '';
  return enumValue;
}

function getAddressId(billingRecord: BillingRecord): string {
  if (!billingRecord) return '';
  return 'BILLING';
}

function updateRowsState(currentBillingRecordRow: any, editedInvoice: any, rows: any, setRows: any, isCreating: boolean = false) {
  const invoiceIndex = currentBillingRecordRow.billingInvoices.findIndex((invoice: Invoice) => invoice.id === editedInvoice.id);
  const updatedInvoices = [...currentBillingRecordRow.billingInvoices];
  const currentRows = [...rows];
  const billingRecordIndex = currentRows.findIndex((billingRecord: BillingRecord) => billingRecord.id === currentBillingRecordRow.id);
  const isShownInvoice = currentBillingRecordRow.shownInvoice?.id === editedInvoice.id;
  const isMostRecentInvoice = currentBillingRecordRow.mostRecentInvoice?.id === editedInvoice.id;

  if (invoiceIndex > -1) {
    // Update the existing invoice
    updatedInvoices[invoiceIndex] = editedInvoice;
  } else {
    // Add the new invoice to the array
    updatedInvoices.push(editedInvoice);
  }

  if (isShownInvoice) currentBillingRecordRow.shownInvoice = editedInvoice;
  if (isMostRecentInvoice) currentBillingRecordRow.mostRecentInvoice = editedInvoice;

  currentBillingRecordRow = {
    ...currentBillingRecordRow,
    billingInvoices: updatedInvoices,
  };
  if (isCreating) {
    currentBillingRecordRow.mostRecentInvoice = editedInvoice;
  } else {
    currentBillingRecordRow.currentInvoice = editedInvoice;
  }

  if (billingRecordIndex > -1) currentRows[billingRecordIndex] = currentBillingRecordRow;
  setRows(currentRows);
  return currentBillingRecordRow;
}

const getInvoiceStatusFormField = (row: any) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId="invoiceStatus"
        id="invoice-status-select"
        label="Invoice Status"
        sx={{
          '& fieldset': { border: 'none' },
        }}
        onChange={(e) => {
          const status = e.target.value;
          if (row.shownInvoice) {
            const invoicePatchObject: InvoicePatchPostObj = {
              ...row.shownInvoice,
              sku: row.shownInvoice.sku.id,
              billingRecord: row.id,
              status: status,
            };
            patchInvoice(invoicePatchObject).then((updatedInvoice) => updateRowsState(row, updatedInvoice, row.rows, row.setRows));
          }
        }}
        value={row.shownInvoice?.status}
      >
        <MenuItem value='' selected={row.shownInvoice?.status === ''}></MenuItem>
        <MenuItem value='void' selected={row.shownInvoice?.status === 'void'}>Void</MenuItem>
        <MenuItem value='already_invoiced' selected={row.shownInvoice?.status === 'already_invoiced'}>Already Invoiced</MenuItem>
        <MenuItem value='ready_for_invoice' selected={row.shownInvoice?.status === 'ready_for_invoice'}>Ready For Invoice</MenuItem>
        <MenuItem value='needs_validation' selected={row.shownInvoice?.status === 'needs_validation'}>Needs Validation</MenuItem>
        <MenuItem value='paid' selected={row.shownInvoice?.status === 'paid'}>Paid</MenuItem>
      </Select>
    </FormControl>
  );
};

const getInvoiceIsPaidFormField = (row: any) => {
  return (
    <FormControl fullWidth>
      <Select
        key={row.shownInvoice?.isPaid ? 1 : 2} // Use key to remount the component when the value changes
        labelId="invoiceIsPaid"
        id="is-paid-select"
        label="Invoice Is Paid"
        sx={{
          '& fieldset': { border: 'none' },
        }}
        onChange={(e) => {
          const isPaid = e.target.value == 1;
          if (row.shownInvoice) {
            const invoicePatchObject: InvoicePatchPostObj = {
              ...row.shownInvoice,
              sku: row.shownInvoice.sku.id,
              billingRecord: row.id,
              isPaid: isPaid,
              paidDate: isPaid ? new Date() : null,
              status: isPaid ? 'paid' : row.shownInvoice.status != 'paid' ? row.shownInvoice.status : 'ready_for_invoice',
            };
            patchInvoice(invoicePatchObject).then((updatedInvoice) => updateRowsState(row, updatedInvoice, row.rows, row.setRows));
          }
        }}
        value={row.shownInvoice?.isPaid ? 1 : 2}
      >
        <MenuItem value={1}>Yes</MenuItem>
        <MenuItem value={2}>No</MenuItem>
      </Select>
    </FormControl>
  );
};

const columns: GridColDef[] = [
  { field: 'billingCustomerId', headerName: 'Customer ID', width: 200 },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 200,
    renderCell: params => (<span>{Moment(params.row.shownInvoice?.updatedAt).format('M/D/YYYY')}</span>),
    valueGetter: params => (Moment(params.row.shownInvoice?.updatedAt).format('M/D/YYYY')),
  },
  {
    field: 'invoiceStatus',
    headerName: 'Invoice Status',
    width: 225,
    renderCell: params => (getInvoiceStatusFormField(params.row)), valueGetter: params => getEnumValue(BillingStatus, params.row.shownInvoice?.status),
  },
  {
    field: 'invoiceSsPaid',
    headerName: 'Invoice Is Paid',
    width: 150,
    valueGetter: params => params.row.shownInvoice?.isPaid ? 'Yes' : 'No',
    renderCell: params => (getInvoiceIsPaidFormField(params.row)),
  },
  {
    field: 'paidDate',
    headerName: 'Paid Date',
    width: 200,
    valueGetter: params => params.row.shownInvoice?.paidDate ? Moment(params.row.shownInvoice?.paidDate).format('M/D/YYYY') : '',
  },
  { field: 'isInGracePeriod', headerName: 'Is In Grace Period', width: 200, valueGetter: params => params.row.company.isInGracePeriod ? 'Yes' : 'No' },
  { field: 'markedIsInGracePeriodAt', headerName: 'Marked Is In Grace Period At', width: 250, valueGetter: params => params.row.company?.markedIsInGracePeriodAt && Moment(params.row.company?.markedIsInGracePeriodAt).format('M/D/YYYY') },
  { field: 'billingRecordStatus', headerName: 'Billing Record Status', valueGetter: params => getEnumValue(BillingRecordStatus, params.row.status), width: 150 },
  { field: 'parentCompanyBillingCustomerId', headerName: 'Parent Customer ID', width: 200 },
  { field: 'companyName', headerName: 'Name', width: 300, valueGetter: params => params.row.company.name, renderCell: params => (<Link to={`/registry/${params.row.company.id}`}>{params.row.company.name}</Link>) },
  { field: 'registrationDate', headerName: 'Registration Date', width: 300, valueGetter: params => Moment(params.row.company?.createdAt).format('M/D/YYYY') },
  { field: 'statementName', headerName: 'Statement Name', width: 300, valueGetter: params => params.row.company.billingStatementName },
  { field: 'contactName', headerName: 'Contact Name', width: 300, valueGetter: params => params.row.billingContact?.name },
  { field: 'addressId', headerName: 'Address ID', width: 200, valueGetter: params => getAddressId(params.row) },
  { field: 'address1', headerName: 'Address 1', width: 200, valueGetter: params => params.row.billingContact?.address },
  { field: 'address2', headerName: 'Address 2', width: 200, valueGetter: params => params.row.billingContact?.address2 },
  { field: 'address3', headerName: 'Address 3', width: 200, valueGetter: params => params.row.billingContact?.address3 },
  { field: 'vatNumber', headerName: 'VAT #', width: 200, valueGetter: params => params.row.company?.vatNumber },
  { field: 'city', headerName: 'City', width: 200, valueGetter: params => params.row.billingContact?.city },
  { field: 'state', headerName: 'State', width: 200, valueGetter: params => params.row.billingContact?.state },
  { field: 'zipcode', headerName: 'Zipcode', width: 200, valueGetter: params => params.row.billingContact?.zipcode },
  { field: 'country', headerName: 'Country', width: 200, valueGetter: params => params.row.billingContact?.country },
  { field: 'billingFrequency', headerName: 'Billing Frequency', width: 200, valueGetter: params =>  billingFrequencyOptions.find((option) => option.value === params.row.company.billingFrequency)?.key },
  { field: 'billingEmailTo', headerName: 'Billing Email To', width: 200, valueGetter: params => params.row.company.billingEmailTo },
  { field: 'billingEmailCc', headerName: 'Billing Email CC', width: 200, valueGetter: params => params.row.company.billingEmailCc },
  { field: 'billingContactLastUpdatedAt', headerName: 'Billing Contact Last Updated At', width: 250, valueGetter: params => params.row.relatedHistoricalContext.companyBillingContact?.lastUpdated && Moment(params.row.relatedHistoricalContext.companyBillingContact?.lastUpdated).format('M/D/YYYY') },
  { field: 'billingContactLastChange', headerName: 'Billing Contact Last Change', width: 250, valueGetter: params => params.row.relatedHistoricalContext.companyBillingContact?.fieldChanges },
  {
    field: 'classId',
    headerName: 'Class ID',
    width: 200,
    valueGetter: params => {
      if (params.row.company.type == COMPANY_TYPES.CONTENT_OWNER && params.row.company.contentOwnerLevel == 2) return 'MPA MEMBER';
      return getEnumValue(CompanyType, params.row.company.type);
    },
    renderCell: params => {
      if (params.row.company.type == COMPANY_TYPES.CONTENT_OWNER && params.row.company.contentOwnerLevel == 2) return (<span>MPA MEMBER</span>);
      return (<span>{getEnumValue(CompanyType, params.row.company.type)}</span>);
    },
  },
  { field: 'item', headerName: 'Item', width: 200, valueGetter: params => params.row.shownInvoice?.sku?.item },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 200,
    valueGetter: params => params.row.shownInvoice?.startDate && Moment(params.row.shownInvoice?.startDate).format('M/D/YYYY'),
    renderCell: params => (<span>{params.row.shownInvoice?.startDate && Moment(params.row.shownInvoice?.startDate).format('M/D/YYYY')}</span>),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 200,
    valueGetter: params => params.row.shownInvoice?.endDate && Moment(params.row.shownInvoice?.endDate).format('M/D/YYYY'),
    renderCell: params => (<span>{params.row.shownInvoice?.endDate && Moment(params.row.shownInvoice?.endDate).format('M/D/YYYY')}</span>),
  },
  { field: 'description', headerName: 'Item Description', width: 200, valueGetter: params => params.row.shownInvoice?.sku?.description },
  { field: 'itemTotal', headerName: 'Item Total', width: 200, valueGetter: params => params.row.shownInvoice?.sku?.itemTotal },
  { field: 'discountAmount', headerName: 'Discount Amount', width: 200, valueGetter: params => params.row.shownInvoice?.discountAmount },
  { field: 'invoiceTotal', headerName: 'Invoice Total', width: 200, valueGetter: params => params.row.shownInvoice?.invoiceTotal },
  { field: 'poNumber', headerName: 'PO Number', width: 200, valueGetter: params => params.row.shownInvoice?.poNumber },
  { field: 'notes', headerName: 'Notes', width: 200, renderCell: params => (<span>{params.row.shownInvoice?.notes}</span>) },
  { field: 'overflow', headerName: '', width: 50, renderCell: params => (<MoreVert onClick={(e) => params.row.handleRowClick(params, e)} />), disableExport: true },
];

const InvoiceColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'item', headerName: 'Item', width: 150, valueGetter: params => params.row.sku.item },
  { field: 'startDate', headerName: 'Start Date', width: 200, renderCell: params => (<span>{Moment(params.row.startDate).format('M/D/YYYY')}</span>) },
  { field: 'endDate', headerName: 'End Date', width: 200, renderCell: params => (<span>{Moment(params.row.endDate).format('M/D/YYYY')}</span>) },
  { field: 'invoiceStatus', headerName: 'Invoice Status', width: 200, renderCell: params => (<span>{getEnumValue(BillingStatus, params.row.status)}</span>) },
  { field: 'description', headerName: 'Description', width: 200, valueGetter: params => params.row.sku.description },
  { field: 'itemTotal', headerName: 'Item Total', width: 200, valueGetter: params => params.row.sku.itemTotal },
  { field: 'discountAmount', headerName: 'Discount Amount', width: 200 },
  { field: 'discountPercent', headerName: 'Discount Percent', width: 200 },
  { field: 'invoiceTotal', headerName: 'Invoice Total', width: 200 },
  { field: 'poNumber', headerName: 'PO Number', width: 200 },
  { field: 'billingFrequency_captured', headerName: 'Billing Freq. Captured', width: 200, valueGetter: params => getEnumValue(BillingFrequencyCaptured, params.row.billingFrequencyCaptured) },
  { field: 'isPaid', headerName: 'Is Paid', width: 200, renderCell: params => (<span>{params.row.isPaid ? 'Yes' : 'No'}</span>) },
  { field: 'paidDate', headerName: 'Paid Date', width: 200, renderCell: params => (<span>{Moment(params.row.paidDate).format('M/D/YYYY')}</span>) },
  { field: 'notes', headerName: 'Notes', width: 200, renderCell: params => (<span>{params.row.notes}</span>) },
  { field: 'createdAt', headerName: 'Created At', width: 200, renderCell: params => (<span>{Moment(params.row.createdAt).format('M/D/YYYY')}</span>) },
];

function RowActionsMenu({ anchorEl, handleClose, currentBillingRecordRow, fetchAndSetRows, setRows, rows }: RowActionsMenuProps) {
  const open = Boolean(anchorEl);
  const [createInvoiceOpen, setCreateInvoiceOpen] = useState(false);
  const [editInvoiceOpen, setEditInvoiceOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState<Invoice | null>(null);
  const [showDataGrid, setShowDataGrid] = useState(true);

  const handleInvoiceSubmit = (editedInvoice: Invoice, isCreating: boolean = false) => {
    console.log('editedInvoice', editedInvoice);
    currentBillingRecordRow = updateRowsState(currentBillingRecordRow, editedInvoice, rows, setRows, isCreating);
    setCreateInvoiceOpen(false);
    setEditInvoiceOpen(false);
    handleClose();
  };

  const handleRowClick = (params: GridRowParams) => {
    setShowDataGrid(false);
    setCurrentInvoice(params.row);
    setEditInvoiceOpen(true);
  };

  const handleEditInvoiceClose = () => {
    setEditInvoiceOpen(false);
    setShowDataGrid(true);
  };

  const handleCreateInvoiceClose = () => {
    setCreateInvoiceOpen(false);
    setShowDataGrid(true);
  };

  const handleCreateInvoiceOpen = () => {
    setCreateInvoiceOpen(true);
    setShowDataGrid(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = useForm({ defaultValues: currentBillingRecordRow ? currentBillingRecordRow : undefined });

  const onSubmit = (data: FieldValues) => {
    if (!currentBillingRecordRow?.id) return;
    const billingRecordData: BillingRecordPatchObj = {
      id: currentBillingRecordRow.id,
      status: data.status,
      notes: data.notes,
      updatedAt: new Date(),
    };

    clearErrors();
    reset();

    patchBillingRecord(billingRecordData).then(() => {
      fetchAndSetRows();
      handleClose();
    });
  };

  useEffect(() => {
    setValue('status', currentBillingRecordRow?.status);
    setValue('notes', currentBillingRecordRow?.notes);
  }, [currentBillingRecordRow]);

  return (
    <div>
      {currentBillingRecordRow &&
          <StandardDialog title={`${currentBillingRecordRow.company.name} Invoices`} handleClose={() => {
            handleClose();
            setShowDataGrid(true);
            setEditInvoiceOpen(false);
            setCreateInvoiceOpen(false);
          }} isOpen={open}>
            {showDataGrid ?
              <div>
                <Box sx={{ width: '100%' }} component="form" method='patch'>
                  <Grid container spacing={2} alignItems="center" mb={2}>
                    <Grid item xs={12}>
                      <TextField
                        disabled
                        id='billingCustomerId'
                        label='Billing Customer ID'
                        sx={{ width: '100%' }}
                        defaultValue={currentBillingRecordRow.billingCustomerId}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="billingRecordStatus">Billing Record Status</InputLabel>
                        <Select
                          labelId="billingRecordStatus"
                          id="billing-record-status-select"
                          label="Billing Record Status"
                          onChange={(e) => setValue('status', e.target.value)}
                          defaultValue={currentBillingRecordRow.status}
                        >
                          {Object.keys(Status).map((key: string, idx: number) => <MenuItem key={idx} value={key} selected={currentBillingRecordRow?.status === key}>{getEnumValue(Status, key)}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!!errors.notes}
                        helperText={!!errors.notes && errors.notes.message}
                        id='notes'
                        label='Notes'
                        sx={{ width: '100%' }}
                        autoFocus
                        multiline
                        rows={4}
                        onFocus={() => clearErrors('notes')}
                        {...register('notes')}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {currentBillingRecordRow.billingInvoices.length > 0 && <>
                    <DataGrid
                        rows={currentBillingRecordRow.billingInvoices}
                        onRowClick={handleRowClick}
                        columns={InvoiceColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                    /></>}
              </div> : <div>
                {editInvoiceOpen && currentInvoice && <InvoiceForm callBack={handleInvoiceSubmit} billingRecord={currentBillingRecordRow} invoice={currentInvoice} handleClose={() => handleEditInvoiceClose()} company={currentBillingRecordRow.company} />}
                {createInvoiceOpen && <InvoiceForm callBack={handleInvoiceSubmit} billingRecord={currentBillingRecordRow} invoice={null} handleClose={() => handleCreateInvoiceClose()} company={currentBillingRecordRow.company} />}
              </div>
            }
            {!createInvoiceOpen && !editInvoiceOpen &&
                <StandardDialogActions>
                    <Button
                        color="primary"
                        type='submit'
                        onClick={handleSubmit(onSubmit)}
                    >
                        Update Billing Record
                    </Button>
                  <Button
                      onClick={() => handleCreateInvoiceOpen()}
                      color="primary">
                      Invoice {currentBillingRecordRow.company.name}
                  </Button>
                </StandardDialogActions>
            }
          </StandardDialog>
      }
    </div>
  );
}

function CustomToolbar(props: { showCurrentInvoices: boolean, toggleShowCurrentInvoices: any } ) {
  const { showCurrentInvoices, toggleShowCurrentInvoices } = props;
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        variant='text'
        size='small'
        startIcon={<ReceiptOutlinedIcon />}
        onClick={() => toggleShowCurrentInvoices(!showCurrentInvoices)}>
        Show {showCurrentInvoices ? 'Most Recently Created Invoice' : 'Current Billing Period Invoice'}
      </Button>
    </GridToolbarContainer>
  );
}

export default function BillingRecordsAdminPanel() {
  const [rows, setRows] = useState<BillingRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentBillingRecordRow, setCurrentBillingRecordRow] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showCurrentPeriodInvoices, setShowCurrentPeriodInvoices] = useState(true);

  const handleMenuClose = (event: any) => {
    if (event) event.preventDefault();
    setMenuAnchorEl(null);
    setCurrentBillingRecordRow(null);
  };

  function handleRowClick(params: GridRowParams, event: any) {
    event.preventDefault();
    setCurrentBillingRecordRow(params.row);
    setMenuAnchorEl(event.currentTarget);
  }

  function fetchAndSetRows() {
    getAllBillingRecords().then((billingRecords: BillingRecord[]) => {
      billingRecords.sort((a, b) => Number(a.id) - Number(b.id));
      billingRecords.map((billingRecord: any) => {
        billingRecord.fetchAndSetRows = fetchAndSetRows;
        billingRecord.rows = billingRecords;
        billingRecord.setRows = setRows;
        if (showCurrentPeriodInvoices) {
          billingRecord.shownInvoice = billingRecord.currentInvoice;
        } else {
          billingRecord.shownInvoice = billingRecord.mostRecentInvoice;
        }
      });
      setRows(billingRecords);
      setIsLoading(false);
    });
  }

  useEffect(() => fetchAndSetRows(), []);

  const toggleShowCurrentInvoices = (showCurrentInvoices: boolean) => {
    setShowCurrentPeriodInvoices(showCurrentInvoices);
    rows.forEach((billingRecord: any) => {
      billingRecord.handleRowClick = handleRowClick;
      if (showCurrentInvoices) {
        billingRecord.shownInvoice = billingRecord.currentInvoice;
      } else {
        billingRecord.shownInvoice = billingRecord.mostRecentInvoice;
      }
    });
    setRows(rows);
  };

  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        onRowClick={handleRowClick}
        loading={isLoading}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
        componentsProps={{
          toolbar: { showCurrentInvoices: showCurrentPeriodInvoices, toggleShowCurrentInvoices: toggleShowCurrentInvoices },
        }}
      />
      <RowActionsMenu
        anchorEl={menuAnchorEl}
        handleClose={(e: any) => handleMenuClose(e)}
        currentBillingRecordRow={currentBillingRecordRow}
        fetchAndSetRows={fetchAndSetRows}
        setRows={setRows}
        rows={rows}
      />
    </Card>
  );
}
