import React, { useState } from 'react';

import { AccountSettings } from '@aws-amplify/ui-react';
import { Button, Grid, Alert, Typography, Paper, Container } from '@mui/material';
import Layout from '../Layout/Layout';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { updateUser } from '../../app/store/userSlice';
import User from '../../interfaces/users.interface';
import UserForm from './UserForm';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import { Link } from 'react-router-dom';
import { USER_TYPES } from '../../services/user';

export default function UserProfile() {
  const [userUpdatedOpen, setUserUpdatedOpen] = useState(false);
  const handleUserUpdatedOpen = () => setUserUpdatedOpen(true);
  const handleUserUpdatedClose = () => setUserUpdatedOpen(false);
  const {
    user,
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const updateStateUser = (responseUser: User) => {
    dispatch(updateUser(responseUser));
    handleUserUpdatedOpen();
  };
  const [showPasswordResetSuccessAlert, setShowPasswordResetSuccessAlert] = useState(false);

  return (
    <Layout pageTitle='Profile'>
      <Container><Paper sx={{ padding: '1em' }}>
        <UserForm successCallback={updateStateUser} userType={user?.type ? user.type : ''} user={user}/>
        <Typography variant='h5' mt={1} mb={1}>Change Password</Typography>
        {showPasswordResetSuccessAlert && <Alert severity="success">Successfully changed password!<Button size='small' onClick={() => setShowPasswordResetSuccessAlert(false)}>X</Button></Alert>}
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">
          <Grid item xs={6}>
            <AccountSettings.ChangePassword onSuccess={() => setShowPasswordResetSuccessAlert(true)} />
          </Grid>
        </Grid>
      </Paper></Container>
      <StandardDialog title="User Updated" handleClose={handleUserUpdatedClose} isOpen={userUpdatedOpen}>
        <Typography>Your user information has been updated.</Typography>
        <StandardDialogActions>
          <Button onClick={() => handleUserUpdatedClose()}>Close</Button>
          <Link to={user?.type === USER_TYPES.TPN_ADMIN ? '/registry' : '/profile'}><Button variant='contained'>{user?.type === USER_TYPES.TPN_ADMIN ? 'Registry' : 'Company Profile'}</Button></Link>
        </StandardDialogActions>
      </StandardDialog>
    </Layout>
  );
}