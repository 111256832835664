import React, { useEffect, useState, ChangeEvent } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Slide, Alert, Snackbar, Button, Card, CircularProgress, Select, Stack, FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { Questionnaire, RemediationValidationRow } from '../../interfaces/questionnaire.interface';
import { fetchBestPractices } from '../../services/questionnaires';
import { getRemediationValidationAdminData } from '../../services/assessments';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAll } from '../../app/store/questionnaireSlice';
import { fetchBPVerificationList } from '../../app/store/assessmentSlice';
import { BPVerification } from '../../interfaces/assessment.interface';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Moment from 'moment/moment';
import Done from '@mui/icons-material/Done';
import { addBPVerification, deleteBPVerification } from '../../services/assessments';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import debounce from 'lodash/debounce';
import getAuthSession from '../../services/auth';
import axios from 'axios';
import BestPracticeVerificationDialog from './BestPracticeVerificationDialog';

// Function to return colors based on status
const getStatusColor = (status: string) => {
  switch (status) {
    case 'reviewed':
      return 'rgba(144, 238, 144, 0.6)';
    case 'rejected':
      return 'rgba(255, 0, 0, 0.4)';
    case 'not_started':
      return 'rgba(211, 211, 211, 0.3)';
    default:
      return 'rgba(173, 216, 230, 0.6)';
  }
};

function CustomToolbar({ openFilterModal }: { openFilterModal: () => void }) {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <Button
        variant='text'
        size='small'
        startIcon={<PlaylistAddCheckIcon />}
        onClick={openFilterModal}>
        Validation Best Practices
      </Button>
    </GridToolbarContainer>
  );
}

const handleActionClick = (rowData: any) => {
  const url = `/assessment/${rowData.annotatedAssessmentId}/questionnaire/${rowData.annotatedBpSurveyId}`;
  window.open(url, '_blank');
};

export default function RemediationVerificationAdminPanel() {
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<RemediationValidationRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingBP, setLoadingBP] = useState(false);
  const [questionnaireId, setQuestionnaireId] = useState('');
  const questionnaires = useAppSelector(state => state.questionnaires.results);
  const [questionniare, setQuestionnaire] = useState<Questionnaire | null>(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const { bpVerificationList } = useAppSelector((state) => state.assessments);
  const [searchTerm, setSearchTerm] = useState('');
  const [JWToken, setJWToken] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [controlList, setControlList] = useState<BPVerification[]>([]);
  const [bestPracticeList, setBestPracticeList] = useState<BestPractice[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<BestPractice | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  // Column definitions
  const columns: GridColDef[] = [
    { field: 'questionnaireTitle', headerName: 'Questionnaire', width: 200 },
    { field: 'companyName', headerName: 'Company', width: 200 },
    { field: 'siteOrAppName', headerName: 'Site/Application', width: 200 },
    {
      field: 'needsList',
      headerName: 'Question Code(s)',
      width: 450,
      filterable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', margin: '8px 0' }}>
          {Array.isArray(params.value) && params.value.map((item: { code: string, status: string }, idx: number) => (
            <span
              key={idx}
              style={{
                padding: '5px 10px',
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 500,
                backgroundColor: getStatusColor(item.status),
                borderRadius: '4px',
                color: '#000',
                display: 'inline-block',
              }}
            >
              {item.code}
            </span>
          ))}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleActionClick(params.row)}
        >
          Validate Status
        </Button>
      ),
    },
    { field: 'assessmentDate', headerName: 'Assessment Date', width: 150, valueGetter: (params) => Moment(params.row.assessmentDate).format('MM/DD/YYYY') },
    { field: 'annotatedUpdatedAt', headerName: 'Last Updated', width: 150, valueGetter: (params) => Moment(params.row.annotatedUpdatedAt).format('MM/DD/YYYY') },
  ];

  const handleFetchVerificationData = React.useCallback(async () => {
    setLoading(true);
    const data = await getRemediationValidationAdminData(questionniare?.id) as RemediationValidationRow[];
    const formattedData: Record<string, RemediationValidationRow[]> = (data || []).reduce((acc, item) => {
      if (acc[item.annotatedAssessmentSurveyId]) {
        acc[item.annotatedAssessmentSurveyId].push(item);
      } else {
        acc[item.annotatedAssessmentSurveyId] = [item];
      }
      return acc;
    }, {} as Record<string, RemediationValidationRow[]>);
    const newRows = Object.keys(formattedData).map((key) => {
      const firstItem = formattedData[key][0];
      firstItem.needsList = formattedData[key].map((item: RemediationValidationRow) => ({
        code: item.questionShortCode,
        status: item.subRemediationVerificationStatus || item.assessorAnswerVerificationStatus,
      }));

      const {
        annotatedAssessmentSurveyId,
        questionnaireTitle,
        siteOrAppName,
        companyName,
        needsList,
        assessmentDate,
        annotatedUpdatedAt,
        annotatedAssessmentId,
        annotatedBpSurveyId,
        annotatedVendorStatus,
        assessorAnswerVerificationDate,
        assessorAnswerVerificationStatus,
        questionShortCode,
        remediationAnswerId,
        subRemediationDate,
        subRemediationPlan,
        subRemediationVerificationDate,
        subRemediationVerificationStatus,
      } = firstItem;

      return {
        id: annotatedAssessmentSurveyId || `${questionShortCode}-${Math.random()}`,
        siteOrAppName: siteOrAppName || 'Unknown',
        questionnaireTitle,
        companyName,
        annotatedAssessmentSurveyId,
        needsList: needsList || [],
        assessmentDate: assessmentDate ? new Date(assessmentDate).toLocaleDateString('en-US') : '',
        annotatedUpdatedAt: annotatedUpdatedAt ? new Date(annotatedUpdatedAt).toLocaleString('en-US') : '',
        annotatedAssessmentId,
        annotatedBpSurveyId,
        annotatedVendorStatus,
        assessorAnswerVerificationDate,
        assessorAnswerVerificationStatus,
        questionShortCode,
        remediationAnswerId,
        subRemediationDate,
        subRemediationPlan,
        subRemediationVerificationDate,
        subRemediationVerificationStatus,
      };
    });
    setRows(newRows);
    setLoading(false);
  }, [questionniare]);

  const handleFetchBestPracticeQuestions = React.useCallback(async (questionnaire_id: string) => {
    setQuestionnaireId(questionnaire_id);
    if (!bpVerificationList) return;
    setLoadingBP(true);
    const { results } = await fetchBestPractices(undefined, { questionnaire_id });
    const filteredList = bpVerificationList
      ? bpVerificationList
        .filter(({ bestpracticeId }) => results.find((q: BestPractice) => q.id == bestpracticeId))
      : [];
    setControlList(filteredList);
    setLoadingBP(false);
  }, [bpVerificationList]);

  const handleQuestionnaireChange = React.useCallback((e: SelectChangeEvent<string | number>) => {
    setQuestionnaire(questionnaires.find((q) => q.id === e.target.value) || null);
    setQuestionnaireId(e.target.value.toString());
    handleFetchBestPracticeQuestions(e.target.value.toString());
  }, [questionnaires]);

  const handleOpenFilterModal = () => {
    setSearchTerm('');
    setOpenFilterModal(true);
  };

  const handleBestPracticeChange = async (value: BestPractice | null) => {
    if (!value || controlList.find((control) => control.bestpracticeId === value.id)) {
      setAutocompleteValue(null);
      return;
    }

    try {
      const data = await addBPVerification(value.id);
      const newBPVerification = {
        id: data.id,
        bestpracticeId: value.id,
        title: value.title,
      };
      setControlList([...controlList, newBPVerification]);
      setShowSnackBar(true);
      setAutocompleteValue(null);
    } catch (error) {
      alert('Error adding Best Practice Validation');
      console.error('Error adding Best Practice Validation:', error);
    }
  };

  const fetchItems = async (value: string) => {
    if (isFetching) return;

    setIsFetching(true);

    if (!questionnaireId || !JWToken) {
      setIsFetching(false);
      return;
    }

    if (!value.trim()) {
      setBestPracticeList([]);
      setIsFetching(false);
      return;
    }

    try {
      const url = `${process.env.REACT_APP_BASE_API}/best-practices/?search=${value}&questionnaire_id=${questionnaireId}&limit=9999`;
      const { data } = await axios.get(url, {
        headers: { Authorization: `Bearer ${JWToken}` },
      });
      setBestPracticeList(data.results);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleInputChange = debounce(async (event: ChangeEvent<{ value: unknown }>) => {
    const inputValue = event.target.value as string;
    if (inputValue.trim().length >= 1) {
      await fetchItems(inputValue);
    } else {
      setBestPracticeList([]);
    }
  }, 500);

  const handleDeleteBPVerification = async (id: string) => {
    try {
      await deleteBPVerification(parseInt(id));
      const updatedList = controlList.filter((control) => control.id !== id);
      setControlList(updatedList);
      setShowSnackBar(true);
    } catch (error) {
      alert('Error deleting Best Practice Validation');
      console.error('Error deleting Best Practice Validation:', error);
    }
  };

  useEffect(() => {
    getAuthSession().then((session) => {
      setJWToken(session.getIdToken().getJwtToken());
    });
  });

  useEffect(() => {
    if (!searchTerm.trim()) {
      setBestPracticeList([]);
      return;
    }
  }, [searchTerm]);

  // Run fetch on mount
  useEffect(() => {
    dispatch(fetchAll({ type: 'mpa_best_practice' }));
    dispatch(fetchBPVerificationList());
    handleFetchVerificationData();
  }, []);

  useEffect(() => {
    handleFetchVerificationData();
  }, [questionniare]);

  return (
    <>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={5000}
        TransitionComponent={Slide}
        onClose={() => setShowSnackBar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setShowSnackBar(false)} icon={<Done fontSize="inherit" />} severity="success" sx={{ width: '100%' }}>
          Best Practice Validation Updated
        </Alert>
      </Snackbar>
      <Card sx={{ padding: '2em' }}>
        <Stack direction="row-reverse" spacing={1} mb={2}>
          <FormControl size="small" sx={{ width: 305 }}>
            <InputLabel id="questionnaire-select-label">Questionnaire</InputLabel>
            <Select
              labelId="questionnaire-select-label"
              id="questionnaire-select"
              value={questionniare ? questionniare.id : ''}
              onChange={handleQuestionnaireChange}
              label="Questionnaire"
              size="small"
              displayEmpty
            >
            { questionnaires.map((q) => (
              <MenuItem key={q.id} value={q.id}>
                {q.title} {q.version}
              </MenuItem>
            ))}
            </Select>
          </FormControl>
        </Stack>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <DataGrid
            style={{ height: 400, width: '100%' }}
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            getRowId={(row) => row.id || `${row.questionShortCode}-${Math.random()}`}
            components={{
              Toolbar: () => <CustomToolbar openFilterModal={handleOpenFilterModal} />,
            }}
            getRowHeight={() => 'auto'}
          />
        )}
      </Card>
      <BestPracticeVerificationDialog
        isOpen={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        questionnaires={questionnaires}
        questionnaireId={questionnaireId}
        setQuestionnaireId={setQuestionnaireId}
        controlList={controlList}
        handleFetchBestPracticeQuestions={handleFetchBestPracticeQuestions}
        handleBestPracticeChange={handleBestPracticeChange}
        handleDeleteBPVerification={handleDeleteBPVerification}
        bestPracticeList={bestPracticeList}
        setBestPracticeList={setBestPracticeList}
        handleInputChange={handleInputChange}
        isFetching={isFetching}
        autocompleteValue={autocompleteValue}
        setAutocompleteValue={setAutocompleteValue}
        loadingBP={loadingBP}
      />
    </>
  );
}
