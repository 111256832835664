import React, { useCallback, useState, useEffect } from 'react';
import Layout from '../Layout/Layout';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PartnerResourcesModal from './PartnerResourcesModal';
import fetchParterResources from '../../services/partnerResources';
import { PartnerResource } from '../../interfaces/partnerResources.interface';
import { useNavigate } from 'react-router-dom';
import { RocketLaunch } from '@mui/icons-material';
import { WorkflowLabels } from '../../services/workflow';

const PartnerResources = () => {
  const navigate = useNavigate();
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [partnerResources, setPartnerResources] = useState<PartnerResource[]>([]);
  const [loadingResources, setLoadingResources] = useState(true);

  const handleCloseResourceModal = useCallback(() => {
    setShowResourceModal(false);
  }, []);

  const fetchResources = useCallback(async () => {
    try {
      const { results } = await fetchParterResources();
      setLoadingResources(false);
      if (!results) return;
      setPartnerResources(results.sort((a: PartnerResource, b: PartnerResource) => a.title.localeCompare(b.title)));
    } catch (error) {
      console.error(error);
    }
  }, [ setPartnerResources ]);

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <Layout pageTitle="Partner Resources">
      <Box component="section" sx={{ px: 4, py: 2 }}>
        <Typography
          variant="h5"
          sx={{
            my: 2,
            fontWeight: 500,
            color: '#2F2D4F',
            letterSpacing: '0.5px',
          }}
        >
          Welcome to the TPN Partner Resource Center
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 7 }}>
        {/* Left Section with Explore and Accordion */}
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: 7 }}>
          <Box
            component="section"
            sx={{
              minWidth: '1000px',
              maxWidth: '1000px',
              padding: 0,
              display: 'flex',
              flexWrap: 'wrap',
              borderRadius: '4px',
              alignItems: 'stretch',
              position: 'relative',
              overflow: 'hidden',
              border: '1px solid #d9dde5',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              '&:before': {
                display: 'none',
              },
              background: 'linear-gradient(135deg, #e5e9f3, #f4f6fc)',
            }}
          >
            <Box
              sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                width: '500px',
                justifyContent: 'center',
                borderRadius: '4px',
              }}
            >
             <Typography
                variant="body1"
                sx={{
                  my: 1,
                  color: '#333333',
                  lineHeight: 1.6,
                  letterSpacing: '0.3px',
                  fontWeight: 400,
                }}
              >
               At TPN, we are committed to helping you enhance security preparedness through this <strong style={{ textDecoration: 'underline' }}>no-cost</strong> <strong>Partner Resource Center</strong>, offering tools to confidently navigate the <strong>TPN Gold Shield</strong> assessment and secure your content.
              </Typography>
              <Button
                variant="contained"
                size="large"
                sx={{
                  my: 2,
                  mr: 2,
                  width: '100%',
                  maxWidth: '330px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  paddingX: 2,
                  paddingY: 1.5,
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    backgroundColor: '#005bb5',
                  },
                }}
                onClick={() => setShowResourceModal(true)}
                startIcon={<RocketLaunch fontSize="medium" />}
              >
                Explore All Partner Resources
              </Button>
            </Box>
            <Box
              sx={{
                flex: 1,
                ml: 1,
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/tpn_security_lock_rounded.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                borderRadius: '4px',
                right: 0,
                bottom: 0,
                left: '50%',
                width: '50%',
                border: 'none',
              }}
            />
          </Box>
          <Accordion
            defaultExpanded
            sx={{
              minWidth: '1000px',
              maxWidth: '1000px',
              padding: 2,
              border: '1px solid #d9dde5',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              '&:before': {
                display: 'none',
              },
              background: 'linear-gradient(135deg, #e2e6f3, #f6f8fc)',
              borderRadius: '8px', 
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: 'inherit' }}
            >
              <Typography variant="h5"
              sx={{
                color: '#333333',
                lineHeight: 1.6,
                letterSpacing: '0.3px',
                fontWeight: 500,
              }}
              >Learn More About Partner Resources</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{ mb: 2, 
                color: '#333333',
                lineHeight: 1.6,
                letterSpacing: '0.3px',
                fontWeight: 400 }}>
                Watch the video below to understand how to navigate and make the most of the Partner Resources Center
              </Typography>
              <Box
                component="video"
                controls
                title="Partner Resource Center Explainer Video"
                sx={{
                  width: '100%',
                  height: '500px',
                  border: 'none',
                }}
                controlsList="nodownload"
                onContextMenu={(e: any) => e.preventDefault()}
              >
                <source
                  src="https://tpn-public-documents.s3.us-west-1.amazonaws.com/Melody+Partner+Resources_no_bg+(1).mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
       {/* Right Section - Recommended Resources */}
        <Box
          sx={{
            maxWidth: '280px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            alignSelf: 'flex-start',
            borderColor: '#d9dde5',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
              width: '100%',
              fontWeight: 500,
              padding: '8px 16px',
              border: '1px solid #d9dde5',
              background: 'linear-gradient(135deg, #E9ECF4 0%, #EEF1F7 100%)',
              color: '#2F2D4F',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
            }}
          >
          Recently Added
          </Typography>
          <Box
            sx={{
              px: 2,
              pb: 2,
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
              border: '1px solid #e1e3e5',
              borderTop: 'none',
            }}
          >
            {loadingResources ? (
              <>
                <Skeleton variant="text" animation="pulse" />
                <Skeleton variant="text" animation="pulse" />
                <Skeleton variant="text" animation="pulse" />
              </>
            ) : (
              <>
                {/* Include TPN Best Practices in the sorted list */}
                {[
                  { title: 'MPA Content Security Best Practices', id: 'MPA CONTENT SECURITY BEST PRACTICES', isBestPractice: true, isKeyTerms: false },
                  { title: 'Key Terms & Definitions', id: 'KEY TERMS & DEFINITIONS', isBestPractice: false, isKeyTerms: true },
                  ...partnerResources
                    .filter((resource) =>
                      !WorkflowLabels.includes(resource.description) &&
                        resource.category.title !== 'KEY TERMS & DEFINITIONS',
                    )
                    .map((resource) => ({
                      ...resource,
                      isBestPractice: false,
                      isKeyTerms: false,
                    }))
                    .slice(0, 6), // Ensures the total array length doesn't exceed 7
                ]
                  // Sort resources alphabetically by title
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((resource) => (
                    <Typography
                      key={resource.id}
                      variant="body1"
                      onClick={() => {
                        setShowResourceModal(true);
                        if (resource.isBestPractice) {
                          navigate('/partner-resources/MPA%20CONTENT%20SECURITY%20BEST%20PRACTICES');
                        } else if (resource.isKeyTerms) {
                          navigate('/partner-resources/KEY%20TERMS%20&%20DEFINITIONS');
                        } else {
                          navigate(`/partner-resources/${(resource as PartnerResource).category.title}/${resource.id}`);
                        }
                      }}
                      sx={{
                        cursor: 'pointer',
                        color: '#0073e6',
                        textDecoration: 'none',
                        '&:hover': {
                          color: '#005bb5',
                          textDecoration: 'underline',
                        },
                        lineHeight: 1.2,
                        letterSpacing: '0.3px',
                        fontWeight: 500,
                      }}
                    >
                      {resource.title}
                    </Typography>
                  ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <PartnerResourcesModal
        fetchResources={fetchResources}
        partnerResources={partnerResources}
        showResourceModal={showResourceModal}
        handleCloseResourceModal={handleCloseResourceModal}
      />
    </Layout>

  );
};

export default PartnerResources;
