import React from 'react';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { CalendarMonth } from '@mui/icons-material';
import moment from 'moment';

const EditAccessEndDateModal = ({
  isOpen,
  setIsOpen,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isIndefinite,
  setIsIndefinite,
  handleSave,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  isIndefinite: boolean;
  setIsIndefinite: (value: boolean) => void;
  handleSave: (updatedEndDate?: string) => void;
}) => {
  return (
    <StandardDialog
      title={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CalendarMonth  fontSize="medium" />
          Edit Access End Date
      </Box>
    }
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
      maxWidth="sm"
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 4 }}>
          {/* Start Date Picker */}
          <DesktopDatePicker
            label="Start Date"
            inputFormat="MM/DD/yyyy"
            value={startDate ? moment(startDate) : null}
            disabled
            onChange={(newValue) => setStartDate(newValue ? newValue.toDate() : null)}
            renderInput={(params) => <TextField {...params} />}
          />

          <DesktopDatePicker
            label="Expiration Date"
            inputFormat="MM/DD/yyyy"
            value={endDate ? moment(endDate) : null}
            onChange={(newValue) => setEndDate(newValue ? moment(newValue).endOf('day').toDate() : null)}
            minDate={startDate ? moment(startDate) : null}
            disabled={isIndefinite}
            renderInput={(params) => <TextField {...params} />}
          />

          {/* Indefinite Checkbox */}
          <FormControlLabel
            control={
                <Checkbox 
                checked={isIndefinite} 
                onChange={(e) => {
                  setIsIndefinite(e.target.checked);
                  if (e.target.checked) setEndDate(null);
                }} 
                />
            }
            label={
                <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  Indefinite (No End Date)
                  <Tooltip title="You can return later to update or revoke this setting." arrow placement="top">
                    <HelpOutlineIcon fontSize="small" sx={{ color: '#555' }} />
                  </Tooltip>
                </span>
            }
            sx={{ ml: '5px' }}
            />
        </Box>
      </LocalizationProvider>
      <StandardDialogActions>
        <Button onClick={() => setIsOpen(false)} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={() => handleSave(endDate ? moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss') : undefined)} color="primary" variant="contained">
          Save Changes
        </Button>
      </StandardDialogActions>
    </StandardDialog>
  );
};

export default EditAccessEndDateModal;
