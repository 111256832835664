import React, { useMemo } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, Typography, Link, Box } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
import { Question } from '../../../interfaces/question.interface';
import User from '../../../interfaces/users.interface';
import { isWorkflowFiveThreeOrGreater } from '../../../services/questionnaires';
import { WorkflowTypeKey } from '../../../interfaces/workflow.interface';
import { USER_TYPES } from '../../../services/user';

type AssessorFindingsFormProps = {
  user: User | undefined;
  workflowType?: WorkflowTypeKey;
  handleSetShowPIDetails: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  question: Question;
  assessorAnswer: string;
  assessmentStatus: string | undefined;
  assessorFindings: string;
  onAssessorAnswerChange: (answer: string) => void;
  onAssessorFindingsChange: (findings: string) => void;
};

const AssessorFindingsForm = (props: AssessorFindingsFormProps) => {
  const {
    user,
    question,
    assessorAnswer,
    assessmentStatus,
    assessorFindings,
    workflowType,
    handleSetShowPIDetails,
    onAssessorAnswerChange,
    onAssessorFindingsChange,
  } = props;

  const handleAssessorAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAssessorAnswerChange(e.target.value);
  };

  const handleAssessorFindingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAssessorFindingsChange(e.target.value);
  };

  const isFiveThreeOrGreater = useMemo(() => workflowType ? isWorkflowFiveThreeOrGreater(workflowType) : false, [workflowType]);
  return (
    <>
      <RadioGroup
        aria-labelledby={question.title}
        name='assessor-answer'
        value={assessorAnswer}
        onChange={handleAssessorAnswerChange}
      >
        <FormControlLabel
          disabled={
            user &&
            user.type === USER_TYPES.ASSESSOR &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Fully Implemented'
          control={<Radio />}
          label={
            <Typography
              sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Fully Implemented' ? 'bold' : 'inherit' }}
            >
              Fully Implemented
            </Typography>
          }
        />
        <FormControlLabel
          disabled={
            user &&
            user.type === USER_TYPES.ASSESSOR &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Partially Implemented'
          control={<Radio />}
          label={
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: 1,
                fontWeight: assessorAnswer && assessorAnswer === 'Partially Implemented' ? 'bold' : 'inherit',
              }}
            >
              Partially Implemented{' '}
              {(assessorAnswer === 'Partially Implemented' && isFiveThreeOrGreater) && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleSetShowPIDetails}
                    sx={{
                      textDecoration: 'none',
                      padding: '6px 8px',
                      borderRadius: '9px',
                      color: '#006bd6',
                      '&:hover': {
                        backgroundColor: '#f0f9ff',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px', fontWeight: 400 }}>
                      <SourceIcon sx={{ mr: 0.2, fontSize: 18 }} />Details
                    </Box>
                  </Link>
                </Box>
              )}
            </Typography>
          }
        />
        <FormControlLabel
          disabled={
            user &&
            user.type === USER_TYPES.ASSESSOR &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Not Implemented'
          control={<Radio />}
          label={
            <Typography
              sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Not Implemented' ? 'bold' : 'inherit' }}
            >
              Not Implemented
            </Typography>
          }
        />
        <FormControlLabel
          disabled={
            user &&
            user.type === USER_TYPES.ASSESSOR &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true
          }
          value='Not Applicable'
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: assessorAnswer && assessorAnswer === 'Not Applicable' ? 'bold' : 'inherit' }}>
              Not Applicable
            </Typography>
          }
        />
      </RadioGroup>
      <Typography variant='caption'>Finding is required</Typography>
      <TextField
        id={`${question.id}-assessor-comment`}
        multiline
        rows={10}
        label={'Assessor Finding'}
        fullWidth
        onChange={handleAssessorFindingsChange}
        value={assessorFindings}
        required
        sx={{ mt: 2 }}
        inputProps={{
          readOnly:
            user &&
            user.type === USER_TYPES.ASSESSOR &&
            assessmentStatus &&
            ['Assessing', 'Submitted'].indexOf(assessmentStatus) > -1
              ? false
              : true,
          sx: { overflowY: 'scroll' },
        }}
      />
    </>
  );
};

export default AssessorFindingsForm;
