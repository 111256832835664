import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import { Grid } from '@mui/material';
import AssessmentLevelWidget from './Widgets/AssessmentLevel';
import AssessmentStatusWidget from './Widgets/AssessmentStatus';
import { useAppSelector } from '../../app/hooks';
import SitesApplicationsOverviewWidget from './Widgets/SitesApplicationsOverview';
import AdminModules from './Widgets/AdminModules';
import { AssessmentSurveyMeta } from '../../interfaces/metaData.interface';
import { fetchSurveyMeta } from '../../services/surveys';
import DownloadReportHeader from '../DownloadReport/DownloadReportHeader';
import { USER_TYPES } from '../../services/user';

export default function Dashboard() {
  const { user } = useAppSelector(state => state.user);
  const [assessmentSurveyMeta, setAssessmentSurveyMeta] = useState<AssessmentSurveyMeta | undefined>(undefined);

  useEffect(() => {
    if (user?.type !== USER_TYPES.ASSESSOR) fetchSurveyMeta().then(asm => setAssessmentSurveyMeta(asm));
  }, []);
  
  return (
    <Layout pageTitle="Dashboard">
      {user?.type !== USER_TYPES.ASSESSOR && user?.type !== USER_TYPES.VENDOR_ADMIN && <>{user?.type === USER_TYPES.TPN_ADMIN && <AdminModules />}
      {user?.type === USER_TYPES.CONTENT_OWNER && <DownloadReportHeader />}
      <Grid container spacing={2} sx={{ width: '100%', mt: 3 }}>
        <Grid item xs={12} md={6} lg={4}>
          <AssessmentLevelWidget />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SitesApplicationsOverviewWidget assessmentSurveyMeta={assessmentSurveyMeta} type='site' />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <SitesApplicationsOverviewWidget assessmentSurveyMeta={assessmentSurveyMeta} type='application' />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ width: '100%', mt: 2 }}>
        <Grid item xs={0} lg={2}>
          <div />
        </Grid>
        <Grid item xs={12} lg={8}>
          <AssessmentStatusWidget />
        </Grid>
        <Grid item xs={0} lg={2}>
          <div />
        </Grid>
      </Grid></>}
    </Layout>
  );
}
