import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridOverlay, GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';
import Moment from 'moment/moment';

import { RequestToken } from '../../interfaces/requestToken.interface';
import getRequestTokens, {
  deleteRequestToken,
  sendReportToExternalUser,
} from '../../services/requestTokens';
import { Card, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EmailIcon from '@mui/icons-material/Email';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import RequestTokenForm from '../RequestTokens/RequestTokenForm';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import FileUploaderComp from '../Forms/FileUpload';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'recipientEmail', headerName: 'Recipient Email', flex: 2, valueGetter: params => params.row.context.recipientEmail },
  { field: 'reportName', headerName: 'Report Name', flex: 3, valueGetter: params => params.row.context.report?.reportName },
  { field: 'lastUsedAt', headerName: 'Last Used At', flex: 1, valueGetter: params => params.row.lastUsedAt ? Moment(params.row.lastUsedAt).format('MM/DD/YYYY') : 'Never', renderCell: params => params.row.lastUsedAt ? Moment(params.row.lastUsedAt).format('MM/DD/YYYY') : 'Never' },
  { field: 'expiresAt', headerName: 'Expires At', flex: 1, valueGetter: params => Moment(params.row.expiresAt).format('MM/DD/YYYY'), renderCell: params => Moment(params.row.expiresAt).format('MM/DD/YYYY') },
  { field: 'createdAt', headerName: 'Created At', flex: 1, valueGetter: params => Moment(params.row.createdAt).format('MM/DD/YYYY'), renderCell: params => Moment(params.row.createdAt).format('MM/DD/YYYY') },
  { field: 'updatedAt', headerName: 'Updated At', flex: 1, valueGetter: params => Moment(params.row.updatedAt).format('MM/DD/YYYY'), renderCell: params => Moment(params.row.updatedAt).format('MM/DD/YYYY') },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: () => (
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    ),
  },
];

interface RowActionsMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: any;
  currentRow: any;
  setRows: any;
  rows: any[];
}

function RowActionsMenu({ anchorEl, handleClose, currentRow, setRows, rows }: RowActionsMenuProps) {
  const [token, setRequestToken] = useState<RequestToken>();
  const open = Boolean(anchorEl);
  const [deleteRequestTokenOpen, setDeleteRequestTokenOpen] = useState(false);
  const [editRequestTokenOpen, setEditRequestTokenOpen] = useState(false);

  const handleEditRequestTokenSubmit = (editedRequestToken: RequestToken) => {
    setRows(rows.map((row) => row.id === editedRequestToken.id ? editedRequestToken : row));
    setEditRequestTokenOpen(false);
    handleClose();
  };

  const handleRequestTokenDeleteSubmit = () => {
    if (!token) return;
    deleteRequestToken(token).then(() => {
      setRows(rows.filter((row) => row.id !== token.id));
      setDeleteRequestTokenOpen(false);
      handleClose();
    });
  };

  const sendAssessmentReportEmail = () => {
    if (!token) return;
    sendReportToExternalUser(token).then(() => handleClose());
  };

  useEffect(() => {
    if (currentRow) setRequestToken(currentRow);
  }, [currentRow]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >{token &&
        <div>
            <StandardDialog title={`Delete token for ${token?.context?.recipientEmail}?`} handleClose={() => setDeleteRequestTokenOpen(false)} isOpen={deleteRequestTokenOpen}>
                <Typography>Are you sure you want delete {token?.context?.recipientEmail}&apos;s token?</Typography>
                <StandardDialogActions>
                    <Button variant="outlined" onClick={() => setDeleteRequestTokenOpen(false)}>Cancel</Button>
                    <Button variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => handleRequestTokenDeleteSubmit()}>Delete</Button>
                </StandardDialogActions>
            </StandardDialog>
            <StandardDialog title={`Edit ${token?.context?.recipientEmail}'s token?`} handleClose={() => setEditRequestTokenOpen(false)} isOpen={editRequestTokenOpen}>
                <Stack direction="row" spacing={2}>
                    <RequestTokenForm callBack={handleEditRequestTokenSubmit} token={token} handleClose={() => setEditRequestTokenOpen(false)} />
                </Stack>
            </StandardDialog>
        </div>}
      <MenuItem onClick={() => setEditRequestTokenOpen(true)}>
        <ListItemIcon><EditIcon /></ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setDeleteRequestTokenOpen(true)}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => sendAssessmentReportEmail()}>
        <ListItemIcon><EmailIcon /></ListItemIcon>
        <ListItemText>Share Report</ListItemText>
      </MenuItem>
    </Menu>
  );
}

function CustomToolbar(props: any) {
  const [openCreateRequestToken, setOpenCreateRequestToken] = useState(false);
  const [openBulkRequestTokenUpload, setOpenBulkRequestTokenUpload] = useState(false);
  const handCreateRequestTokenSuccess = (newRequestToken: RequestToken) => {
    props.onCreateSuccessHandler(newRequestToken);
    setOpenCreateRequestToken(false);
  };
  const bulkRequestTokenUploadHandler = (file: any) => {
    const actualFile = file && file[0] && file[0].file;
    if (!actualFile) return;
    // requestTokenBulkUpload(actualFile).then((requestTokens: RequestToken[]) => {
    //   props.setRows([...requestTokens, ...props.rows]);
    //   setOpenBulkRequestTokenUpload(false);
    // });
  };

  return (
    <GridToolbarContainer>
      <StandardDialog title='Share Report' handleClose={() => setOpenCreateRequestToken(false)} isOpen={openCreateRequestToken}>
        <RequestTokenForm token={null} callBack={handCreateRequestTokenSuccess} handleClose={() => setOpenCreateRequestToken(false)} />
      </StandardDialog>
      <StandardDialog title='Request Token Bulk Upload' handleClose={() => setOpenBulkRequestTokenUpload(false)} isOpen={openBulkRequestTokenUpload}>
        <Stack spacing={2}>
          <Grid item xs={12} mt={2} mb={2}>
            <FileUploaderComp callback={(file: File) => bulkRequestTokenUploadHandler(file)} multiple={false} hideFileList={true} />
          </Grid>
        </Stack>
      </StandardDialog>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        variant='text'
        size='small'
        startIcon={<AddOutlinedIcon />}
        onClick={() => setOpenCreateRequestToken(true)}>
        Share Report
      </Button>
      <Button
        variant='text'
        size='small'
        startIcon={<AddOutlinedIcon />}
        onClick={() => setOpenBulkRequestTokenUpload(true)}>
        Bulk Upload
      </Button>
      <GridToolbarExport csvOptions={{
        fileName: `tpn_shared_reports_${new Date().toLocaleDateString()}`,
      }}/>
    </GridToolbarContainer>
  );
}

export default function SharedReportsAdminPanel() {
  const [rows, setRows] = useState<RequestToken[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentRow, setCurrentRow] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    getRequestTokens().then((tokens: RequestToken[]) => {
      setRows(tokens);
      setIsLoading(false);
    });
  }, []);

  const addNewRequestToken = (newRequestToken: RequestToken) => {
    setRows([...rows, newRequestToken]);
  };

  const handleMenuClose = (event: any) => {
    if (event) event.preventDefault();
    console.log('handling menu close');
    setMenuAnchorEl(null);
    setCurrentRow(null);
  };

  function handleRowClick(params: GridRowParams, event: any) {
    setCurrentRow(params.row);
    setMenuAnchorEl(event.currentTarget);
  }

  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        onRowClick={handleRowClick}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
        componentsProps={{
          toolbar: {
            onCreateSuccessHandler: addNewRequestToken,
            rows: rows,
            setRows: setRows,
          },
        }}
      />
      <RowActionsMenu
        anchorEl={menuAnchorEl}
        handleClose={(e: any) => handleMenuClose(e)}
        currentRow={currentRow}
        setRows={setRows}
        rows={rows}
      />
    </Card>
  );
}
