// react and external-libs
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
// MUI
import { FormControlLabel, Switch, Box, AccordionDetails } from '@mui/material';
// redux
import { useAppSelector } from '../../../app/hooks';
// interface
import { Company } from '../../../interfaces/users.interface';
import { Workflow } from '../../../interfaces/workflow.interface';
// services
import { getCompany } from '../../../services/company';
// components
import AssessmentDataRow from './AssessmentDataRow';
import { DataCell, DataRow } from '../../Tables/DataList';
import AssessmentForm from '../../Assessments/NewAssessmentModal';
import { AccordionCollapse, AccordionCollapseSummary } from '../../Layout/Collapsible';
import { USER_TYPES } from '../../../services/user';
import { COMPANY_TYPES } from '../../../services/vendor';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    nonCompliant: true;
  }
}

type AssessmentsAccordionProps = { company: Company; userCanAdminCompany: boolean };

const AssessmentsAccordion = (props: AssessmentsAccordionProps) => {
  const { company, userCanAdminCompany } = props;
  // router and redux
  const { profileSection } = useParams();
  const { user } = useAppSelector((state) => state.user);
  const { workflows } = useAppSelector(state => state.workflow);
  // states
  const [expanded, setExpanded] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [filteredWorkflows, setFilteredWorkflows] = useState<Workflow[]>([]);
  // local variables
  const activeWorkflows = useMemo(() => workflows?.filter(cw => cw.isActive && cw.assessment), [workflows]);

  const listFilteredWorkflows = useMemo(
    () => {
      const singleAssessmentFlows = filteredWorkflows.filter((value, index, self) => {
        return self.findIndex(v => v.assessment === value.assessment) === index;
      });
      return showCompleted
        ? singleAssessmentFlows
        : singleAssessmentFlows.filter((wf) => {
          if (company.type === COMPANY_TYPES.ASSESSOR)
            return ['Remediation', 'Completed', 'Complete'].indexOf(wf.status) === -1;
          if (company.type === COMPANY_TYPES.VENDOR) return ['Completed', 'Complete'].indexOf(wf.status) === -1;
        });
    }, [showCompleted, filteredWorkflows],
  );

  // expand accordion if assessments url
  useEffect(() => {
    if (!profileSection) {
      setExpanded(false);
      return;
    }
    setExpanded(profileSection === 'assessments');
  }, [profileSection]);

  // filter assessments and get/set from/to localStorage
  useEffect(() => {
    if (!activeWorkflows) return;
    const nWorkflows = activeWorkflows.filter((wf) => {
      if (company.type === COMPANY_TYPES.ASSESSOR) {
        if (wf?.assignedAssessor === user?.id && wf?.status !== 'Rejected') return true;
        return wf?.assessor === company.id && wf?.status !== 'Rejected';
      }
      return true;
    });
    setFilteredWorkflows(nWorkflows ? nWorkflows : []);

    // let localLog: any = localStorage.getItem('assessmentAccessLog');
    // if (localLog) localLog = JSON.parse(localLog);

    // nAssessments.forEach((ass) => {
    //   (ass.surveys as AssessmentSurvey[]).forEach((asssur) => {
    //     if (localLog && localLog.find((ll: any) => ll.assessmentSurvey === asssur.id)) return;
    //     dispatch(
    //       setAssessmentLocalStorage({
    //         assessmentSurvey: asssur.id!,
    //         assessment: ass.id!,
    //         date: moment().subtract(10, 'years').format('YYYY-MM-DDTHH:mm:ssZZ'),
    //       }),
    //     );
    //   });
    // });
  }, [company, activeWorkflows, user?.id]);

  // set companies
  useEffect(() => {
    if (!filteredWorkflows || filteredWorkflows.length === 0 || company?.type !== COMPANY_TYPES.ASSESSOR) return;
    const companyIds = filteredWorkflows.map(wf => wf.serviceProvider);
    Promise.all([...new Set(companyIds)].map((id) => getCompany(id.toString()))).then((res) => setCompanies(res));
  }, [filteredWorkflows, company.type]);

  return (
    <AccordionCollapse
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionCollapseSummary
        name='assessments-header'
        title='Manage Assessments'
        count={listFilteredWorkflows.length}
        addItem={() => setAssessmentModalOpen(true)}
        addItemLabel='Assessment'
        addItemTest={user && user.type === USER_TYPES.VENDOR_ADMIN && userCanAdminCompany}
      />
      <AccordionDetails>
        <Box sx={{ position: 'absolute', left: '250px', top: '13px' }}>
          <FormControlLabel
            control={<Switch checked={showCompleted} onChange={(e) => setShowCompleted(e.target.checked)} />}
            label='Show Completed'
          />
        </Box>
        <DataRow header>
          <DataCell xs={4}>{company.type === COMPANY_TYPES.VENDOR ? 'Assessor' : 'Company'}</DataCell>
          <DataCell xs={3}>Questionnaire Count</DataCell>
          <DataCell xs={2}>Status</DataCell>
          <DataCell xs={2}>Action</DataCell>
          <DataCell xs={1}>&nbsp;</DataCell>
        </DataRow>
        {listFilteredWorkflows.map((wf: Workflow) => (
          <AssessmentDataRow
            key={wf.id}
            company={company}
            vendorCompany={
              company.type === COMPANY_TYPES.ASSESSOR && companies.length > 0
                ? companies.find((comp) => comp.id === wf.serviceProvider)
                : undefined
            }
            workflow={wf}
            userCanAdminCompany={userCanAdminCompany}
          />
        ))}
      </AccordionDetails>
      <AssessmentForm
        modalOpen={assessmentModalOpen}
        company={company}
        closeCallback={() => setAssessmentModalOpen(false)}
      />
    </AccordionCollapse>
  );
};

export default AssessmentsAccordion;
