import React, { useEffect } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { REMEDIATION_OPTIONS, REMEDIATION_VERIFICATION_STATUS } from '../../../services/surveyHelpers';
import FileUploaderComp, { FileUpload } from '../../Forms/FileUpload';
import User from '../../../interfaces/users.interface';
import { Question } from '../../../interfaces/question.interface';
import { SubRemediation, WillNotRemediateReasons } from '../../../interfaces/assessment.interface';
import RemediationValidation from './RemediationValidation';
import moment from 'moment';
import { useAppSelector } from '../../../app/hooks';
import WillNotRemediateReasonSelect from './WillNotReasonSelect';
import { USER_TYPES } from '../../../services/user';

type RemediationFormStepProps = {
  subRemErr: string;
  question: Question;
  errorMessage: string;
  fileList: FileUpload[];
  user: User | undefined;
  subRemediation: SubRemediation;
  origSubRemediation?: SubRemediation;
  onSubRemVFilesChange: (newSubRemVFiles: { [key: string]: FileUpload[] }) => void;
  handleSetSubRemediations: (subRemediation: SubRemediation) => void;
  patchRemediationValidationStatus: (status: string, isSubRemediation: boolean, comment: string) => void;
};

const RemediationFormStep = ({
  user,
  fileList,
  question,
  subRemErr,
  errorMessage,
  subRemediation,
  origSubRemediation,
  onSubRemVFilesChange,
  handleSetSubRemediations,
  patchRemediationValidationStatus,
}: RemediationFormStepProps) => {
  const { bpVerificationList } = useAppSelector((state) => state.assessments);
  const [remediationDateErr, setRemediationDateErr] = React.useState<string>('');
  useEffect(() => {
    if (subRemediation.remediationPlan === REMEDIATION_OPTIONS.remediated && subRemediation.remediationDate && moment(subRemediation.remediationDate).isAfter(moment(), 'day')) {
      setRemediationDateErr('Remediated status requires a current or past date');
    } else {
      setRemediationDateErr('');
    }
  }, [subRemediation.answer]);

  const handleVendorStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemediationDateErr('');
    if (e.target.value === REMEDIATION_OPTIONS.willNotRemediate) {
      handleSetSubRemediations({
        ...subRemediation,
        remediationPlan: e.target.value,
        remediationDate: subRemediation.remediationDate || new Date().toISOString(),
      });
    } else {
      handleSetSubRemediations({ ...subRemediation, remediationPlan: e.target.value });
    }
  };

  const handleRemediationDateChange = (date: string | null) => {
    if (subRemediation.remediationPlan === REMEDIATION_OPTIONS.remediated && date && moment(date).isAfter(moment(), 'day')) {
      setRemediationDateErr('Remediated status requires a current or past date');
    } else {
      setRemediationDateErr('');
    }
    handleSetSubRemediations({ ...subRemediation, remediationDate: date });
  };

  const handleWillNotRemediateReasonChange = (willNotRemediateReason : WillNotRemediateReasons) => {
    handleSetSubRemediations({ ...subRemediation, willNotRemediateReason });
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetSubRemediations({ ...subRemediation, remediationComment: e.target.value });
  };

  const handleFilesChange = (files: FileUpload[]) => {
    if (!files) return;
    onSubRemVFilesChange({ [subRemediation.answer as number]: files });
  };

  const verificationDisable = React.useMemo(() => (!!user && user.type !== USER_TYPES.VENDOR_ADMIN) || origSubRemediation?.verificationStatus === REMEDIATION_VERIFICATION_STATUS.reviewed, [user, origSubRemediation]);

  if (!subRemediation) return null;

  const needsVerification = React.useMemo(() => {
    const bpCpy = question.bestPractice;
    return typeof bpCpy === 'object' &&
           bpVerificationList?.some(bp => bp.bestpracticeId === bpCpy.id);
  }, [bpVerificationList, question.bestPractice]);

  useEffect(() => {
    const { remediationPlan, remediationDate } = subRemediation;

    if (
      remediationPlan === REMEDIATION_OPTIONS.remediated &&
      remediationDate &&
      moment().isBefore(moment(remediationDate), 'day')
    ) {
      handleRemediationDateChange(null);
    }
  }, [subRemediation.remediationPlan]);

  return (
    <>
      <RadioGroup
        aria-labelledby={question.title}
        name="assessor-answer"
        value={subRemediation.remediationPlan || ''}
        onChange={handleVendorStatusChange}
      >
        <FormControlLabel
          disabled={verificationDisable}
          value={REMEDIATION_OPTIONS.willNotRemediate}
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: subRemediation.remediationPlan === REMEDIATION_OPTIONS.willNotRemediate ? 'bold' : 'inherit' }}>
              Will not Remediate
            </Typography>
          }
        />
        <FormControlLabel
          disabled={verificationDisable}
          value={REMEDIATION_OPTIONS.willRemediateLater}
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: subRemediation.remediationPlan === REMEDIATION_OPTIONS.willRemediateLater ? 'bold' : 'inherit' }}>
              Will Remediate Later
            </Typography>
          }
        />
        <FormControlLabel
          disabled={verificationDisable}
          value={REMEDIATION_OPTIONS.remediated}
          control={<Radio />}
          label={
            <Typography sx={{ fontWeight: subRemediation.remediationPlan === REMEDIATION_OPTIONS.remediated ? 'bold' : 'inherit' }}>
              Remediated
            </Typography>
          }
        />
      </RadioGroup>
      { subRemediation.remediationPlan === REMEDIATION_OPTIONS.remediated && needsVerification && (
          <RemediationValidation
            isSubRemediation={true}
            origVerificationStatus={origSubRemediation?.verificationStatus}
            origRemediationStatus={origSubRemediation?.remediationPlan}
            origVerificationComment={origSubRemediation?.verificationComment}
            origVerificationDate={origSubRemediation?.verificationDate}
            patchRemediationValidationStatus={patchRemediationValidationStatus}
          />
      )}
      <Box sx={{ my: 2 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label="Remediation Date"
          inputFormat="MM/DD/yyyy"
          value={subRemediation.remediationDate}
          disabled={
            subRemediation.remediationPlan === REMEDIATION_OPTIONS.willNotRemediate ||
            (user && user.type !== USER_TYPES.VENDOR_ADMIN) ||
            !subRemediation.remediationPlan ||
            verificationDisable
          }
          shouldDisableDate={(date) => {
            // Disable future dates if plan is Remediated
            return subRemediation.remediationPlan === REMEDIATION_OPTIONS.remediated && new Date(date) > new Date();
          }}
          onChange={handleRemediationDateChange}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              helperText={remediationDateErr}
              error={Boolean(remediationDateErr)}
            />
          )}
        />
        </LocalizationProvider>
      </Box>
      {subRemediation.remediationPlan === REMEDIATION_OPTIONS.willNotRemediate && (
        <WillNotRemediateReasonSelect
          disabled={verificationDisable}
          value={subRemediation.willNotRemediateReason || ''}
          onChange={handleWillNotRemediateReasonChange}
        />
      )}
      <TextField
        id={`${question.id}-remediation-comment`}
        multiline
        rows={5}
        label="Additional Details"
        fullWidth
        required
        onChange={handleCommentChange}
        value={subRemediation.remediationComment}
        sx={{ my: 1 }}
        disabled={verificationDisable}
        inputProps={{
          sx: { overflowY: 'scroll' },
        }}
      />
      {(subRemediation.remediationPlan === REMEDIATION_OPTIONS.remediated || subRemediation.remediationPlan === REMEDIATION_OPTIONS.willNotRemediate) && (
        <>
          {question.attachmentFormLabel && (
            <Typography mb={1} mt={1} sx={{ whiteSpace: 'pre-wrap' }}>
              {question.attachmentFormLabel}
            </Typography>
          )}
          <Box mt={1}>
            <FileUploaderComp
              showCOPublicModal={true}
              boxHeight={100}
              fileList={fileList}
              notAssessorVisible={true}
              callback={handleFilesChange}
              enablePublic
              dontShowUpload={!user || user.type !== USER_TYPES.VENDOR_ADMIN || verificationDisable}
            />
          </Box>
        </>
      )}
      <Box sx={{ mt: 2, height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {(errorMessage.length > 0 || subRemErr.length > 0) && (
          <Typography color='error'>
            {errorMessage || subRemErr}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default RemediationFormStep;