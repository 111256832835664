import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, FormControlLabel, FormGroup, Radio, RadioGroup, Typography, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import { Controller } from 'react-hook-form';
import { Question } from '../../interfaces/question.interface';
import { PIDetails } from '../../interfaces/assessment.interface';
import CommentBuilder from '../Comments/CommentBuilder';
import PiDetailsControlComment from '../Comments/PiDetailsControlComment';
import RoundIconButton from './RoundIconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { Badge } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const defaultStatusMap: { [key: string]: string } = {
  'compensating_control': 'Compensating Control: ',
  'not_implemented': 'Not Implemented: ',
  'implemented': 'Implemented: ',
  'not_applicable': 'Not Applicable: ',
};

export default function AssessorPIDetails({
  assessorFinding,
  canEdit,
  control,
  question,
  selectedCtrlAns,
  show,
  setShow,
  setSelectedCtrlAns,
  updateAssessorFindings,
}: {
  assessorFinding?: string,
  canEdit?: boolean,
  control: any,
  question: Question,
  selectedCtrlAns: { [key: number]: PIDetails },
  show: boolean,
  setSelectedCtrlAns: (value: { [key: number]: PIDetails }) => void,
  setShow: (value: boolean) => void,
  updateAssessorFindings: (findings: string) => void,
}) {
  const [singleSelectVal, setSingleSelectVal] = useState<string | undefined>(undefined);
  const [singleSelectQId, setSingleSelectQId] = useState<number | null>(null);
  const [locSelectedCtrlAns, setLocSelectedCtrlAns] = useState<{ [key: number]: PIDetails }>(() => {
    return JSON.parse(JSON.stringify(selectedCtrlAns)); // Deep copy of selectedCtrlAns
  });
  const theme = useTheme();
  const [localRemarks, setLocalRemarks] = useState<string>('');
  const [showCommentBuilder, setShowCommentBuilder] = useState(false);
  const [showAddCommentMod, setShowAddCommentMod] = useState(false);
  const [commentControlId, setCommentControlId] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleHideCommentBuilder = useCallback(() => {
    setShowCommentBuilder(false);
  }, [setShowCommentBuilder]);

  useEffect(() => {
    if (!question) return;
    if (!selectedCtrlAns || Object.keys(selectedCtrlAns).length === 0) {
      setLocSelectedCtrlAns({});
      setSingleSelectQId(null);
      setSingleSelectVal(undefined);
    } else {
      setLocSelectedCtrlAns(prevState => ({
        ...prevState,
        ...JSON.parse(JSON.stringify(selectedCtrlAns)),
      }));
    }

    if (question.type === 'single_select' && Object.values(locSelectedCtrlAns).length > 0) {
      const selectedCtrlAnsVal = Object.values(locSelectedCtrlAns)[0];
      if (selectedCtrlAnsVal.compensatingOrNotApplicable) setSingleSelectVal(selectedCtrlAnsVal.compensatingOrNotApplicable);
    }
  }, [selectedCtrlAns, question.type]);

  const handleSaveAndContinue = useCallback(() => {
    setShowConfirmation(false);
    if (localRemarks) {
      updateAssessorFindings(localRemarks);
      setLocalRemarks('');
    }
    setSelectedCtrlAns({ ...locSelectedCtrlAns });
    setShow(false);
  }, [locSelectedCtrlAns, setSelectedCtrlAns, setShow, localRemarks, updateAssessorFindings]);

  const preSaveAndContinue = () => question.type !== 'single_select' ? setShowConfirmation(true) : handleSaveAndContinue();

  const handleCancel = useCallback(() => {
    // Reset state
    setLocalRemarks('');
    setSingleSelectQId(null);
    setSingleSelectVal(undefined);
    setLocSelectedCtrlAns(JSON.parse(JSON.stringify(selectedCtrlAns)));
    setShow(false);
  }, [selectedCtrlAns, setShow]);

  const handleSingleSelectVal = useCallback((event: React.SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    const newVal = (event.target as HTMLInputElement).value;
    setSingleSelectVal(newVal);
    if (!singleSelectQId) return;
    setLocSelectedCtrlAns(prevState => ({
      ...prevState,
      [singleSelectQId]: {
        ...prevState[singleSelectQId],
        compensatingOrNotApplicable: newVal,
      },
    }));
  }, [locSelectedCtrlAns, singleSelectQId]);

  const handleRadioChange = (id: number, value: string) => {
    setLocSelectedCtrlAns(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        compensatingOrNotApplicable: value,
      },
    }));
  };

  const handleSaveCommentBuilder = useCallback((comment: string) => {
    setLocalRemarks(comment);
  }, [setLocalRemarks]);

  const commentBuilderDefault = useMemo(() => {
    if (localRemarks) {
      return localRemarks;
    }

    const filteredAnswers = question.answers.filter(
      ans => !['Fully Implemented', 'Not Implemented', 'Not Applicable'].includes(ans.text || ''),
    );

    // Build the default comment structure
    const defaultBuild = filteredAnswers.map(ans => {
      const selectedCtrlAnsVal = locSelectedCtrlAns[ans.id];
      const plainText = (selectedCtrlAnsVal && defaultStatusMap[selectedCtrlAnsVal?.compensatingOrNotApplicable || ' '])
        ? defaultStatusMap[selectedCtrlAnsVal?.compensatingOrNotApplicable || ' ']
        : ' ';
      // Add the comment directly if it exists
      const commentText = selectedCtrlAnsVal?.comment ? ` ${selectedCtrlAnsVal.comment}` : '';

      // Combine everything on a single line
      return `• ${plainText} ${ans.text} \n\t Assessor Comment: ${commentText} \n`;
    }).join('\n');

    // Combine with any existing assessor finding
    return assessorFinding ? `${assessorFinding}\n\n${defaultBuild}` : defaultBuild;
  }, [locSelectedCtrlAns, question.answers, showCommentBuilder, assessorFinding, localRemarks]);

  const clearRadioAnswer = useCallback((id: number | null, isSingleSelect: boolean) => {
    if (!isSingleSelect && id) {
      setLocSelectedCtrlAns(prevState => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          compensatingOrNotApplicable: '',
        },
      }));
    } else {
      setSingleSelectVal('');
      if (!singleSelectQId) return;
      setLocSelectedCtrlAns(prevState => ({
        ...prevState,
        [singleSelectQId]: {
          ...prevState[singleSelectQId],
          compensatingOrNotApplicable: '',
        },
      }));
    }
  }, [locSelectedCtrlAns, singleSelectQId]);

  const handleOpenCommentDialog = useCallback((ansId: number) => {
    setCommentControlId(ansId);
    setShowAddCommentMod(true);
  }, [setShowCommentBuilder]);

  const handleSetControlComment = (comment: string | null) => {
    setShowAddCommentMod(false);
    if (comment === null) return; // If null, do not proceed with the state update
    setLocSelectedCtrlAns(prevState => ({
      ...prevState,
      [commentControlId ?? 0]: {
        ...prevState[commentControlId ?? 0],
        comment: comment,
      },
    }));
  };

  const handleAppendControlData = () => {
    updateAssessorFindings(commentBuilderDefault);
    handleSaveAndContinue();
  };

  return (
    <>
      <CommentBuilder
        canEdit={canEdit}
        commentBuilderDefault={commentBuilderDefault}
        handleHideCommentBuilder={handleHideCommentBuilder}
        showCommentBuilder={showCommentBuilder}
        handleSaveCommentBuilder={handleSaveCommentBuilder}
      />
      <PiDetailsControlComment
        canEdit={!!canEdit}
        piComment={locSelectedCtrlAns[commentControlId ?? 0]?.comment}
        showPiDetailsControlComment={showAddCommentMod}
        handleSetControlComment={handleSetControlComment}
      />
      <StandardDialog
        title={'Add each component-level detail to Assessor Findings box?'}
        subtitle={'This will automatically aggregate the details entered for these individual components into the main Assessor Findings box.'}
        handleClose={() => setShowConfirmation(false)}
        isOpen={showConfirmation}
        maxWidth="sm"
      >
        <Box display="flex" justifyContent="center" gap="10px" width="100%">
          <Button
            onClick={handleAppendControlData}
            color="primary"
            variant="contained"
          >
            YES
          </Button>
          <Button
            onClick={handleSaveAndContinue}
            color="primary"
            variant="outlined"
          >
            NO
          </Button>
        </Box>
      </StandardDialog>
      <StandardDialog
        title={'Partially Implemented Assessor Findings'}
        subtitle={'All controls require an answer. Comments are optional.'}
        handleClose={handleCancel}
        isOpen={show}
      >
        {question.type === 'single_select' && (
          <FormGroup>
            <Controller
              control={control}
              name='answer'
              render={({ field: { onChange, value } }) => {
                useEffect(() => {
                  setSingleSelectQId(value);
                }, []);
                return (
                  <>
                    <RadioGroup
                      aria-labelledby={question.title}
                      name='answer'
                      value={value ?? 0}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {question.answers.map((answer, key) => (
                        <FormControlLabel
                          sx={{
                            alignItems: 'flex-start',
                            '& .MuiRadio-root': {
                              padding: '0 .5em',
                            },
                            padding: '.5em 0',
                          }}
                          disabled
                          key={key}
                          value={answer.id}
                          control={<Radio />}
                          label={<Typography>{answer.text}</Typography>}
                        />
                      ))}
                    </RadioGroup>
                    <Box sx={{ marginLeft: '2.5em' }}>
                      <Box sx={{ display: 'flex', gap: '1em' }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'semiBold', color: theme.palette.customThemeColors?.darkGrey }}
                        >
                          Findings
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RadioGroup row name="implementationStatus" value={singleSelectVal ?? ''}>
                        <FormControlLabel
                            value="implemented"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Implemented"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                          <FormControlLabel
                            value="compensating_control"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Compensating Control"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                          <FormControlLabel
                            value="not_implemented"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Not Implemented"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                          <FormControlLabel
                            value="not_applicable"
                            onChange={handleSingleSelectVal}
                            control={<Radio size="small" />}
                            label="Not Applicable"
                            labelPlacement="end"
                            sx={{ marginRight: '1.5em', color: theme.palette.customThemeColors?.darkGrey }}
                          />
                        </RadioGroup>
                        { canEdit && (
                          <RoundIconButton onClick={() => clearRadioAnswer(null, true)}/>
                        )}
                      </Box>
                    </Box>
                  </>
                );
              }}
            />
          </FormGroup>
        )}
        {question.type === 'multi_select' && (
          <FormGroup>
            <div style={{ display: 'flex', alignItems: 'start', fontWeight: 'bold' }}>
              <Tooltip title="Implemented" placement="top" arrow>
                <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                  YES
                </Typography>
              </Tooltip>
              <Tooltip title="Compensating Control" placement="top" arrow>
                <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                  C/C
                </Typography>
              </Tooltip>
              <Tooltip title="Not Implemented" placement="top" arrow>
                <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                  N/I
                </Typography>
              </Tooltip>
              <Tooltip title="Not Applicable" placement="top" arrow>
                <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                  N/A
                </Typography>
              </Tooltip>
            </div>
            <Controller
              control={control}
              name="answer"
              render={() => (
                <>
                  {question.answers
                    .filter((ans) => !['Fully Implemented', 'Not Implemented', 'Not Applicable'].includes(ans.text || ''))
                    .map((answer, key) => (
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginBottom: '1em',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: '1em',
                      }}
                    >
                      <RadioGroup
                        row
                        name="implementationStatus"
                        value={locSelectedCtrlAns[answer.id]?.compensatingOrNotApplicable ?? ''}
                        sx={{ justifyContent: 'space-between', flex: 1 }}
                      >
                        {['implemented', 'compensating_control', 'not_implemented', 'not_applicable'].map((value) => (
                          <Radio
                            key={value}
                            disabled={!canEdit}
                            value={value}
                            onChange={() => handleRadioChange(answer.id, value)}
                            size="small"
                            sx={{ width: '40px' }}
                          />
                        ))}
                      </RadioGroup>

                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={!locSelectedCtrlAns[answer.id]?.comment?.length}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        sx={{
                          '& .MuiBadge-dot': { top: '6px', right: '6px' },
                        }}
                      >
                        <IconButton
                          onClick={() => handleOpenCommentDialog(answer.id)}
                          sx={{
                            color: locSelectedCtrlAns[answer.id]?.comment?.length
                              ? theme.palette.customThemeColors?.darkGrey
                              : theme.palette.customThemeColors?.lightGray,
                            marginLeft: '8px',
                          }}
                        >
                          <CommentIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </Badge>
                    </Box>

                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: theme.palette.customThemeColors?.darkGrey,
                          overflowWrap: 'break-word',
                          wordBreak: 'break-word',
                          flex: 1,
                          marginRight: '8px',
                          paddingTop: '4px',
                        }}
                      >
                        {answer.text}
                      </Typography>

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Remediation" placement="top" arrow>
                            <Typography
                              component="span"
                              sx={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: theme.palette.error.main,
                                color: 'white',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                lineHeight: 1,
                                textAlign: 'center',
                                paddingLeft: '1px',
                                boxSizing: 'border-box',
                                opacity: (locSelectedCtrlAns[answer.id]?.compensatingOrNotApplicable === 'not_implemented' ||
                                locSelectedCtrlAns[answer.id]?.compensatingOrNotApplicable === 'compensating_control') ? 1 : 0,
                              }}
                            >
                              R
                            </Typography>
                          </Tooltip>
                          <RoundIconButton disabled={!(canEdit && answer.id in locSelectedCtrlAns && locSelectedCtrlAns[answer.id].compensatingOrNotApplicable)} onClick={() => clearRadioAnswer(answer.id, false)} />
                      </Box>
                    </Box>
                  </Box>
                    ))}
                </>
              )}
            />
          </FormGroup>
        )}
    <StandardDialogActions>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
            onClick={handleCancel}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          {canEdit && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={preSaveAndContinue}
            >
              Continue
            </Button>
          )}
          </Box>
        </StandardDialogActions>
      </StandardDialog>
    </>
  );
}
