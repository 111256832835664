import React from 'react';
// MUI
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
// Navigation
import { useNavigate } from 'react-router-dom';
// Icons
import DownloadIcon from '@mui/icons-material/Download';
// Interfaces
import { PartnerResource } from '../../../interfaces/partnerResources.interface';
// Hooks
import { useAppSelector } from '../../../app/hooks';
import { useTheme } from '@mui/material/styles';
// Components
import ResourceCard from '../ResourceCard';
// Helpers
import { gradientColors } from '../../../services/partnerResourcesHelpers';
import { USER_TYPES } from '../../../services/user';

const StandardLibrary = ({ 
  filteredSections,
  expandedSection,
  uniqueDownloadCount,
}: {
  uniqueDownloadCount: (metaData: PartnerResource['metadata']) => number;
  expandedSection: null | string;
  filteredSections: PartnerResource[];
}) => {
  const { user } = useAppSelector(state => state.user);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCardClick = (document: any) => {
    navigate(`/partner-resources/${expandedSection}/${document.id}`);
  };
    
  return (
    <>
    {filteredSections.map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={item.id}>
          <Card
              elevation={0}
              sx={{
                height: user?.type === USER_TYPES.TPN_ADMIN ? 350 : 325,
                border: '1px solid #eaeaea',
                boxSizing: 'border-box',
                padding: 1,
                borderRadius: 2,
                backgroundColor: 'white',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s, border-color 0.3s',
                transformOrigin: 'center',
                '&:hover': {
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                  borderColor: '#eeeeee',
                },
              }}
              onClick={() => handleCardClick(item)}
          >
              <Box
                  sx={{
                    background: `linear-gradient(to bottom left, ${gradientColors[index % gradientColors.length].start}, ${gradientColors[index % gradientColors.length].end})`,
                    padding: 1,
                    borderRadius: 1,
                  }}
              >
                  <ResourceCard item={item} />
              </Box>
              <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative',
                    flex: 1,
                    height: 'calc(100% - 140px)',
                    pt: 3,
                    px: 1,
                  }}
              >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: '#2F2D4F',
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        marginLeft: '5px',
                        fontWeight: 400,
                        color: '#666666',
                      }}
                    >
                      by {item?.author?.displayName || 'Trusted Partner Network'}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: 1,
                        color: '#333333',
                        lineHeight: 1.6,
                        letterSpacing: '0.3px',
                      }}
                    >
                      {item.subDescription}
                    </Typography>
                  </Box>
                  { user?.type === USER_TYPES.TPN_ADMIN && (
                  <Box sx={{ justifyContent: 'space-between', bottom: 5, position: 'absolute' }}>
                      <Typography variant="caption" sx={{ color: theme.palette.customThemeColors?.darkGrey }}>
                          <DownloadIcon sx={{ fontSize: 'small', verticalAlign: 'middle', mr: 0.5 }} />
                          {uniqueDownloadCount(item.metadata)} Download{uniqueDownloadCount(item.metadata) === 1 ? '' : 's'}
                      </Typography>
                  </Box>
                  )}
              </CardContent>
          </Card>
      </Grid>
    ))}
    </>
  );
};

export default StandardLibrary;