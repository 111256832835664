import axios from 'axios';

import getAuthSession from './auth';
import { PartnerResourceMetadata } from '../interfaces/partnerResources.interface';

export async function postDownloadPartnerResource(partnerResourceMetadata: PartnerResourceMetadata) {
  const authSession = await getAuthSession();
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_API}/metadata/`,
      partnerResourceMetadata,
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return data as PartnerResourceMetadata;
  } catch (error) {
    console.error(error);
  }
}

export default async function fetchParterResources() {
  const authSession = await getAuthSession();
  try {
    const resources = await axios.get(
      `${process.env.REACT_APP_BASE_API}/resources/?limit=9999`,
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return resources.data;
  } catch (error) {
    console.error(error);
  }
}