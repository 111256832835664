import { FieldValues } from 'react-hook-form';
import { ContentOwnerProfile, ServiceCategory, VendorAdminProfile } from './vendor.interface';
import { Certification } from './certification.interface';
import { HardeningDocument } from './hardeningDocuments';
import { BillingFrequency } from './billing.interface';

export interface Contact {
  id?: number;
  name: string;
  phoneNumber?: string;
  address?: string;
  address2?: string;
  address3?: string;
  country?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  isPrimary?: boolean;
  primaryContact?: User;
  badge?: string;
  services?: Service[];
  companyApplications?: CompanyApplication[];
  blueShieldDate?: string;
  goldShieldDate?: string;
}
export interface ContactSubmissionData extends Omit<Contact, 'primaryContact' | 'services'> {
  primaryContact?: number | undefined;
  services?: number[] | undefined | Service[];
  createdBy?: User[] | number;
  createdAt?: string;
}

export interface Availability {
  id: number;
  startDate: Date;
  endDate: Date;
  user: number;
}

export interface Error {
  message: string
}

export interface UserFormData extends FieldValues {
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string
  errors?: Error[]
}

export interface SignupErrors {
  username?: string[],
  email?: string[],
  firstName?: string[],
  lastName?: string[],
  password?: string[],
}

export interface Document {
  name: string;
  imageUrl?: string;
  locationUrl: string;
  verified: boolean;
  validUtil?: Date;
}

export interface DocusignWaiver {
  id?: number;
  envelopeId?: string;
  envelopeCompleted: boolean;
  recipientCompleted: boolean;
  waiverCompletedAt?: Date;
  userSignWaiverAt?: Date;
}

export interface Service {
  id: number;
  name: string;
  serviceCategories: ServiceCategory[];
}

export interface ApplicationMeta {
  id?: number;
  title: string;
}

export type ApplicationType = {
  id: number;
  type: string;
};

export type Badge = {
  id?: number;
  title: 'non_tpn_self_reported' | 'tpn_self_reported' | 'tpn_assessed';
  awardedDate: string;
  expirationDate: string;
  createdAt?: string;
};

export type Version = {
  id?: number;
  value: string;
  badge?: Badge;
  hardeningDocuments?: HardeningDocument[] | number[];
};

export type ApplicationVersion = {
  id?: number;
  application: number | Application;
  version: number | Version;
};

export interface Application {
  id?: number;
  name: string;
  description?: string;
  isPublic: boolean;
  adminApproved: boolean;
  applications?: Application[] | number[];
  company?: Company | number;
  meta?: ApplicationMeta[] | number[]
  versions?: Version[] | number[];
  applicationTypes?: ApplicationType[] | number[];
  hardeningDocuments?: HardeningDocument[] | number[];
  applicationVersions?: ApplicationVersion[] | number[];
  createdAt?: string;
}

export interface ApplicationReponse {
  results: Application[];
  count: number;
}

export interface CustomApplicationOption {
  id: number;
  name: string;
  value: string;
  label: string;
}

export interface CompanyApplication {
  id?: number;
  company: number;
  application: Application;
  sites: Contact[];
  services: Service[];
  badge?: string;
  blueShieldDate?: string;
  goldShieldDate?: string;
  versions: number[]
}

export interface CompanyApplicationSubmitData {
  id?: number;
  company: number;
  application: number;
  sites?: number[];
  services: number[];
  badge?: string;
  blueShieldDate?: string;
  goldShieldDate?: string;
  versions?: number[]
}

export interface CompanyCertification {
  id?: number;
  company: number;
  companyName?: string;
  certification: Certification;
  issueDate: any;
  expirationDate: any;
  document: string;
  sites: Contact[];
  applications: Application[];
  createdAt?: Date | string;
  adminApproved?: boolean;
  adminReviewed?: boolean;
  applicationVersions?: number[];
}

export interface CompanyCertificationSubmitData extends Omit<CompanyCertification, 'certification' | 'sites' | 'applications' | 'document'> {
  certification: number;
  sites: number[];
  applicationVersions?: number[];
  applications?: number[];
  document: any;
}

export interface CompanyDocumentSubmitData {
  name: string;
  document: any;
  company: number;
}

export interface CompanyDocumentUpdateData extends Omit<CompanyDocumentSubmitData, 'document'> {
  sites?: number[];
  services?: number[];
  applicationVersions?: number[];
}

export interface CompanyDocument {
  id: number;
  name: string;
  document: string;
  company: number;
  createdAt: Date;
  updatedAt: Date;
  sites: number[];
  services: number[];
  applicationVersions: number[];
}

export interface CompanyLegalContact {
  id?: number;
  name?: string;
  email?: string;
  address?: string;
  company: number;
}

export interface AssessorLevel {
  id?: number
  descriptor: string
}

export interface Company {
  id?: number;
  slug?: string;
  name: string;
  logo?: string;
  domain?: string;
  contact?: Contact;
  locations: Contact[];
  services: Service[];
  employees: User[];
  pendingEmployees: User[];
  region: string,
  waiver?: DocusignWaiver;
  waivers?: number[];
  type?: string;
  isPaid: boolean;
  isInGracePeriod?: boolean;
  grossRevenue: string;
  agreementGrossRevenue?: string;
  employeeCount: string;
  admins: User[];
  displayShieldType?: string;
  primaryContactUser?: User;
  companyApplications?: CompanyApplication[];
  companyCertifications?: CompanyCertification[];
  companyDocuments?: CompanyDocument[];
  billingEmailCc?: string;
  billingEmailTo?: string;
  billingContact?: Contact;
  billingFrequency: BillingFrequency;
  billingCustomerId?: string;
  billingPoNumber?: string;
  billingStatementName?: string;
  vatNumber?: string;
  subsidiaries: Company[];
  assessorLevel?: AssessorLevel | null;
  canAssessCloud?: boolean;
  canAssessPrem?: boolean;
  createdAt?: string;
  blueShieldDate?: string;
  goldShieldDate?: string;
  operatingRegions?: string[];
  boxDriveUrl?: string;
  contentOwnerLevel?: number;
  companyLegalContacts?: CompanyLegalContact[];
  isPublished: boolean;
  context?: {
    showBillingUpdatePrompt: boolean;
  },
  markedIsPublishedAt?: boolean;
  hasPreviouslyPaid?: boolean;
  activeWorkflowBadges?: Badge[];
  isHidden?: boolean;
  akaNames?: string[];
  fkaNames?: string[];
  dbaNames?: string[];
}

export interface CompanySubmissionData extends Omit<Company, 'employees' | 'services' | 'locations' | 'primaryContactUser' | 'admins' | 'billingContact' | 'subsidiaries' | 'pendingEmployees' | 'isPaid' | 'isPublished' | 'isHidden'> {
  employees?: (number | undefined)[];
  pendingEmployees?: (number | undefined)[];
  services?: (number | undefined)[];
  locations?: (number | undefined)[];
  admins?: number[];
  primaryContactUser?: string | number | undefined;
  billingContact?: number | Contact | undefined;
  subsidiaries?: (number | undefined)[];
  isPaid?: boolean;
  isPublished?: boolean;
}

export interface TpnAdminProfile {
  id: number;
  isActive: boolean;
}

export interface BaseUser {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
  type: string;
  contact?: Contact;
  isConsultant: boolean;
  permissions?: string[];
}

export interface InviteUser extends Omit<BaseUser, 'firstName' | 'lastName'> {}

export default interface User extends BaseUser {
  id: number;
  contact: Contact;
  availability: Availability[];
  vendorAdminProfile: VendorAdminProfile;
  contentOwnerProfile: ContentOwnerProfile;
  tpnAdminProfile: TpnAdminProfile;
  lastLogin: Date;
  dateJoined: Date;
  cognitoId: string;
  groups: number[];
  userPermissions: number[];
  errors: SignupErrors;
  companies: Company[];
  pendingCompanies: Company[];
  isConsultant: boolean;
  isCognitoUserActive: boolean;
  permissions?: string[];
}

export interface AssessorUser {
  id: number;
  firstName: string;
  lastName: string;
}

export interface AssessorCompany {
  id: number;
  name: string;
  assessorLevel: AssessorLevel | null;
  employees: AssessorUser[]
}

export const Regions = [
  'Africa',
  'Asia',
  'Caribbean',
  'Middle East',
  'Oceania',
  'South/Central America',
  'UK/Europe',
  'US/CAN',
];
