import React, { useCallback, useEffect, useState, useMemo } from 'react';
// MUI
import {
  Alert,
  Box,
  Button,
  Tooltip,
  Typography,
  TableContainer,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
// Icons
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ErrorOutline } from '@mui/icons-material';
// Hooks
import { useAppSelector } from '../../app/hooks';
// Modals
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import ShareReportModal from './Modals/ShareReportModal';
import AuthorizedUsersModal from './Modals/AuthorizedUsersModal';
import EditAccessEndDateModal from './Modals/EditAccessEndDateModal';
// Interfaces
import { Workflow } from '../../interfaces/workflow.interface';
import { Engagement, EngagementWorkflow, Grantee, RemovedUser } from '../../interfaces/engagements';
// Services
import { WORKFLOW_MAP, WorkflowMap, fetchWorkflowById } from '../../services/workflow';
import { updateEngagement, createEngagement, getEngagements } from '../../services/engagements';
// Components
import Layout from '../Layout/Layout';
import ShieldLabel from './ShieldLabel';
import SummaryBubbles from './SummaryBubbles';
import ViewGoldReport from './ViewReports/ViewGoldReport';
import ViewBlueReport from './ViewReports/ViewBlueReport';
import TruncatedCell from './TruncatedCell';
import { USER_TYPES } from '../../services/user';

export default function SharedReports() {
  const { user } = useAppSelector((state) => state.user);
  const theme = useTheme();
  const [showAuthorizedUsersModal, setShowAuthorizedUsersModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [authorizedUsers, setAuthorizedUsers] = useState<Grantee[]>([]);
  const [removedUsers, setRemovedUsers] = useState<RemovedUser[]>([]);
  const [selectedEngagementId, setSelectedEngagementId] = useState<number | null>(null);
  const [engagementsSent, setEngagementsSent] = useState<Engagement[]>([]);
  const [engagementsReceived, setEngagementsReceived] = useState<Engagement[]>([]);

  const [isEditEndDateOpen, setIsEditEndDateOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isIndefinite, setIsIndefinite] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);
  const [inheritedReport, setInheritedReport] = useState<Engagement | null>(null);
  const [showShareReportModal, setShowShareReportModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedReport, setSelectedReport] = useState<Engagement | null>(null);
  const [menuItemWorkflow, setMenuItemWorkflow] = useState<Workflow | null>(null);
  const [isConfirmEndSharingOpen, setIsConfirmEndSharingOpen] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState<{ [key: number]: HTMLElement | null }>({});
  const handleClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement>, report: Engagement) => {
    if (!report.id) return;

    setMenuAnchor(prev => ({ ...prev, [Number(report.id)]: event.currentTarget }));
    setSelectedReportId(report.id);
    setSelectedReport(report);

    const siteAppWorkflows = await fetchWorkflowById(report.workflow.id as number);
    setMenuItemWorkflow(siteAppWorkflows);
  }, []);
  
  const handleClose = (reportId: number) => {
    setMenuAnchor(prev => ({ ...prev, [reportId]: null }));
    setSelectedReport(null);
    setSelectedReportId(null);
  };

  const handleEditEndDate = (report: Engagement | null) => {
    if (!report || !report.id) return;

    setStartDate(new Date(report.startDate));
    setEndDate(report.endDate ? new Date(report.endDate) : null);
    setIsIndefinite(!report.endDate);
    setSelectedReportId(report.id);
    setIsEditEndDateOpen(true);
  };

  const fetchEngagements = async () => {
    if (!user?.id) return;
    try {
      const localEngagementsSent = await getEngagements('sent', user.id);
      setEngagementsSent(localEngagementsSent.filter((a: Engagement) => a.createdBy.id === user.id || user.type === USER_TYPES.TPN_ADMIN).sort((a:Engagement, b: Engagement) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()));
      const localEngagementsReceived = await getEngagements('received', user.id);
      setEngagementsReceived(
        localEngagementsReceived
          .sort((a:Engagement, b: Engagement) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
          ),
      );
    } catch (error) {
      console.error('Error fetching engagements:', error);
    }
  };

  const handleSaveEndDate = async (updatedEndDate?: string) => {
    if (!selectedReportId) return;
  
    try {  
      setIsEditEndDateOpen(false);
      await updateEngagement({
        id: selectedReportId,
        endDate: updatedEndDate,
      });
      fetchEngagements();
    } catch (error) {
      console.error('Error updating end date:', error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchQuery('');
  };

  const summaryBubbleTabChange = useCallback((tab: number) => {
    console.log('tab', tab);
    setSelectedTab(tab);
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, report: Engagement) => {
    setAnchorEl(event.currentTarget);
    setSelectedReport(report);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedReport(null);
  };
  
  const handleEndSharing = useCallback((report: Engagement) => {
    setSelectedReport(report);
    setIsConfirmEndSharingOpen(true);
    setAnchorEl(null);
  }, []);

  const handleEndSharingFromAuthorizedUsers = useCallback( async () => {
    const engagement = engagementsSent.find((eng) => eng.id === selectedEngagementId);

    if (engagement) {
      // Update to end sharing now
      await updateEngagement({
        id: engagement.id,
        isActive: false,
        endDate: new Date().toISOString(),
      });

      fetchEngagements();
      setShowAuthorizedUsersModal(false);
    }
  }, [selectedEngagementId, engagementsSent]);

  const handleConfirmEndSharing = useCallback(async () => {
    if (!selectedReport?.id) return;
  
    // Update to end sharing now
    await updateEngagement({
      id: selectedReport.id,
      isActive: false,
      endDate: new Date().toISOString(),
    });

    fetchEngagements();
    setIsConfirmEndSharingOpen(false);
    setShowAuthorizedUsersModal(false);
  }, [selectedReport]);
  

  const handleSetShowAuthorizedUsersModal = useCallback((engagement: Engagement | null) => {
    if (!engagement || !engagement.id) return;

    const { grantees, id, removedUsers: removedUsersList } = engagement;

    if (removedUsersList) setRemovedUsers(removedUsersList);
    setAuthorizedUsers(grantees as Grantee[]);
    setSelectedEngagementId(id);
    setShowAuthorizedUsersModal(true);
  }, []);

  const handleDeleteAuthorizedUser = useCallback(async (grantee: Grantee) => {
    if (!selectedEngagementId) return;

    const updatedGrantees = authorizedUsers.filter((usr) => usr.id !== grantee.id);
    const engagement = await updateEngagement({
      id: selectedEngagementId,
      grantees: updatedGrantees.map(localGrantee => localGrantee.id) as number[],
    });

    setAuthorizedUsers(engagement.grantees as Grantee[]);

    if (engagement.removedUsers) {
      setRemovedUsers(engagement.removedUsers);
    }

  }, [authorizedUsers, selectedEngagementId]);

  const resetNewShareReport = useCallback(() => {
    setAuthorizedUsers([]);
    setSelectedEngagementId(null);
    setEndDate(null);
    setStartDate(null);
    setIsIndefinite(false);
    setSelectedReportId(null);
    setIsEditEndDateOpen(false);
  }, []);

  const handleCloseShareReportModal = useCallback(() => {
    setShowShareReportModal(false);
    setInheritedReport(null);
    resetNewShareReport();
  }, []);

  const addAuthorizedUser = useCallback( async (usr: Grantee) => {
    if (!selectedEngagementId) return;
    const updatedGrantees = [
      ...authorizedUsers,
      {
        id: usr.id,
        email: usr.email,
        firstName: usr.firstName,
        lastName: usr.lastName,
        companyName: authorizedUsers[0].companyName,
        companyIsPaid: authorizedUsers[0].companyIsPaidOrNotInGracePeriod,
      },
    ];

    const engagement = await updateEngagement({
      id: selectedEngagementId,
      grantees: updatedGrantees.map(grantee => grantee.id) as number[],
    });

    setAuthorizedUsers(engagement.grantees as Grantee[]);

    if (engagement.removedUsers) {
      setRemovedUsers(engagement.removedUsers);
    }
  }, [authorizedUsers, selectedEngagementId]);

  const handleShareReport = useCallback( async ({
    selectedReportTypes,
    startDate: localStartDate,
    endDate: localEndDate,
    workflows,
    questionnaireType,
    employeeList,
  }: {
    selectedReportTypes: string[];
    startDate: Date;
    endDate: Date | null;
    workflows: Workflow[];
    questionnaireType: string;
    employeeList: Grantee[];
  }) => {
    try {
      const workflow = workflows.find((wf) => wf.workflowType === questionnaireType)?.id;
      const grantorCompany = user?.companies[0]?.id;

      if (!workflow || !grantorCompany || !employeeList) return false;

      const engagement = await createEngagement({
        sharedReportTypes: selectedReportTypes,
        isActive: localStartDate <= new Date(),
        startDate: localStartDate,
        endDate: localEndDate,
        workflow,
        grantorCompany,
        grantees: employeeList.map((emp) => emp.id as number),
        createdBy: user.id,
      });

      return !!engagement;
    } catch (error) {
      console.error('Error sharing report:', error);
      return false;
    }
  }, []);

  const handleCopyAndReshare = (report: Engagement) => {
    setInheritedReport(report);
    setShowShareReportModal(true);
    handleMenuClose();
  };
  const disableNewReportButton = useMemo(() => {
    if (!user?.id || !user?.companies?.length) return true;
    // Ensure admins is an array of numbers and check if user.id exists in it
    if (!user.companies[0].admins.some((admin: unknown) => admin as number === user.id)) return true;
    return false;
  }, [user]);

  const userCompanyIsPaid = useMemo(() => (user?.companies[0]?.isPaid && !user?.companies[0]?.isInGracePeriod) || false, [user]);

  const handleCloseAuthUsersModal = useCallback(() => {
    setShowAuthorizedUsersModal(false);
    fetchEngagements();
  }, []);

  useEffect(() => {
    fetchEngagements();
  }, [showShareReportModal]);

  const engagementsInView = useMemo(() => selectedTab === 0 ? engagementsSent : engagementsReceived, [selectedTab, engagementsSent, engagementsReceived]);

  const filteredEngagements = useMemo(() => {
    const searchLower = searchQuery.toLowerCase().trim();
  
    return engagementsInView.filter((report) => {
      const engagementWorkflow = report.workflow as EngagementWorkflow;
  
      const startDateFormatted = report.startDate
        ? new Date(report.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
        : '';
  
      const endDateFormatted = report.endDate
        ? new Date(report.endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
        : 'Indefinite';

      return (
        (engagementWorkflow.siteName && engagementWorkflow.siteName.toLowerCase().includes(searchLower)) ||
        (engagementWorkflow.applicationName && engagementWorkflow.applicationName.toLowerCase().includes(searchLower)) ||
        (report.createdBy?.firstName && report.createdBy.firstName.toLowerCase().includes(searchLower)) ||
        (report.createdBy?.lastName && report.createdBy.lastName.toLowerCase().includes(searchLower)) ||
        (report.grantorCompany?.name && report.grantorCompany.name.toLowerCase().includes(searchLower)) ||
        (report.grantees?.some((g) => (g as Grantee)?.companyName?.toLowerCase().includes(searchLower)) ?? false) ||
        (WORKFLOW_MAP[report.workflow?.workflowType as keyof WorkflowMap]?.version?.toLowerCase().includes(searchLower)) || // Match version
        startDateFormatted.toLowerCase().includes(searchLower) ||
        endDateFormatted.toLowerCase().includes(searchLower)
      );
    });
  }, [engagementsInView, searchQuery]);  

  const MemoizedViewBlueReport = React.memo(ViewBlueReport);
  const MemoizedViewGoldReport = React.memo(ViewGoldReport);

  const memoizedReportProps = useMemo(() => ({
    engagementId: selectedReportId,
    closeMenuItem: () => handleClose(selectedReportId || 0),
    workflow: menuItemWorkflow,
    label: 'Questionnaire Report',
    surveyId: (selectedReport?.workflow as EngagementWorkflow)?.bpSurveyId,
    siteAppName: (selectedReport?.workflow as EngagementWorkflow)?.siteName || (selectedReport?.workflow as EngagementWorkflow)?.applicationName,
  }), [menuItemWorkflow, selectedReport, selectedReportId]);
  
  return (
    <Layout pageTitle="Service Provider - Shared Reports">
      <Box component="section" sx={{ px: 4, py: 1 }}>
        <Typography
          variant="h6"
          sx={{
            my: 2,
            fontWeight: 400,
            color: '#2F2D4F',
            letterSpacing: '0.5px',
          }}
        >
          Securely share your TPN Blue and Gold reports with other trusted TPN Service Provider members
        </Typography>
        { (!userCompanyIsPaid && user?.type !== USER_TYPES.TPN_ADMIN) &&
          <Alert severity="error">Your membership fee has not yet been paid. You will not be able to share or view shared reports until your company has paid its membership fee.</Alert>
        }
      </Box>
      <Box component="section" sx={{ px: 4, py: 2 }}>
        {/* Summary Bubbles */}
        <SummaryBubbles
          engagementsSent={engagementsSent}
          engagementsReceived={engagementsReceived}
          summaryBubbleTabChange={summaryBubbleTabChange}
        />
        {/* Tabs and Action Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Shared Reports Tabs"
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Reports You Shared" />
            <Tab label="Reports Shared With You" />
          </Tabs>
          <Tooltip placement='top' title={disableNewReportButton ? 'Contact a company admin to access this feature.' : ''} arrow>
            <span>
              <Button
                variant="contained"
                disabled={disableNewReportButton || !userCompanyIsPaid}
                color="primary"
                sx={{ mb: 1 }}
                onClick={() => setShowShareReportModal(true)}
              >
                Share New Report
              </Button>
            </span>
          </Tooltip>
        </Box>
        <Divider sx={{ mb: 4 }} />
        {/* Table Container */}
        <TableContainer>
          <Paper elevation={2} sx={{ border: `1px solid ${theme.palette.divider}`, overflow: 'hidden' }}>
            {/* Top Section: Title and Search Bar */}
            <Box sx={{ p: 2, px: 3, gap: 1.5, display: 'flex', flexDirection: 'column', backgroundColor: '#f7f8f9' }}>
              <Typography variant="h6" sx={{ fontWeight: 300, color: theme.palette.customThemeColors?.darkGrey }}>
                {selectedTab === 0 ? 'Reports You Shared' : 'Reports Shared With You'}
              </Typography>
              <TextField
                sx={{ minWidth: '500px', width: '33%', backgroundColor: 'white' }}
                variant="outlined"
                size="small"
                placeholder="Search reports..."
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            {/* Table Section */}
            <DataGrid
              sortingMode="client"
              rowHeight={75}
              sx={{
                '& .MuiDataGrid-cell': { padding: '12px' },
                '& .MuiDataGrid-columnHeader': { padding: '12px' },
                '& .MuiDataGrid-menuIcon': { display: 'none !important' },
              }}
              disableColumnMenu
              disableColumnSelector
              disableColumnFilter
              componentsProps={{
                columnMenu: { sx: { display: 'none' } },
              }}
              rows={filteredEngagements.map((report, index) => {
                const now = new Date();
                const viewStartDate = new Date(report.startDate);
                const viewEndDate = new Date(report.endDate);

                // Determine record status
                const isExpired = report.endDate && viewEndDate < now;
                const isUpcoming = viewStartDate > now && !isExpired;
                const isActive = viewStartDate <= now && (!report.endDate || viewEndDate >= now);

                // Apply the same color styling to both dates
                const dateColor = isUpcoming ? '#6c757d' : isActive ? '#2E7D32' : '#D32F2F';

                return {
                  id: index,
                  reportData: report, // Store the full report object for `renderCell`
                  company: (user?.type === USER_TYPES.TPN_ADMIN || selectedTab === 1) ? report.grantorCompany?.name : '',
                  name: (report.workflow as EngagementWorkflow)?.siteName || (report.workflow as EngagementWorkflow)?.applicationName,
                  type: (report.workflow as EngagementWorkflow)?.siteName ? 'Site' : 'Application',
                  version: WORKFLOW_MAP[(report.workflow as EngagementWorkflow)?.workflowType as keyof WorkflowMap]?.version,
                  reportTypes: report.sharedReportTypes,
                  sharedBy: `${report.createdBy.firstName} ${report.createdBy.lastName}`,
                  receivingCompany: selectedTab === 0 ? (report.grantees[0] as Grantee)?.companyName || (report.removedUsers && report.removedUsers[0]?.engagementUser?.companyName) : '',
                  authorizedUsers: report.grantees?.length || 0,
                  dateCreated: new Date(report.createdAt).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }),
                  startDate: report.startDate,
                  expirationDate: report.endDate || '', 
                  startDateFormatted: new Date(report.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }),
                  expirationDateFormatted: report.endDate 
                    ? new Date(report.endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                    : 'Indefinite',
                  startDateColor: dateColor,
                  expirationDateColor: dateColor,
                };
              })}
              columns={[
                ...((user?.type === USER_TYPES.TPN_ADMIN || selectedTab === 1) ? [{
                  field: 'company',
                  headerName: 'Company',
                  flex: 1,
                  sortable: true,
                  renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} />,
                }] : []),
                { field: 'name', headerName: 'Name', flex: 1, sortable: true, renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} /> },
                { field: 'type', headerName: 'Type', flex: 1, sortable: true, renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} /> },
                { field: 'version', headerName: 'Version', flex: 1, sortable: true, renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} /> },
                ...(selectedTab === 0
                  ? [
                    {
                      field: 'reportTypes',
                      headerName: 'Report Types',
                      flex: 1,
                      sortable: true,
                      renderCell: (params: any) => (
                          <ShieldLabel
                            showOne={params.value.some((type: string) => type === 'tpn_self_reported')}
                            showTwo={params.value.some((type: string) => type === 'tpn_assessed')}
                          />
                      ),
                    },
                  ]
                  : []),

                { field: 'sharedBy', headerName: 'Shared By', flex: 1, sortable: true, renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} /> },

                ...(selectedTab === 0
                  ? [
                    { field: 'receivingCompany', headerName: 'Receiving Company', flex: 1, sortable: true, renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} /> },
                    {
                      field: 'authorizedUsers',
                      headerName: 'Active Users',
                      flex: 1,
                      sortable: true,
                      renderCell: (params: any) => (
                          <Typography
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSetShowAuthorizedUsersModal(params.row.reportData);
                            }}
                            sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                          >
                            {params.value} Users
                          </Typography>
                      ),
                    },
                  ]
                  : []),
                {
                  field: 'dateCreated',
                  headerName: 'Date Created',
                  flex: 1,
                  sortable: true,
                  renderCell: (params: any) => <TruncatedCell selectedTab={selectedTab} text={params.value} />,
                },

                {
                  field: 'startDate',
                  headerName: 'Start Date',
                  flex: 1,
                  sortable: true,
                  valueGetter: (params) => new Date(params.row.startDate).getTime(),
                  renderCell: (params) => (
                    <Typography sx={{ fontWeight: 500, color: params.row.startDateColor }}>
                      {params.row.startDateFormatted}
                    </Typography>
                  ),
                },
                {
                  field: 'expirationDate',
                  headerName: 'Expiration Date',
                  flex: 1,
                  minWidth: 140,
                  sortable: true,
                  valueGetter: (params) => params.row.expirationDate ? new Date(params.row.expirationDate).getTime() : 0,
                  renderCell: (params) => {
                    const expirationDate = params.row.expirationDate ? new Date(params.row.expirationDate) : null;
                    const isExpired = expirationDate && expirationDate < new Date();
                    const userCompanyNotPaid = (!user?.companies[0]?.isPaid || user?.companies[0]?.isInGracePeriod) && !isExpired;
                    const sharedByCompanyNotPaid = (!params.row.reportData?.grantorCompany?.isPaid || params.row.reportData?.grantorCompany?.isInGracePeriod) && !isExpired;
                    const showSharedWarning = userCompanyNotPaid && selectedTab === 0 && user?.type !== USER_TYPES.TPN_ADMIN;
                    const showSharedByCompanyWarning = (sharedByCompanyNotPaid) && selectedTab === 1 && user?.type !== USER_TYPES.TPN_ADMIN;
                    return (
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', minWidth: 140  }}>
                        <Typography sx={{ fontWeight: 500, color: params.row.startDateColor, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {params.row.expirationDateFormatted}
                        </Typography>
                        {showSharedWarning && (
                          <Tooltip title="Access to this report is restricted until membership fees are paid" placement='top' arrow>
                           <ErrorOutline sx={{ color: 'orange', fontSize: '1.2rem', ml: 0.5,  mt: -0.5, verticalAlign: 'middle' }}/>
                          </Tooltip>
                        )}
                        {showSharedByCompanyWarning && (
                          <Tooltip title={`You can no longer access this report. Please contact ${params.row.sharedBy || params.row.company} to regain access.`} placement='top' arrow>
                            <ErrorOutline sx={{ color: 'orange', fontSize: '1.2rem', ml: 0.5,  mt: -0.5, verticalAlign: 'middle' }}/>
                          </Tooltip>
                        )}
                      </Box>
                    );
                  },
                },
                ...(selectedTab === 1
                  ? [
                    {
                      field: 'access',
                      headerName: 'Access',
                      flex: 1,
                      align: 'center' as const,
                      sortable: false,
                      disableColumnMenu: true,
                      renderCell: (params: any) => {
                        const report = params.row.reportData;
                        const isExpired = report.endDate && new Date(report.endDate) < new Date();
                        const isUpcoming = new Date(report.startDate) > new Date();
                        
                        return (
                          <>
                            {/* View Reports Button */}
                            <Button
                              disabled={isExpired || isUpcoming || !userCompanyIsPaid || report.grantorCompany?.isInGracePeriod || !report.grantorCompany?.isPaid}
                              variant="outlined"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClick(e, report);
                              }}
                              tabIndex={-1}
                              sx={{ textTransform: 'none', fontWeight: 500, width: 200, opacity: isExpired ? 0.5 : 1 }}
                            >
                              {report?.reportType ? 'View Reports' : 'View Reports'}
                            </Button>
                            {/* Dropdown Menu */}
                            <Menu
                              key={report.id}
                              anchorEl={menuAnchor[report.id || 0]}
                              open={Boolean(menuAnchor[report.id || 0])}
                              onClose={() => {
                                handleClose(report.id || 0);
                              }
                              }
                            >
                              <MenuItem disabled>
                                <Typography variant="body2" sx={{ color: '#777', width: '100%', fontWeight: 500, fontSize: '10px' }}>
                                  VIEW REPORTS
                                </Typography>
                              </MenuItem>

                              {selectedReportId === report.id && menuItemWorkflow &&
                              engagementsInView.find(e => e.id === report.id)?.sharedReportTypes.includes('tpn_self_reported') && (
                                <MenuItem >
                                  <MemoizedViewBlueReport {...memoizedReportProps} />
                                </MenuItem>
                              )
                            }
                            {selectedReportId && engagementsInView.find(e => e.id === selectedReportId)?.sharedReportTypes.includes('tpn_assessed') && (
                              <MenuItem onClick={() => handleClose(selectedReportId || 0)}>
                                <MemoizedViewGoldReport
                                  engagementId={selectedReportId}
                                  label="Assessment Report"
                                  closeMenuItem={() => handleClose(selectedReportId || 0)}
                                  assessmentId={(engagementsInView.find(e => e.id === selectedReportId)?.workflow as EngagementWorkflow)?.assessmentId}
                                  surveyId={(engagementsInView.find(e => e.id === selectedReportId)?.workflow as EngagementWorkflow)?.bpSurveyId}
                                  siteAppName={(engagementsInView.find(e => e.id === selectedReportId)?.workflow as EngagementWorkflow)?.siteName || (engagementsInView.find(e => e.id === selectedReportId)?.workflow as EngagementWorkflow)?.applicationName}
                                />
                              </MenuItem>
                            )}
                            </Menu>

                          </>
                        );
                      },
                    },
                    
                  ]
                  : []),

                ...(selectedTab === 0
                  ? [
                    {
                      field: 'actions',
                      headerName: 'Actions',
                      flex: 1,
                      align: 'center' as const,
                      sortable: false,
                      disableColumnMenu: true,
                      renderCell: (params: any) => {
                        const report = params.row.reportData;
                        const isExpired = report.endDate && new Date(report.endDate) < new Date();
                        return (
                            <>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMenuOpen(event, report);
                                }}
                                tabIndex={-1}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              {/* Menu for actions */}
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl) && selectedReport?.id === report.id}
                                onClose={handleMenuClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              >
                                <MenuItem onClick={() => { handleEditEndDate(selectedReport); handleMenuClose(); }} disabled={isExpired}>
                                  Edit Access End Date
                                </MenuItem>
                                <MenuItem onClick={() => { handleSetShowAuthorizedUsersModal(selectedReport); handleMenuClose(); }} disabled={isExpired}>
                                  Edit Authorized Users
                                </MenuItem>
                                <MenuItem disabled={!isExpired || !userCompanyIsPaid} onClick={() => { handleCopyAndReshare(report); handleMenuClose(); }}>
                                  Copy & Re-Share
                                </MenuItem>
                                <MenuItem onClick={() => { handleEndSharing(report); }} disabled={isExpired}>
                                  End Sharing
                                </MenuItem>
                              </Menu>
                            </>
                        );
                      },
                    },
                  ]
                  : []),
              ]}
              autoHeight
              hideFooter
              disableSelectionOnClick
            />
          </Paper>
        </TableContainer>
        <ShareReportModal
          isOpen={showShareReportModal}
          handleClose={handleCloseShareReportModal}
          handleShareReport={handleShareReport}
          inheritedReport={inheritedReport}
          authorizedUsers={authorizedUsers}
        />
        <AuthorizedUsersModal
          handleEndSharing={handleEndSharingFromAuthorizedUsers}
          removedUsers={removedUsers}
          addAuthorizedUser={addAuthorizedUser}
          deleteAuthorizedUser={handleDeleteAuthorizedUser}
          isOpen={showAuthorizedUsersModal}
          authorizedUsers={authorizedUsers}
          handleCloseAuthUsersModal={handleCloseAuthUsersModal}
        />
        <EditAccessEndDateModal
          isOpen={isEditEndDateOpen}
          setIsOpen={setIsEditEndDateOpen}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          isIndefinite={isIndefinite}
          setIsIndefinite={setIsIndefinite}
          handleSave={handleSaveEndDate}
        />
        <StandardDialog
          title="End Sharing"
          isOpen={isConfirmEndSharingOpen}
          handleClose={() => setIsConfirmEndSharingOpen(false)}
          maxWidth="xs"
        >
          <Box sx={{ p: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
            This will immediately end sharing of this report for all authorized users. This action cannot be undone. Do you wish to continue?
            </Typography>
          </Box>
          <StandardDialogActions>
            <Button onClick={() => setIsConfirmEndSharingOpen(false)} sx={{  color: '#333' }}>
              Cancel
            </Button>
            <Button onClick={handleConfirmEndSharing} color="error" variant="contained">
              End Sharing
            </Button>
          </StandardDialogActions>
        </StandardDialog>
      </Box>
    </Layout>
  );
}
