import axios from 'axios';

import getAuthSession from './auth';
import { VendorAdminProfile, VendorAdminProfileRequest } from '../interfaces/vendor.interface';

export const COMPANY_TYPES = {
  VENDOR: 'vendor',
  CONTENT_OWNER: 'content_owner',
  ASSESSOR: 'assessor',
};

export async function updateVendorAdminProfile(vendorProfile: VendorAdminProfileRequest) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/vendor_admin_profiles/${vendorProfile.id}/`,
    { ...vendorProfile },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as VendorAdminProfile;
}

export async function createVendorAdminProfile(vendorProfile: VendorAdminProfileRequest) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/vendor_admin_profiles/`,
    { ...vendorProfile },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as VendorAdminProfile;
}