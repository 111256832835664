import React, { useEffect, useState, useCallback } from 'react';
import StandardDialog, { StandardDialogActions } from '../../Modals/StandardDialog';
import { Business } from '@mui/icons-material';
import Fade from '@mui/material/Fade';
import { getCompanies } from '../../../services/company';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  FormControl,
  InputAdornment,
  CircularProgress,
  TableContainer,
  Paper,
  Tooltip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { getEngagementUserByEmail } from '../../../services/engagements';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import AddCircle from '@mui/icons-material/AddCircle';
import { Company } from '../../../interfaces/users.interface';
import { Grantee, RemovedUser } from '../../../interfaces/engagements';
import debounce from 'lodash/debounce';
import moment from 'moment';

const AuthorizedUsersModal = ({
  isOpen,
  authorizedUsers,
  removedUsers,
  deleteAuthorizedUser,
  addAuthorizedUser,
  handleCloseAuthUsersModal,
  handleEndSharing,
}: {
  isOpen: boolean;
  handleCloseAuthUsersModal: () => void;
  removedUsers: RemovedUser[];
  authorizedUsers: Grantee[];
  deleteAuthorizedUser: (grantee: Grantee) => void;
  addAuthorizedUser: (user: Grantee) => void;
  handleEndSharing: () => void;
}) => {
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [userToDelete, setUserToDelete] = useState<Grantee | null>(null);
  const [engagemmentCompany, setEngagementCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(false);
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [emailValidation, setEmailValidation] = useState<{ valid: boolean | null, employee?: Grantee }>({ valid: null, employee: undefined });
  const [allEngagementUsers, setAllEngagementUsers] = useState<Grantee[]>([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [removeError, setRemoveError] = useState('');
  const [showRemoveError] = useState(false);
  const [employeeSearchError, setEmployeeSearchError] = useState('');
  const [showEmployeeSearchError, setShowEmployeeSearchError] = useState(false);
  const [lastUserDelete, setLastUserDelete] = useState(false);

  const theme = useTheme();

  const debouncedHandleEmployeeSearch = useCallback(
    debounce(async (email: string, companyId?: number) => {
      if (!email || !companyId) {
        setEmailValidation({ valid: null });
        return;
      }

      try {
        setLoading(true);
        const employee = await getEngagementUserByEmail({ granteeCompanyId: companyId, email });
        if (employee) {
          setEmailValidation({ valid: true, employee });
        } else {
          setEmailValidation({ valid: false });
        }
      } catch (error) {
        setEmailValidation({ valid: false });
      } finally {
        setLoading(false);
      }
    }, 500),
    [engagemmentCompany],
  );

  // Function to add user
  const handleAddUser = useCallback(() => {
    if (emailValidation.valid && emailValidation.employee) {
      if (authorizedUsers.some((user) => user.email !== emailValidation.employee?.email)) {
        addAuthorizedUser(emailValidation.employee);
      }
      setEmployeeSearch('');
      setEmailValidation({ valid: null });
    }
  }, [emailValidation, employeeSearch, addAuthorizedUser]);

  const handleRemoveUser = (email: string) => {
    const usrToDelete = authorizedUsers.find((user) => user.email === email);
    if (!usrToDelete) return;

    // Check if there's only one active user left
    if (authorizedUsers.length === 1) {
      setLastUserDelete(true);
    }

    setUserToDelete(usrToDelete);
    setConfirmDeleteUser(true);
  };

  const handleDeleteConfirmed = useCallback(async () => {
    if (userToDelete) {
      await deleteAuthorizedUser(userToDelete);
    }

    if (lastUserDelete) {
      handleEndSharing();
    }

    setConfirmDeleteUser(false);
    setUserToDelete(null);
    setLastUserDelete(false);
  }, [userToDelete, lastUserDelete, deleteAuthorizedUser, handleEndSharing]);

  const handleCloseNewAuthUserModal = () => {
    setOpenAddUserModal(false);
    setEmailValidation({ valid: null });
    setEmployeeSearchError('');
  };

  const handleCloseRemoveUserModal = useCallback(() => {
    setConfirmDeleteUser(false);
    setUserToDelete(null);
    setLastUserDelete(false);
  }, []);

  useEffect(() => {
    const companyName =
      authorizedUsers?.[0]?.companyName ||
      removedUsers?.[0]?.engagementUser?.companyName;

    if (!companyName) return;

    const fetchCompanyData = async () => {
      const companies = await getCompanies(
        `?limit=10&type=vendor&ordering=-created_at&is_published=true&search=${companyName}`,
      );

      if (companies?.[0]?.id) {
        setEngagementCompany(companies[0]);
      }
    };

    fetchCompanyData();
  }, [authorizedUsers, removedUsers]);

  useEffect(() => {
    // If the searched input is already an authorized user, don't make the API call
    if (authorizedUsers.some((user) => user.email === employeeSearch)) {
      setEmployeeSearchError('User is already an authorized user.');
      setShowEmployeeSearchError(true);
      setEmailValidation({ valid: null });
      return;
    }

    setShowEmployeeSearchError(false);
    setEmployeeSearchError('');
    debouncedHandleEmployeeSearch(employeeSearch, engagemmentCompany?.id);
    return () => debouncedHandleEmployeeSearch.cancel();
  }, [employeeSearch, debouncedHandleEmployeeSearch, engagemmentCompany]);

  useEffect(() => {
    if (!isOpen) {
      setEmailValidation({ valid: null });
      setEngagementCompany(null);
      setEmployeeSearch('');
      setAllEngagementUsers([]);
      setRemoveError('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (!openAddUserModal) {
      // Reset all states
      setEmployeeSearch('');
      setEmailValidation({ valid: null });
      setRemoveError('');
    }
  }, [openAddUserModal]);

  useEffect(() => {
    setAllEngagementUsers(
      [
        ...authorizedUsers,
        ...removedUsers.map(((removed: any) => removed.engagementUser)),
      ]
        .sort((a, b) => a.email.localeCompare(b.email)),
    );
  }, [authorizedUsers, removedUsers]);

  const logoSrc = engagemmentCompany?.logo || `${process.env.PUBLIC_URL}/assets/default-company.png`;

  return (
    <>
      <StandardDialog
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <GroupIcon />
            Manage Authorized Users
          </Box>
        }
        subtitle="Add or remove users who have access to this report."
        isOpen={isOpen}
        handleClose={handleCloseAuthUsersModal}
        maxWidth="md"
      >
        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* Company Name, Logo, and Add User Action */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid #ddd',
                  backgroundColor: '#fff',
                  overflow: 'hidden',
                }}
              >
                {engagemmentCompany?.logo ? (
                  <img
                    src={logoSrc}
                    alt="Company Logo"
                    onError={(e) => (e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/default-company.png`)}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <Business sx={{ fontSize: 26, color: '#bbb' }} />
                )}
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 400, color: '#333' }}>
                {engagemmentCompany?.name || ''}
              </Typography>
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenAddUserModal(true)}
              >
                New Authorized User
              </Button>
          </Box>

          {/* Authorized Users Table */}
          <TableContainer
            sx={{
              border: `1px solid ${theme.palette.divider}`, borderRadius: '4px', overflow: 'hidden', display: 'flex', flexDirection: 'column',
            }}
          >
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Paper elevation={0} sx={{ border: 'none', overflow: 'hidden' }}>
              {/* Top Section: Title */}
              <Box
                sx={{
                  p: 2,
                  px: 3,
                  gap: 1.5,
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f7f8f9',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 300, color: theme.palette.customThemeColors?.darkGrey, borderColor: 'transparent' }}>
                  Authorized Users
                </Typography>
              </Box>

              {/* Table Section */}
              <DataGrid
                sortingMode="client"
                rowHeight={60}
                autoHeight
                sx={{
                  borderColor: 'transparent',
                  overflow: 'hidden',
                }}
                disableColumnMenu
                disableColumnSelector
                disableColumnFilter
                rows={allEngagementUsers.map((user, index) => ({
                  id: index,
                  firstName: user.firstName || 'N/A',
                  lastName: user.lastName || '',
                  email: user.email,
                  status: removedUsers.some((removedUser: any) => removedUser.engagementUser.email === user.email) ? 'Inactive' : 'Active',
                }))}
                columns={[
                  { field: 'firstName', headerName: 'First Name', flex: 1, sortable: true },
                  { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: true },
                  { field: 'email', headerName: 'Email', flex: 2, sortable: true },
                  {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                    sortable: true,
                    renderCell: (params) => {
                      const inactiveEngagementUser = removedUsers.find((removedUser: any) => removedUser.engagementUser.email === params.row.email);
                      return (
                      <Tooltip  title={params.value !== 'Active' && inactiveEngagementUser
                        ? `Removed by ${inactiveEngagementUser.removedBy?.firstName || ''} ${inactiveEngagementUser.removedBy?.lastName || ''} - ${moment(inactiveEngagementUser.removedOn).format('MMM DD, YYYY')}`
                        : ''
                      } arrow placement='top'>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FiberManualRecordIcon
                          sx={{
                            color: params.value === 'Active' ? '#2E7D32' : '#D32F2F',
                            fontSize: '1rem',
                            marginRight: '6px',
                          }}
                        />
                        <Typography sx={{ fontWeight: 500 }}>{params.value}</Typography>
                      </Box>
                      </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => {
                      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
                      const open = Boolean(anchorEl);

                      const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                      };

                      const handleMenuClose = () => {
                        setAnchorEl(null);
                      };

                      return (
                          <>
                            <IconButton onClick={handleMenuClick}>
                              <MoreVertIcon />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                              <MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  // Call remove action if user is Active
                                  handleRemoveUser(params.row.email);
                                }}
                                disabled={params.row.status !== 'Active'}
                              >
                                Remove User Access
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  const userObj = allEngagementUsers.find((u) => u.email === params.row.email);
                                  if (userObj) {
                                    addAuthorizedUser(userObj);
                                  }
                                }}
                                disabled={params.row.status !== 'Inactive'}
                              >
                                Restore User Access
                              </MenuItem>
                            </Menu>
                          </>
                      );
                    },
                  },
                ]}
              />
            </Paper>
            </Box>
          </TableContainer>
          <Fade
            in={showRemoveError}
            timeout={{ enter: 300, exit: 600 }}
            onExited={() => setRemoveError('')}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, ml: 1 }}>
              <Typography variant="body2" color="error">
                {removeError}
              </Typography>
            </Box>
          </Fade>

        </Box>

        {/* New Add Authorized User Modal */}
        <StandardDialog
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <PersonAddIcon />
              New Authorized User
            </Box>
          }
          subtitle="Enter the company user's email address."
          isOpen={openAddUserModal}
          handleClose={handleCloseNewAuthUserModal}
          maxWidth="sm"
        >
          <Box>
            <FormControl fullWidth>
              <TextField
                label="Enter Employee Email"
                variant="outlined"
                value={employeeSearch}
                onChange={(e) => setEmployeeSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading ? <CircularProgress size={20} /> : null}
                      {emailValidation.valid === true && <CheckCircle color="success" />}
                      {emailValidation.valid === false && <Cancel color="error" />}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Fade
              in={showEmployeeSearchError}
              timeout={{ enter: 300, exit: 600 }}
              onExited={() => setEmployeeSearchError('')}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, ml: 1 }}>
                <Typography variant="body2" color="error">
                  {employeeSearchError}
                </Typography>
              </Box>
            </Fade>
            {emailValidation.valid === false ? (
              <Typography  variant="body2" color="error" sx={{ mt: 1 }}>
                No matching email found for this company.
              </Typography>
            ) : emailValidation.valid === true ? (
              <Button
                onClick={() => {
                  handleAddUser();
                  setOpenAddUserModal(false);
                }}
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
                sx={{
                  mt: 2,
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  textTransform: 'none',
                  boxShadow: 'none',
                }}
              >
                Add User
              </Button>
            ) : null}
          </Box>
        </StandardDialog>
        <StandardDialogActions>
          <Button onClick={handleCloseAuthUsersModal} color="primary" variant="contained">
            Close
          </Button>
        </StandardDialogActions>
      </StandardDialog>
      <StandardDialog
      title="Remove Authorized User"
      isOpen={confirmDeleteUser}
      handleClose={handleCloseRemoveUserModal}
      maxWidth="sm"
    >
      <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
        {
        !lastUserDelete ? (
          <>
          Are you sure you want to remove {
            userToDelete
              ? `${(userToDelete.firstName || '')} ${(userToDelete.lastName || '')}`.trim() || userToDelete.email
              : 'this user'
          } from the Authorized Users list? This action will revoke their access immediately.</>
        ) : (
          <>
          Removing the last user will end sharing for this report. This action cannot be undone. Are you sure you want to proceed?
          </>
        )}
      </Typography>
      <StandardDialogActions>
        <Button onClick={handleCloseRemoveUserModal} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleDeleteConfirmed} color="error" variant="contained">
         { lastUserDelete ? 'End Sharing' : 'Remove User' }
        </Button>
      </StandardDialogActions>
    </StandardDialog>
  </>
  );
};

export default AuthorizedUsersModal;