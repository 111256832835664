import React, { useState } from 'react';
import { Collapse, ListItemButton, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DescriptionIcon from '@mui/icons-material/Description';
import Divider from '@mui/material/Divider';
import { CheckBoxOutlineBlank, WorkspacePremium } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ForumIcon from '@mui/icons-material/Forum';
import { useAppSelector } from '../../app/hooks';

export default function LegendCard(props: { mt?: number }) {
  const { assessmentStatus } = useAppSelector(state => state.questionnaireAnswer);
  const [expanded, setExpanded] = useState(true);
  
  const theme = useTheme();
  return (
    <Paper sx={{ mt: props.mt ? props.mt : 0 }}>
      <List>
        <ListItemButton onClick={() => setExpanded(!expanded)}>
        <ListItemText 
           primary={
               <strong style={{ color: theme.palette.customThemeColors?.darkGrey }}>Legend</strong>
           }
         />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem>
              <ListItemIcon>
                <WorkspacePremium htmlColor='black' />
              </ListItemIcon>
              <ListItemText primary='Best Practice Question'  primaryTypographyProps={{ fontWeight: '500' }}/>
            </ListItem>
            {(!assessmentStatus || assessmentStatus === 'Pre-Assessment') && <>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxOutlineBlank />
                </ListItemIcon>
                <ListItemText primary='Unanswered Question' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxIcon sx={{ color: theme.palette.success.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.success.main }} primary='Answered' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon sx={{ color: theme.palette.primary.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.primary.main }} primary='Satisfied by Certificate' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxIcon sx={{ color: theme.palette.nonCompliant.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.nonCompliant.main }} primary='For Review' />
              </ListItem>
            </>}
            {assessmentStatus && assessmentStatus !== 'Pre-Assessment' && <>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxOutlineBlank />
                </ListItemIcon>
                <ListItemText primary='Unassessed Question' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxIcon sx={{ color: theme.palette.success.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.success.main }} primary='Assessor Reviewed' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxIcon sx={{ color: theme.palette.error.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.error.main }} primary='Remediation: Awaiting Plan' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                <CheckBoxIcon sx={{ color: theme.palette.secondary.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.secondary.main }} primary='Remediation: Content Owner Priority' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxIcon sx={{ color: theme.palette.nonCompliant.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.nonCompliant.main }} primary='Remediation: Plan - Will Later/Will Not' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckBoxIcon sx={{ color: theme.palette.primary.main }}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: theme.palette.primary.main }} primary='Remediation: Complete' />
              </ListItem>
            </>}
          </List>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <List disablePadding>
            <ListItem>
              <ListItemIcon>
                <VisibilityIcon sx={{ color: 'black' }} />
              </ListItemIcon>
              <ListItemText primary='Question Visible Due to Logic' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ForumIcon sx={{ color: 'black' }} />
              </ListItemIcon>
              <ListItemText primary='Question has Comments' />
            </ListItem>
          </List>
        </Collapse>
        <List>
   {!expanded && (
     <ListItem style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemIcon>
          <WorkspacePremium htmlColor='#050505' />
          </ListItemIcon>
          <ListItemText
         primary='Best Practice Question'
	         primaryTypographyProps={{ fontWeight: '500', color: theme.palette.customThemeColors?.darkGrey }}
       />
     </ListItem>
   )}
 </List>
      </List>
    </Paper>
  );
}
