import React from 'react';
// MUI
import { Box, Button, Grid, Typography } from '@mui/material';
// Icons
import { FileDownload } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
// Helpers
import { EmojiFlagMapType, ReactWorldFlagsMap } from '../../../services/partnerResourcesHelpers';
// Interfaces
import { PartnerResource } from '../../../interfaces/partnerResources.interface';
// Hooks
import { useAppSelector } from '../../../app/hooks';

import { useTheme } from '@mui/material/styles';

import Flag from '../PartnerResourcesFlags';
import { USER_TYPES } from '../../../services/user';

const MPABestPractices = ({
  filteredSections,
  handleDownloadPartnerResource,
  uniqueDownloadCount,
}: {
  uniqueDownloadCount: (metaData: PartnerResource['metadata']) => number;
  handleDownloadPartnerResource: (section: PartnerResource) => void;
  filteredSections: PartnerResource[];
}) => {

  const { user } = useAppSelector(state => state.user);
  const theme = useTheme();

  return (
        <Box sx={{ padding: 3 }}>
            {/* Section Subheader */}
            <Typography
            variant="body2"
            sx={{
              mb: 2,
              color: '#666',
              px: 1,
              fontStyle: 'italic',
            }}
            >
            Download the MPA Content Security Best Practices in the following languages
            </Typography>
            <Grid container spacing={2}>
                {filteredSections.sort((a, b) => {
                  const nativeNameA = ReactWorldFlagsMap[a.title as EmojiFlagMapType].nativeName;
                  const nativeNameB = ReactWorldFlagsMap[b.title as EmojiFlagMapType].nativeName;
                  // Check if either is "English" and prioritize it
                  if (nativeNameA === 'English') return -1;
                  if (nativeNameB === 'English') return 1;

                  // Otherwise, sort alphabetically
                  return nativeNameA.localeCompare(nativeNameB);
                }).map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index} sx={{ minWidth: '375px' }}>
                    <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 2,
                          minHeight: user?.type === USER_TYPES.TPN_ADMIN ? 86 : 'auto',
                          border: '1px solid #eceef0',
                          borderRadius: 2,
                          backgroundColor: '#fafbfc',
                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            borderColor: '#d9dde1',
                            backgroundColor: '#f8f9fb',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                    >
                        <Box sx={{ position: 'relative', minWidth: '150px' }}>
                        {/* Flag and Language */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* Flag Icon */}
                            <Flag language={item.title as EmojiFlagMapType} />

                            <Typography
                            variant="body1"
                            sx={{
                              color: '#4a4a4a',
                              fontWeight: 500,
                              '&:hover': {
                                color: '#333333',
                              },
                            }}
                            >{ReactWorldFlagsMap[item.title as EmojiFlagMapType].nativeName}</Typography>
                        </Box>
                        { (user?.type === USER_TYPES.TPN_ADMIN) && (
                        <Box sx={{ position: 'absolute', width: '100%', bottom: -22 }}>
                            <Typography variant="caption" sx={{ color: theme.palette.customThemeColors?.darkGrey }}>
                                <DownloadIcon sx={{ fontSize: 'small', verticalAlign: 'middle', mr: 0.5 }} />
                                {uniqueDownloadCount(item.metadata)} Download{uniqueDownloadCount(item.metadata) === 1 ? '' : 's'}
                            </Typography>
                        </Box>
                        )}
                        </Box>
                        <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          handleDownloadPartnerResource(item);
                        }}
                        startIcon={<FileDownload sx={{ fontSize: 18 }}/>}
                        sx={{
                          textTransform: 'none',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: 'primary.dark',
                            color: '#fff',
                          },
                        }}
                        >
                        Download
                        </Button>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
  );
};

export default MPABestPractices;