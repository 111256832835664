import axios from 'axios';
import getAuthSession from './auth';
import { FetchAllWorkflowsRequest } from '../app/store/workflowSlice';
import { Workflow, WorkflowTypeKey } from '../interfaces/workflow.interface';

export const WORKFLOW_STATUSES = {
  INCOMPLETE: 'incomplete',
  NON_COMPLIANT: 'non-compliant',
  COMPLETE: 'complete',
  SUBMITTED_FOR_ASSESSMENT: 'submittedForAssessment',
  ASSIGNED: 'Assigned',
  PRE_ASSESSMENT: 'Pre-Assessment',
  ASSESSING: 'Assessing',
  SUBMITTED: 'Submitted',
  REJECTED: 'Rejected',
  REMEDIATION: 'Remediation',
  FINAL_COMPLETE: 'Complete',
} as const;

export const wfHierarchy = [
  WORKFLOW_STATUSES.INCOMPLETE,
  WORKFLOW_STATUSES.NON_COMPLIANT,
  WORKFLOW_STATUSES.COMPLETE,
  WORKFLOW_STATUSES.SUBMITTED_FOR_ASSESSMENT,
  WORKFLOW_STATUSES.ASSIGNED,
  WORKFLOW_STATUSES.PRE_ASSESSMENT,
  WORKFLOW_STATUSES.ASSESSING,
  WORKFLOW_STATUSES.SUBMITTED,
  WORKFLOW_STATUSES.REJECTED,
  WORKFLOW_STATUSES.REMEDIATION,
  WORKFLOW_STATUSES.FINAL_COMPLETE,
];

export type WorkflowStatus = typeof WORKFLOW_STATUSES[keyof typeof WORKFLOW_STATUSES];

export async function createNewWorkflow(props: { workflowType: WorkflowTypeKey, baseline: number, serviceProvider: number, status: string, site?: number, application?: number, applicationVersion?: number, version?: number }) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/workflows/`,
    { ...props, isActive: true },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function fetchWorkflowById(workflow_id: number) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/workflows/${workflow_id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Workflow;
}

export async function fetchWorkflows(request: FetchAllWorkflowsRequest, isActive?: boolean) {
  const authSession = await getAuthSession();
  const active = typeof isActive === 'undefined' ? 'true' : isActive;
  const queryString = Object.entries(request).filter(([_, val]) => val !== undefined && (_ || !_)).map(([key, value]) => `${key}=${value}`).join('&');
  const url = `${process.env.REACT_APP_BASE_API}/workflows/?is_active=${active}${queryString ? `&${queryString}` : ''}`;
  const response = await axios.get(
    url,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Workflow[];
}

export async function deactivateWorkflowProcess(workflow_id: number) {
  const authSession = await getAuthSession();
  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/workflows/deactivate/`,
    {
      workflow_id,
    },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function updateWorkflow(request: Partial<Workflow>) {
  const authSession = await getAuthSession();
  const { id,  ...req } = request;
  const currentWorkflow = await axios.get(
    `${process.env.REACT_APP_BASE_API}/workflows/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  
  const currentStatus = currentWorkflow.data?.status;

  const currentStatusIndex = wfHierarchy.indexOf(currentStatus || '');
  const newStatusIndex = wfHierarchy.indexOf(req.status as typeof wfHierarchy[number] || '');
  const statusExceptions = (currentStatus === 'complete' && req.status === 'incomplete') || (currentStatus === 'non-compliant' && req.status === 'incomplete');
  // If the new status is lower in the hierarchy, just use the current status unless in pre-assessment phase
  if (newStatusIndex < currentStatusIndex && !statusExceptions) {
    req.status = currentStatus;
  }
  const url = `${process.env.REACT_APP_BASE_API}/workflows/${id}/`;
  const response = await axios.patch(
    url, req,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Workflow;
}

// Constants for workflow types
export const TPN_BEST_PRACTICE_5_1 = 'tpn_best_practices_5.1';
export const TPN_BEST_PRACTICE_5_2 = 'tpn_best_practices_5.2';
export const TPN_BEST_PRACTICE_5_3 = 'tpn_best_practices_5.3';

interface WorkflowEntry {
  label: string;
  version: string;
  questionnaireTitle: string;
}

export type WorkflowMap = {
  [key in typeof TPN_BEST_PRACTICE_5_1 | typeof TPN_BEST_PRACTICE_5_2 | typeof TPN_BEST_PRACTICE_5_3]: WorkflowEntry;
};

export const WORKFLOW_MAP: WorkflowMap = {
  [TPN_BEST_PRACTICE_5_1]: { label: 'Questionnaire v5.1', version: 'v5.1', questionnaireTitle: 'TPN Best Practices Questionnaire v5.1' },
  [TPN_BEST_PRACTICE_5_2]: { label: 'Questionnaire v5.2', version: 'v5.2', questionnaireTitle: 'TPN Best Practices Questionnaire v5.2' },
  [TPN_BEST_PRACTICE_5_3]: { label: 'Questionnaire v5.3', version: 'v5.3', questionnaireTitle: 'TPN Best Practices Questionnaire v5.3' },
};

export const WorkflowLabels = [
  TPN_BEST_PRACTICE_5_1,
  TPN_BEST_PRACTICE_5_2,
  TPN_BEST_PRACTICE_5_3,
];

export const workflowLabels = (wf: string) => {
  return WORKFLOW_MAP[wf as keyof WorkflowMap]?.label || wf;
};

export const workflowVersion = (workflowType?: string) => {
  return WORKFLOW_MAP[workflowType as keyof WorkflowMap]?.version || '';
};

export const ReadOnlyBaselineWorkflowStatusArray: WorkflowStatus[] = [
  WORKFLOW_STATUSES.SUBMITTED_FOR_ASSESSMENT,
  WORKFLOW_STATUSES.ASSIGNED,
  WORKFLOW_STATUSES.ASSESSING,
  WORKFLOW_STATUSES.SUBMITTED,
  WORKFLOW_STATUSES.REJECTED,
  WORKFLOW_STATUSES.REMEDIATION,
  WORKFLOW_STATUSES.FINAL_COMPLETE,
];

export const CanNotSetBackToIncompleteWorkflowStatusArray: WorkflowStatus[] = [
  WORKFLOW_STATUSES.ASSESSING,
  WORKFLOW_STATUSES.SUBMITTED,
  WORKFLOW_STATUSES.REJECTED,
  WORKFLOW_STATUSES.REMEDIATION,
  WORKFLOW_STATUSES.FINAL_COMPLETE,
];