import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../app/store/userSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import getAuthSession, { refreshAuthSession } from '../../services/auth';
import { setShowTokenExpiredModal } from '../../app/store/userSlice';
import { useAppSelector } from '../../app/hooks';

export default function NestedAccountList() {
  const showTokenExpiredModal = useAppSelector(
    (state) => state.user?.showTokenExpiredModal ?? false,
  );

  const [openNav, setOpen] = useState(false);
  const [loginSession, setLoginSession] = useState<string | null>(null);
  const [expirationTime, setExpirationTime] = useState<number | null>(null);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const loggoutTimeDisplay = '00:00:00';

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!openNav);
  };

  const { signOut } = useAuthenticator();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
    signOut();
    navigate('/', { replace: true });
  };

  const updateExpirationTime = async () => {
    try {
      const authSession = await getAuthSession();
      let newExpiration = authSession.getAccessToken().getExpiration() * 1000;
      const currentTimeUTC = Date.now();

      // Refresh access token 5 minutes before it expires
      if (newExpiration - currentTimeUTC < 5 * 60 * 1000) {
        await refreshAuthSession(); // Disregard local storage, fetch new session from Cognito
        const refreshedSession = await getAuthSession();
        newExpiration = refreshedSession.getAccessToken().getExpiration() * 1000;
      }

      if (newExpiration !== expirationTime && !showTokenExpiredModal) {
        setExpirationTime(newExpiration);
      }
    } catch (error) {
      console.error('Error fetching session:', error);
    }
  };

  useEffect(() => {
    updateExpirationTime();

    const refreshInterval = setInterval(updateExpirationTime, 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, []);

  useEffect(() => {
    if (!expirationTime) return;

    const updateLoginSession = () => {
      const remainingTime = expirationTime - Date.now();

      if (remainingTime <= 0) {
        setLoginSession(loggoutTimeDisplay);
        console.log('Token Expired', expirationTime, Date.now());
        dispatch(setShowTokenExpiredModal(true));
      } else {
        setLoginSession(moment.utc(remainingTime).format('HH:mm:ss'));
      }
    };

    updateLoginSession();

    countdownIntervalRef.current = setInterval(updateLoginSession, 1000);

    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, [expirationTime, dispatch]);

  return (
    <List
      component="nav"
      sx={{
        position: 'absolute',
        bottom: openNav ? 32 : 0,
        width: 'inherit',
        height: 145,
        textAlign: 'center',
      }}>
      <Typography>Logout in: {showTokenExpiredModal ? loggoutTimeDisplay : loginSession}</Typography>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SettingsOutlinedIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="My Account" />
        {openNav ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openNav} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to='/user-profile' title='User Profile'>
            <ListItemButton sx={{ pl: 4 }} >
              <ListItemIcon>
                <AccountCircleIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary='User Info' sx={{ color: 'white' }} />
            </ListItemButton>
          </Link>
          <ListItemButton sx={{ pl: 4 }} onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlinedIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
