import React from 'react';
import ReactWorldFlags from 'react-world-flags';
import { Typography } from '@mui/material';
import { EmojiFlagMap, ReactWorldFlagsMap, EmojiFlagMapType  } from '../../services/partnerResourcesHelpers';

// Is user on Mac?
const isMacOS = () => {
  if (typeof window !== 'undefined') {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  }
  return false;
};

// Flag Component
const Flag = ({ language }: { language: EmojiFlagMapType }) => {
  const emojiFlag = EmojiFlagMap[language];

  if (isMacOS() && emojiFlag) {
    return (
      <Typography variant="h6" sx={{ marginRight: 1 }}>
        {emojiFlag}
      </Typography>
    );
  }

  // Fallback to ReactWorldFlags
  return (
    <ReactWorldFlags
      code={ReactWorldFlagsMap[language].code || ''}
      style={{ width: '24px', height: '16px', marginRight: '8px' }}
    />
  );
};

export default Flag;