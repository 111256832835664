import React, { useMemo, useEffect } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { Control, Controller, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { PaletteKey, QuestionStatusById } from '../../../app/store/questionnaireAnswerSlice';
import { Question } from '../../../interfaces/question.interface';
import { QuestionnaireTypes, isWorkflowFiveThreeOrGreater } from '../../../services/questionnaires';
import { WorkflowTypeKey } from '../../../interfaces/workflow.interface';

type QuestionOnQuestionnaireAnswerProps = {
  question: Question;
  workflowType?: WorkflowTypeKey;
  questionStatus: QuestionStatusById;
  curQuestionStatus: string;
  control: Control;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  readOnly: boolean;
  preview: boolean;
  submitted: boolean;
  color: PaletteKey;
};

const QuestionOnQuestionnaireAnswer = (props: QuestionOnQuestionnaireAnswerProps) => {
  const {
    question,
    questionStatus,
    control,
    workflowType,
    setValue,
    getValues,
    register,
    readOnly,
    preview,
    submitted,
    color,
  } = props;

  const initializedAnswersRef = React.useRef<{ [key: number]: boolean }>({});
  const isBaselineQuestion = useMemo(() => typeof question.questionnaire !== 'number' && [QuestionnaireTypes.SITE_BASELINE, QuestionnaireTypes.APPLICATION_BASELINE ].includes(question.questionnaire.type || ''), [question.questionnaire]);
  const isFiveThreeOrGreater = useMemo(() => workflowType ? isWorkflowFiveThreeOrGreater(workflowType) : false, [workflowType]);
  const checkboxChecked = (id: number, value: number | number[] | undefined) => {
    if (value === undefined || typeof value === 'number' || value.indexOf(id) === -1) return false;
    return true;
  };

  const checkboxCCChecked = (id: number, value: number | number[] | undefined) => {
    if (value === undefined || typeof value === 'number' || value.indexOf(id) === -1) return false;
    return true;
  };

  const checkboxNIChecked = (id: number, value: number | number[] | undefined) => {
    if (value === undefined || typeof value === 'number' || value.indexOf(id) === -1) return false;
    return true;
  };

  const checkboxNAChecked = (id: number, value: number | number[] | undefined) => {
    if (value === undefined || typeof value === 'number' || value.indexOf(id) === -1) return false;
    return true;
  };

  const clearAllCheckboxes = (list: string[]) => {
    list.forEach((item) => {
      setValue(item, []);
    });
  };

  const handleCheckboxCheck = (id: string, value: number | number[] | undefined, key: number) => {
    const idNum = parseInt(id);

    // Get the current values from the form state
    const currentAnswer = getValues('nonApplicableAnswers');
    const currentCompensatingControls = getValues('compensatingControlAnswers');
    const currentNotImplemented = getValues('nonImplementedAnswers');
    
    if (!isBaselineQuestion && isFiveThreeOrGreater) {
      // Remove the ID from 'answer', nonImplementedAnswers and 'compensatingControls' arrays if NA is selected
      setValue('nonApplicableAnswers', currentAnswer ? currentAnswer.filter((item: number) => item !== idNum) : []);
      setValue('compensatingControlAnswers', currentCompensatingControls ? currentCompensatingControls.filter((item: number) => item !== idNum) : []);
      setValue('nonImplementedAnswers', currentNotImplemented ? currentNotImplemented.filter((item: number) => item !== idNum) : []);
    }
    
    if (!isBaselineQuestion && isFiveThreeOrGreater) {
      if (key === 0) {
        // Yes on Fully Implemented set Yes for all
        if (Array.isArray(value)) {
          if (value.indexOf(idNum) !== -1) {
            const newVal = [...value];
            newVal.splice(newVal.indexOf(idNum), 1);
            setValue('answer', newVal);
            return;
          } else {
            setValue('answer', question.answers.filter(ans => !['Not Implemented', 'Not Applicable'].includes(ans?.text || '')).map(a => a.id));
            clearAllCheckboxes(['compensatingControlAnswers', 'nonApplicableAnswers', 'nonImplementedAnswers']);
            return;
          }
        } else {
          setValue('answer', question.answers.filter(ans => !['Not Implemented', 'Not Applicable'].includes(ans?.text || '')).map(a => a.id));
          clearAllCheckboxes(['compensatingControlAnswers', 'nonApplicableAnswers', 'nonImplementedAnswers']);
          return;
        }
      } else if (key === question.answers.length - 1) {
        // Yes on Not Applicable set N/A for all
        if (Array.isArray(value)) {
          if (value.indexOf(idNum) !== -1) {
            const newVal = [...value];
            newVal.splice(newVal.indexOf(idNum), 1);
            setValue('answer', newVal);
            return;
          } else {
            setValue('nonApplicableAnswers', question.answers.filter(ans => !['Fully Implemented', 'Not Implemented'].includes(ans?.text || '')).map(a => a.id));
            setValue('answer', [idNum]);
            clearAllCheckboxes(['compensatingControlAnswers', 'nonImplementedAnswers']);
            return;
          }
        } else {
          setValue('nonApplicableAnswers', question.answers.filter(ans => !['Fully Implemented', 'Not Implemented'].includes(ans?.text || '')).map(a => a.id));
          setValue('answer', [idNum]);
          clearAllCheckboxes(['compensatingControlAnswers', 'nonImplementedAnswers']);
          return;
        }
      } else if (key === question.answers.length - 2) {
        // Yes on Not Implemented
        if (Array.isArray(value) && value.indexOf(idNum) !== -1) {
          setValue('answer', []);
        } else {
          setValue('answer', [idNum]);
          clearAllCheckboxes(['compensatingControlAnswers', 'nonApplicableAnswers']);
          setValue('nonImplementedAnswers',  question.answers.filter(ans => !['Fully Implemented', 'Not Implemented'].includes(ans?.text || '')).map(a => a.id));
        }
        return;
      }
    }
    
    const yesNotImplementedId = question.answers.find(ans => ans.text === 'Not Implemented')?.id || null;
    const yesNonApplicableId = question.answers.find(ans => ans.text === 'Not Applicable')?.id || null;

    if (value === undefined || typeof value === 'number') {
      setValue('answer', [idNum]);
      return;
    }
    if (value.indexOf(idNum) === -1) {
      const newValue = [...value].filter((item: number) => item !== yesNotImplementedId && item !== yesNonApplicableId);
      setValue('answer', [...newValue, idNum]);
      return;
    }
    const newValue = [...value].filter((item: number) => item !== yesNotImplementedId && item !== yesNonApplicableId);
    newValue.splice(newValue.indexOf(idNum), 1);
    setValue('answer', newValue);
  };

  const handleCCCheckboxCheck = (id: string, value: number | number[] | undefined, pcValue: number[] | undefined) => {
    const idNum = parseInt(id);
    const currentAnswer = getValues('answer');
    const currentCompensatingControls = getValues('nonApplicableAnswers');
    const currentNotImplemented = getValues('nonImplementedAnswers');

    // Remove the ID from 'answer', 'nonImplemented' and 'nonApplicableAnswers' arrays if NA is selected
    const notImplementedId = question.answers.find(ans => ans.text === 'Not Implemented')?.id || null;
    const nonApplicableId = question.answers.find(ans => ans.text === 'Not Applicable')?.id || null;
    const fullyImplementedId = question.answers.find(ans => ans.text === 'Fully Implemented')?.id || null;

    setValue(
      'answer',
      currentAnswer
        ? currentAnswer.filter(
          (item: number) =>
            item !== idNum &&
              item !== notImplementedId &&
              item !== nonApplicableId &&
              item !== fullyImplementedId,
        )
        : [],
    );
    setValue(
      'nonApplicableAnswers',
      currentCompensatingControls
        ? currentCompensatingControls.filter((item: number) => item !== idNum)
        : [],
    );
    setValue(
      'nonImplementedAnswers',
      currentNotImplemented
        ? currentNotImplemented.filter((item: number) => item !== idNum)
        : [],
    );
    // Check if PC is already selected and don't clear it
    if (pcValue && pcValue.includes(idNum)) {
      if (value === undefined || typeof value === 'number') {
        setValue('compensatingControlAnswers', [idNum]);
        return;
      }
      if (value.indexOf(idNum) === -1) {
        setValue('compensatingControlAnswers', [...value, idNum]);
        return;
      }
      const newValue = [...value];
      newValue.splice(newValue.indexOf(idNum), 1);
      setValue('compensatingControlAnswers', newValue);
      return;
    }

    if (value === undefined || typeof value === 'number') {
      setValue('compensatingControlAnswers', [idNum]);
      return;
    }
    if (value.indexOf(idNum) === -1) {
      setValue('compensatingControlAnswers', [...value, idNum]);
      return;
    }
    const newValue = [...value];
    newValue.splice(newValue.indexOf(idNum), 1);
    setValue('compensatingControlAnswers', newValue);
  };
  
  const handleNACheckboxCheck = (id: string, value: number | number[] | undefined) => {
    const idNum = parseInt(id);
    const currentAnswer = getValues('answer');
    const currentCompensatingControls = getValues('compensatingControlAnswers');
    const currentNotImplemented = getValues('nonImplementedAnswers');

    // Remove the ID from 'answer', 'nonImplementedAnswers' and 'compensatingControls' arrays if NA is selected
    const notImplementedId = question.answers.find(ans => ans.text === 'Not Implemented')?.id || null;
    const nonApplicableId = question.answers.find(ans => ans.text === 'Not Applicable')?.id || null;
    const fullyImplementedId = question.answers.find(ans => ans.text === 'Fully Implemented')?.id || null;

    setValue(
      'answer',
      currentAnswer
        ? currentAnswer.filter(
          (item: number) =>
            item !== idNum &&
              item !== fullyImplementedId &&
              item !== notImplementedId &&
              item !== nonApplicableId,
        )
        : [],
    );
    setValue(
      'compensatingControlAnswers',
      currentCompensatingControls
        ? currentCompensatingControls.filter((item: number) => item !== idNum)
        : [],
    );
    setValue(
      'nonImplementedAnswers',
      currentNotImplemented
        ? currentNotImplemented.filter((item: number) => item !== idNum)
        : [],
    );
  
    if (value === undefined || typeof value === 'number') {
      setValue('nonApplicableAnswers', [idNum]);
      return;
    }
    if (value.indexOf(idNum) === -1) {
      setValue('nonApplicableAnswers', [...value, idNum]);
      return;
    }
    const newValue = [...value];
    newValue.splice(newValue.indexOf(idNum), 1);
    setValue('nonApplicableAnswers', newValue);
  };

  const handleNICheckboxCheck = (id: string, value: number | number[] | undefined) => {
    const idNum = parseInt(id);
    const currentAnswer = getValues('answer');
    const currentCompensatingControls = getValues('compensatingControlAnswers');
    const nonApplicableAnswers = getValues('nonApplicableAnswers');

    // Remove the ID from 'answer', 'nonApplicable' and 'compensatingControls' arrays if NA is selected
    const notImplementedId = question.answers.find(ans => ans.text === 'Not Implemented')?.id || null;
    const nonApplicableId = question.answers.find(ans => ans.text === 'Not Applicable')?.id || null;
    const fullyImplementedId = question.answers.find(ans => ans.text === 'Fully Implemented')?.id || null;

    setValue(
      'answer',
      currentAnswer
        ? currentAnswer.filter((item: number) =>
          item !== idNum &&
            item !== fullyImplementedId &&
            item !== notImplementedId &&
            item !== nonApplicableId,
        )
        : [],
    );
    setValue(
      'compensatingControlAnswers',
      currentCompensatingControls
        ? currentCompensatingControls.filter((item: number) => item !== idNum)
        : [],
    );
    setValue(
      'nonApplicableAnswers',
      nonApplicableAnswers
        ? nonApplicableAnswers.filter((item: number) => item !== idNum)
        : [],
    );

    if (value === undefined || typeof value === 'number') {
      setValue('nonImplementedAnswers', [idNum]);
      return;
    }
    if (value.indexOf(idNum) === -1) {
      setValue('nonImplementedAnswers', [...value, idNum]);
      return;
    }
    const newValue = [...value];
    newValue.splice(newValue.indexOf(idNum), 1);
    setValue('nonImplementedAnswers', newValue);
  };

  const getOpacity = React.useCallback(
    (text: string | undefined) =>
      text && ['Fully Implemented', 'Not Applicable', 'Not Implemented'].includes(text) ? 0.35 : 1,
    [],
  );

  useEffect(() => {
    if (!questionStatus || !questionStatus[question.id] || questionStatus[question.id].status !== 'cert') return;

    // Check if the question has an answer that is not excluded by the certifications if so do not set default values
    const allAnswers = [
      ...(questionStatus[question.id].questionAnswer?.answer || []),
      ...(questionStatus[question.id].questionAnswer?.compensatingControlAnswers || []),
      ...(questionStatus[question.id].questionAnswer?.nonImplementedAnswers || []),
      ...(questionStatus[question.id].questionAnswer?.nonApplicableAnswers || []),
    ];

    const hasAnswerNotExcluded = allAnswers.some(a => !question.excludedByCertifications.some(q => q.answers?.some(b => b === a || (typeof b !== 'number' && b.id === a))));

    if (hasAnswerNotExcluded) return;

    if (question.type === 'multi_select') {
      question.answers.forEach((answer, key) => {
        const isCertified = question.excludedByCertifications.some(q =>
          q.answers?.some(a => a === answer.id || (typeof a !== 'number' && a.id === answer.id)),
        );

        if (isCertified && !checkboxChecked(answer.id, getValues('answer')) && !initializedAnswersRef.current[answer.id]) {
          handleCheckboxCheck(answer.id.toString(), getValues('answer'), key);
          initializedAnswersRef.current[answer.id] = true;
        }
      });
    } else if (question.type === 'single_select') {
      question.answers.forEach((answer) => {
        const isCertified = question.excludedByCertifications.some(q =>
          q.answers?.some(a => a === answer.id || (typeof a !== 'number' && a.id === answer.id)),
        );

        if (isCertified && getValues('answer') !== answer.id && !initializedAnswersRef.current[answer.id]) {
          setValue('answer', answer.id);
          initializedAnswersRef.current[answer.id] = true;
        }
      });
    }
  }, [questionStatus, question.id, question.answers, question.excludedByCertifications, getValues]);

  return question.type && question.type.includes('text') ? (
    <Grid item xs={12}>
      <TextField
        required
        id={`${question.id}-field`}
        label='Answer'
        fullWidth
        InputProps={{
          readOnly: readOnly && (!preview || submitted),
        }}
        {...register('text')}
      />
    </Grid>
  ) : (
    <Grid item xs={12} md={question.includeComment ? 6 : 12}>
      <FormControl>
        {question.type === 'single_select' && (
          <Controller
            control={control}
            name='answer'
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                aria-labelledby={question.title}
                name='answer'
                value={value ? value : 0}
                onChange={(e) => onChange(e.target.value)}
              >
                {question.answers.map((answer, key) => {
                  const isCertifiedByCertAnswer = useMemo(() => questionStatus[question.id].status === 'cert' && question.excludedByCertifications.some(q => q.answers && q.answers.some(a => a === answer.id || (typeof a !== 'number' && a.id === answer.id))), [questionStatus, question.id, question.excludedByCertifications, answer.id]);
                  return (
                  <FormControlLabel
                    sx={{
                      alignItems: 'flex-start',
                      '& .MuiRadio-root': {
                        padding: '0 .5em',
                      },
                      padding: '.5em 0',
                    }}
                    disabled={readOnly && (!preview || submitted)}
                    key={key}
                    value={answer.id}
                    control={<Radio />}
                    label={
                      <Typography
                        sx={{
                          fontWeight:
                            isCertifiedByCertAnswer
                              ? 'bold'
                              : 'inherit',
                          color:
                            isCertifiedByCertAnswer
                              ? `${[color]}.main`
                              : 'black',
                        }}
                      >
                        {answer.text}
                      </Typography>
                    }
                  />
                  );
                },
                )}
              </RadioGroup>
            )}
          />
        )}
        {question.type === 'multi_select' && (
          <FormGroup>
            <Controller
              control={control}
              name='answer'
              render={({ field: { value } }) => (
                <>
                { (!isBaselineQuestion && isFiveThreeOrGreater) && (
                  <div style={{ display: 'flex', alignItems: 'start', fontWeight: 'bold' }}>
                    <Tooltip title="Implemented" placement="top" arrow>
                      <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                        YES
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Compensating Control" placement="top" arrow>
                      <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                        C/C
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Not Implemented" placement="top" arrow>
                      <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                        N/I
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Not Applicable" placement="top" arrow>
                      <Typography variant="button" sx={{ width: '40px', textAlign: 'center', color: '#424242', fontSize: '15px' }}>
                        N/A
                      </Typography>
                    </Tooltip>
                  </div>
                )}
                {question.answers.map((answer, key) => {
                  const isNonControlAnswer = ['Fully Implemented', 'Not Applicable', 'Not Implemented'].includes(answer?.text || '');
                  const isCertifiedByCertAnswer = useMemo(() => questionStatus[question.id].status === 'cert' && question.excludedByCertifications.some(q => q.answers && q.answers.some(a => a === answer.id || (typeof a !== 'number' && a.id === answer.id))), [questionStatus, question.id, question.excludedByCertifications, answer.id]);
                  const checkedState = useMemo(() => checkboxChecked(answer.id, value), [answer.id, value]);

                  return (
                    <div key={key} style={{ display: 'flex', alignItems: 'start' }}>
                      <div style={{ display: 'flex', alignItems: 'start' }}>
                        <Checkbox
                          disabled={readOnly && (!preview || submitted)}
                          checked={checkedState}
                          value={answer.id}
                          onChange={(e) => handleCheckboxCheck(e.target.value, value, key)}
                          sx={{
                            width: '40px',
                          }}
                        />

                        {/* Second Checkbox for Compensating Controls */}
                        { (!isBaselineQuestion && isFiveThreeOrGreater) && (
                        <Controller
                          control={control}
                          name='compensatingControlAnswers'
                          render={({ field: { value: compValue } }) => (
                            <Checkbox
                              indeterminate={isNonControlAnswer}
                              disabled={readOnly && (!preview || submitted) || isNonControlAnswer}
                              checked={checkboxCCChecked(answer.id, compValue)}
                              value={answer.id.toString()}
                              onChange={(e) => handleCCCheckboxCheck(e.target.value, compValue, value)}
                              sx={{
                                opacity: getOpacity(answer?.text),
                                width: '40px',
                              }}
                            />
                          )}
                        />
                        )}

                        {/* Third Checkbox for Non Implemented Controls */}
                        { (!isBaselineQuestion && isFiveThreeOrGreater) && (
                        <Controller
                          control={control}
                          name='nonImplementedAnswers'
                          render={({ field: { value: niValue } }) => (
                            <Checkbox
                              indeterminate={isNonControlAnswer}
                              disabled={readOnly && (!preview || submitted) || isNonControlAnswer}
                              checked={checkboxNIChecked(answer.id, niValue)}
                              value={answer.id.toString()}
                              onChange={(e) => handleNICheckboxCheck(e.target.value, niValue)}
                              sx={{
                                width: '40px',
                                opacity: getOpacity(answer?.text),
                              }}
                            />
                          )}
                        />
                        )}

                        {/* Fourth Checkbox for Compensating Controls */}
                        { (!isBaselineQuestion && isFiveThreeOrGreater) && (
                        <Controller
                          control={control}
                          name='nonApplicableAnswers'
                          render={({ field: { value: naValue } }) => (
                            <Checkbox
                              indeterminate={isNonControlAnswer}
                              disabled={readOnly && (!preview || submitted) || isNonControlAnswer}
                              checked={checkboxNAChecked(answer.id, naValue)}
                              value={answer.id.toString()}
                              onChange={(e) => handleNACheckboxCheck(e.target.value, naValue)}
                              sx={{
                                width: '40px',
                                opacity: getOpacity(answer?.text),
                              }}
                            />
                          )}
                        />
                        )}
                      </div>

                      <Typography
                        sx={{
                          marginLeft: '1em',
                          padding: '9px 0',
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight:
                            isCertifiedByCertAnswer
                              ? 'bold'
                              : 'inherit',
                          color:
                            isCertifiedByCertAnswer
                              ? `${[color]}.main`
                              : 'black',
                        }}
                      >
                        {answer.text}
                      </Typography>
                    </div>
                  );
                })}
                </>
              )}
            />
          </FormGroup>
        )}
      </FormControl>
    </Grid>
  );
};

export default QuestionOnQuestionnaireAnswer;
