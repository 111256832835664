import React, { useCallback, useEffect, useRef, useState } from 'react';
// MUI
import { Tooltip } from '@mui/material';

const TruncatedCell = ({ text, selectedTab }: { text?: string; selectedTab: number }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const checkTruncation = useCallback(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [textRef.current?.scrollWidth, textRef.current?.clientWidth]);

  useEffect(() => {
    checkTruncation();
  }, [text, selectedTab]);

  useEffect(() => {
    const handleResize = () => {
      checkTruncation();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [checkTruncation]);

  return (
    <Tooltip title={isTruncated ? text || '' : ''} arrow disableHoverListener={!isTruncated} placement="top">
      <div
        ref={textRef}
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          display: 'block',
        }}
      >
        {text || '—'}
      </div>
    </Tooltip>
  );
};

export default TruncatedCell;
