import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Divider, Button, Stack, Skeleton } from '@mui/material';
import { widgetStyles, cert } from './widgetStyles';
import { DataRow, DataCell } from '../../Tables/DataList';
import { Link } from 'react-router-dom';
import { Company } from '../../../interfaces/users.interface';
import { getCompanies } from '../../../services/company';
import { useAppSelector } from '../../../app/hooks';
import { USER_TYPES } from '../../../services/user';

export default function AssessmentStatusWidget() {
  const { user } = useAppSelector(state => state.user);
  const [companies, setCompanies] = useState<any[]>([]);
  const [recentCompanies, setRecentCompanies] = useState<Company[]>([]);
  const [view, setView] = useState<string>('joined');
  const styles = widgetStyles();
  
  useEffect(() => {
    const SPLocalStorageJSON = localStorage.getItem('SPLast10');
    setCompanies(SPLocalStorageJSON === null ? [] : JSON.parse(SPLocalStorageJSON));
    if (user?.type === USER_TYPES.TPN_ADMIN) getCompanies('?limit=10&type=vendor&ordering=-created_at').then(setRecentCompanies);
    if (user?.type !== USER_TYPES.TPN_ADMIN) getCompanies('?limit=10&type=vendor&ordering=-created_at&is_published=true').then(setRecentCompanies);
  }, []);

  return (
    <Paper className={styles.widget}>
      <Box className={styles.widgetHeader}>
        <Typography variant='h4' fontSize='1.8rem'>Most Recent Service Providers</Typography>
        <Divider />
      </Box>

      <Stack direction='row' justifyContent='center' spacing={2} sx={{ mb: 2 }}>
        <Button variant={view === 'joined' ? 'contained' : 'outlined'} onClick={() => setView('joined')}>Joined</Button>
        <Button variant={view === 'viewed' ? 'contained' : 'outlined'} onClick={() => setView('viewed')}>Viewed</Button>
      </Stack>

      {view === 'joined' && <>
        {recentCompanies.length === 0 && <>
          <DataRow header>
            <DataCell xs={8}><Skeleton /></DataCell>
            <DataCell xs={4}><Skeleton /></DataCell>
          </DataRow>
          <DataRow>
            <DataCell xs={8}><Skeleton /></DataCell>
            <DataCell xs={4}><Skeleton /></DataCell>
          </DataRow>
          <DataRow>
            <DataCell xs={8}><Skeleton /></DataCell>
            <DataCell xs={4}><Skeleton /></DataCell>
          </DataRow>
          <DataRow>
            <DataCell xs={8}><Skeleton /></DataCell>
            <DataCell xs={4}><Skeleton /></DataCell>
          </DataRow>
          <DataRow>
            <DataCell xs={8}><Skeleton /></DataCell>
            <DataCell xs={4}><Skeleton /></DataCell>
          </DataRow>
        </>}
        {recentCompanies.length > 0 && <>
          <DataRow header>
            <DataCell xs={8}>Company</DataCell>
            <DataCell xs={4}>Visit</DataCell>
          </DataRow>
          {recentCompanies.map(company => (
            <DataRow key={company.id}>
              <DataCell xs={8}>{company.name}</DataCell>
              <DataCell xs={4}><Link to={`/registry/${company.id}`}><Button variant='contained'>View Service Provider</Button></Link></DataCell>
            </DataRow>
          ))}
        </>}
      </>}

      {view === 'viewed' && <>
        {companies.length === 0 && <Typography color={cert} mt={3} mb={3} textAlign='center' variant='h4'>No Service Providers Viewed</Typography>}
        {companies.length > 0 && <>
          <DataRow header>
            <DataCell xs={8}>Company</DataCell>
            <DataCell xs={4}>Visit</DataCell>
          </DataRow>
          {companies.map(company => (
            <DataRow key={company.id}>
              <DataCell xs={8}>{company.name}</DataCell>
              <DataCell xs={4}><Link to={`/registry/${company.id}`}><Button variant='contained'>View Service Provider</Button></Link></DataCell>
            </DataRow>
          ))}
        </>}
      </>}
    </Paper>
  );
}