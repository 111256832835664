import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOverlay } from '@mui/x-data-grid';
import Moment from 'moment/moment';
import IconButton from '@mui/material/IconButton';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import { CompanyCertification } from '../../interfaces/users.interface';
import { getAllCompanyCertifications, updateCompanyCertificationApproval, updateCompanyCertificatioReviewed } from '../../services/companyCertifications';
import { Card, Switch } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { USER_TYPES } from '../../services/user';

interface CertificationRow {
  id: number | undefined;
  companyName: string | undefined;
  companyId: number | undefined;
  expirationDate?: string | Date;
  adminApproved: boolean;
  adminReviewed: boolean;
  certification: string;
  version: string;
  releaseDate?: string | Date;
  document: string;
  createdAt?: string | Date;
}


function createRowLink(certRow: CertificationRow) {
  return (
    <a href={certRow.document} target="_blank" rel="noreferrer">
      <IconButton size='small' name='View Certification'>
        <LinkOutlinedIcon />
      </IconButton>
    </a>
  );
}

function createRowSwitch(certRow: CertificationRow) {
  const { user } = useAppSelector(state => state.user);
  const userIsAdmin: boolean = user?.type ? user.type == USER_TYPES.TPN_ADMIN : false;
  const companyCertId = `cert-${certRow.id}`;
  const handleChange = (event: any) => {
    if (!certRow.id) return;
    updateCompanyCertificationApproval(certRow.id, event.target.checked);
  };
  return (
    <Switch
      id={companyCertId}
      disabled={!userIsAdmin}
      defaultChecked={certRow.adminApproved}
      onChange={(event) => handleChange(event)}
      name={companyCertId}
    />
  );
}

function createReviewedRowSwitch(certRow: CertificationRow) {
  const { user } = useAppSelector(state => state.user);
  const userIsAdmin: boolean = user?.type ? user.type == USER_TYPES.TPN_ADMIN : false;
  const companyCertId = `cert-${certRow.id}`;
  const handleChange = (event: any) => {
    if (!certRow.id) return;
    updateCompanyCertificatioReviewed(certRow.id, event.target.checked);
  };
  return (
    <Switch
      id={companyCertId}
      disabled={!userIsAdmin}
      defaultChecked={certRow.adminReviewed}
      onChange={(event) => handleChange(event)}
      name={companyCertId}
    />
  );
}

const formatDate = (date?:string) => date ? Moment(date).format('MM/DD/YYYY') : '-';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'companyName', headerName: 'Company Name', width: 230, renderCell: params => (<Link to={`/registry/${params.row.companyId}`}>{params.row.companyName}</Link>) },
  { field: 'certification', headerName: 'Certification', width: 200 },
  { field: 'version', headerName: 'Version', width: 130 },
  { field: 'createdAt', headerName: 'Upload Date', width: 130, renderCell: params => formatDate(params.row.createdAt) },
  { field: 'releaseDate', headerName: 'Release Date', width: 130, renderCell: params => formatDate(params.row.releaseDate) },
  { field: 'expirationDate', headerName: 'Expiration Date', width: 130, renderCell: params => formatDate(params.row.expirationDate) },
  {
    field: 'adminApproved',
    headerName: 'Admin Approved',
    width: 130,
    renderCell: params => createRowSwitch(params.row),
  },
  {
    field: 'adminReviewed',
    headerName: 'Admin Reviewed',
    width: 130,
    renderCell: params => createReviewedRowSwitch(params.row),
  },
  {
    field: 'link',
    headerName: 'Link',
    width: 130,
    renderCell: params => createRowLink(params.row),
  },
];

function createCertificationRow(companyCert: CompanyCertification) {
  return {
    id: companyCert.id,
    companyId: companyCert.company,
    companyName: companyCert.companyName,
    expirationDate: companyCert.expirationDate,
    adminApproved: companyCert.adminApproved ? companyCert.adminApproved : false,
    adminReviewed: companyCert.adminReviewed ? companyCert.adminReviewed : false,
    certification: companyCert.certification.title,
    version: companyCert.certification.version ? companyCert.certification.version : '-',
    releaseDate: companyCert.certification.releaseDate,
    document: companyCert.document,
    createdAt: companyCert.createdAt,
  };
}

export default function CompanyCertificationAdminPanel() {

  const [rows, setRows] = useState<Array<CertificationRow>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getAllCompanyCertifications().then((companyCerts: CompanyCertification[]) => {
      setRows(companyCerts.sort((a, b) => a.companyName!.localeCompare(b.companyName!)).map((companyCert: CompanyCertification) => createCertificationRow(companyCert)));
      setIsLoading(false);
    });
  }, []);
  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        components={{
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
      />
    </Card>
  );
}
