import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CompanyLegalContact, Company } from '../../../interfaces/users.interface';
import createCompanyLegalContact, { updateCompanyLegalContact } from '../../../services/companyLegalContacts';

const CompanyLegalContactForm = (props: { company: Company, companyCallBack: any }) => {
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm<Partial<CompanyLegalContact>>(); // Use Partial to allow missing fields

  const {
    company,
    companyCallBack,
  } = props;
  const companyLegalContact = company?.companyLegalContacts?.length
    ? (company.companyLegalContacts[0] as CompanyLegalContact)
    : null;

  useEffect(() => {
    if (companyLegalContact) {
      setValue('id', companyLegalContact?.id);
      setValue('name', companyLegalContact.name);
      setValue('email', companyLegalContact.email);
      setValue('address', companyLegalContact.address);
    }
    if (company?.id) setValue('company', company?.id);
  }, [companyLegalContact, company]);
  const updateCompanyWithLegalContact = (updateCompany: Company, companyLegalContactResponse: CompanyLegalContact) => {
    const updatedContacts = updateCompany.companyLegalContacts?.length
      ? [companyLegalContactResponse, ...updateCompany.companyLegalContacts.slice(1)]
      : [companyLegalContactResponse];

    const updatedCompany = {
      ...updateCompany,
      companyLegalContacts: updatedContacts,
    };

    companyCallBack(updatedCompany);
  };
  const onSubmit = (data: FieldValues) => {
    if (companyLegalContact) {
      updateCompanyLegalContact(data).then((companyLegalContactResponse: CompanyLegalContact) => updateCompanyWithLegalContact(company, companyLegalContactResponse));
    } else {
      createCompanyLegalContact(data).then((companyLegalContactResponse: CompanyLegalContact) => updateCompanyWithLegalContact(company, companyLegalContactResponse));
    }
  };

  return (
    <Grid container>
      <Box component='form' method='post' onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField
              {...register('name')}
              label='Name'
              defaultValue={companyLegalContact?.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('email')}
              label='Email'
              defaultValue={companyLegalContact?.email}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('address')}
              label='Address'
              multiline={true}
              rows={4}
              defaultValue={companyLegalContact?.address}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' variant='contained' color='primary'>
              {companyLegalContact ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default CompanyLegalContactForm;
