import Auth from '@aws-amplify/auth';

interface Session {
  getIdToken: () => {
    getJwtToken: () => string;
    getExpiration: () => number;
  };
  getAccessToken: () => {
    getJwtToken: () => string;
    getExpiration: () => number;
  };
  getRefreshToken: () => {
    getToken: () => string;
    getExpiration?: () => number;
  };
}

export default async function getAuthSession(): Promise<Session> {
  const storedIdToken = localStorage.getItem('amplifyIdToken');
  const storedAccessToken = localStorage.getItem('amplifyAccessToken');
  const storedExpiry = localStorage.getItem('amplifyTokenExpiry');
  const expiresAt = storedExpiry ? parseInt(storedExpiry, 10) : 0;

  //  If tokens exist & are still valid, return full session object
  if (storedIdToken && storedAccessToken && storedExpiry && Date.now() < expiresAt) {
    return {
      getIdToken: () => ({
        getJwtToken: () => storedIdToken,
        getExpiration: () => expiresAt / 1000,
      }),
      getAccessToken: () => ({
        getJwtToken: () => storedAccessToken,
        getExpiration: () => expiresAt / 1000,
      }),
      getRefreshToken: () => ({
        getToken: () => localStorage.getItem('amplifyRefreshToken') || '',
      }),
    };
  }

  //  Fetch new session from Cognito if expired or missing
  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  const accessToken = session.getAccessToken();
  const refreshToken = session.getRefreshToken();

  //  Store the new ID token & access token separately
  localStorage.setItem('amplifyIdToken', idToken.getJwtToken());
  localStorage.setItem('amplifyAccessToken', accessToken.getJwtToken());
  localStorage.setItem('amplifyTokenExpiry', (accessToken.getExpiration() * 1000).toString());

  return {
    getIdToken: () => idToken,
    getAccessToken: () => accessToken,
    getRefreshToken: () => refreshToken,
  };
}

interface Session {
  getIdToken: () => {
    getJwtToken: () => string;
    getExpiration: () => number;
  };
  getAccessToken: () => {
    getJwtToken: () => string;
    getExpiration: () => number;
  };
  getRefreshToken: () => {
    getToken: () => string;
    getExpiration?: () => number;
  };
}

export async function refreshAuthSession() {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const session = await Auth.currentSession();

    const idToken = session.getIdToken();
    const accessToken = session.getAccessToken();
    const refreshToken = session.getRefreshToken();
    const expiresAt = accessToken.getExpiration() * 1000; // Convert to ms

    localStorage.setItem('amplifyIdToken', idToken.getJwtToken());
    localStorage.setItem('amplifyAccessToken', accessToken.getJwtToken());
    localStorage.setItem('amplifyRefreshToken', refreshToken.getToken());
    localStorage.setItem('amplifyTokenExpiry', expiresAt.toString());

    return session;
  } catch (error) {
    console.error('Error refreshing session:', error);
    throw error;
  }
}
