import React, { useEffect } from 'react';
import { AccordionDetails, Chip, Tooltip, Typography } from '@mui/material';
import { Application, Company, Version } from '../../interfaces/users.interface';
import { AccordionCollapse, AccordionCollapseSummary } from '../Layout/Collapsible';
import { DataCell, DataRow } from '../Tables/DataList';
import { useState } from 'react';
import GetBadge from '../Directory/GetBadge';
import { HardeningDocument } from '../../interfaces/hardeningDocuments';
import { fetchDocument } from '../../app/store/serviceCategories';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { File } from '../../interfaces/questionAnswer.interface';
import { selectBadgeFromArray } from '../../services/badge';
import { getBadgesFromVersions } from '../../services/applications';
import { USER_TYPES } from '../../services/user';

type ApplicationListVersionProps = {
  application: Application;
};

export default function ApplicationListVersion(props:ApplicationListVersionProps) {
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector(state => state.serviceCategories);
  const { user } = useAppSelector(state => state.user);
  const { application } = props;
  const [versionExpanded, setVersionExpanded] = useState(false);
  const [shouldOpenDoc, setShouldOpenDoc] = useState<number | undefined>(undefined);

  const openFile = (doc: File) => {
    const link = document.createElement('a');
    link.setAttribute('href', doc.document);
    link.setAttribute('target', '_blank');
    link.click();
  };

  const openDoc = async (docid: number) => {
    if (!documents.find(doc => doc.id === docid)) {
      dispatch(fetchDocument(docid));
      setShouldOpenDoc(docid);
      return;
    }
    const doc = documents.find(d => d.id === docid);
    if (!doc) return;
    openFile(doc);
  };

  const getDocs = (version: Version) => {
    if (!application.hardeningDocuments?.length) return [];
    const curDocs = (application.hardeningDocuments as HardeningDocument[]).filter(hd => hd.version === version.value);
    if (!curDocs) return '';
    return curDocs.map(cd => user?.type === USER_TYPES.VENDOR_ADMIN ? <Chip key={cd.id} label={cd.type} /> : <Chip key={cd.id} label={cd.type} onClick={() => openDoc(cd.document)} />);
  };

  useEffect(() => {
    if (!shouldOpenDoc || !documents.find(doc => doc.id === shouldOpenDoc)) return;
    const doc = documents.find(d => d.id === shouldOpenDoc);
    setShouldOpenDoc(undefined);
    if (!doc) return;
    openFile(doc);
  }, [documents]);

  return (
    <DataCell xs={12} sx={{ ml: '4%' }}>
      <AccordionCollapse expanded={versionExpanded} onChange={() => { setVersionExpanded(!versionExpanded); }}>
        <AccordionCollapseSummary name='Versions or Included Applications' title='Versions or Included Applications' />
        <AccordionDetails>
          <DataRow header>
            <DataCell xs={1}>Shield</DataCell>
            <DataCell xs={4}>Versions or Included Applications</DataCell>
            <DataCell xs={7}>Configuration Guidelines</DataCell>
          </DataRow>
          {(application.versions as Version[]).sort((a, b) => a.value.localeCompare(b.value)).map(version =>
            <DataRow key={version.id}>
              <DataCell xs={1}>
                {version.badge && typeof version.badge !== 'number' && (application.company as Company)?.isPublished && 
                  <GetBadge shield={selectBadgeFromArray(getBadgesFromVersions(application?.versions as Version[]))} />}
              </DataCell>
              <DataCell xs={4}><Tooltip title={version.value}><Typography sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{version.value}</Typography></Tooltip></DataCell>
              <DataCell xs={7}>{getDocs(version)}</DataCell>
            </DataRow>,
          )}
        </AccordionDetails>
      </AccordionCollapse>
    </DataCell>
  );
}